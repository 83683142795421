import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { TOYOTA } from './utils/brandService';
import configureStore, { history } from './store/configureStore';
import { PersistGate } from 'redux-persist/lib/integration/react';

export const { store, persistor } = configureStore();

const ToyotaTheme = React.lazy(() => import('./themes/ToyotaTheme'));

const ThemeSelector = ({ children }) => {
  const HOSTNAME = window.location.hostname;

  return (
    <>
      <React.Suspense fallback={<></>}>
        {HOSTNAME.includes('localhost') && <ToyotaTheme />}

        {HOSTNAME.includes(TOYOTA) && <ToyotaTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<>Loading</>} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ThemeSelector>
          <App />
        </ThemeSelector>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
