import React from 'react';
import Communication from '../../../containers/account/AccountSubViews/Communication';
import Heading from '../../Heading';
import styles from './cardTerm.module.css';

function PersonalInformation() {
  return (
    <>
      <div className={styles.displayInfo}>
        <Heading>PROTECTION DES DONNÉES</Heading>
      </div>
      <Communication />
    </>
  );
}

export default PersonalInformation;
