import axios from 'axios';
import {
  REQUEST_CALL_SUCCESS,
  REQUEST_CALL_FAIL,
  REQUEST_EMAIL_SUCCESS,
  REQUEST_EMAIL_FAIL,
  REQUEST_CALL_LOADING,
  REQUEST_EMAIL_LOADING,
  ADD_MESSAGE_BANNER
} from './types';
import { CUSTOMER_PORTAL_API } from '../../config/properties';
import { sendUserEvent } from '../../utils/googleAnalyticsService';

export function requestCall(accountId, phoneNumber) {
  return dispatch => {
    dispatch({ type: REQUEST_CALL_LOADING });
    axios
      .post(`${CUSTOMER_PORTAL_API}/account/accounts/${accountId}/mail/phone-contact`, phoneNumber)
      .then(response => {
        if (response.status === 204) {
          sendUserEvent('event_ga', 'Contact', 'contact recall requested');

          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'success',
            message: `Merci pour votre demande! Nous vous recontacterons sous 48h au numéro ${phoneNumber.phone}.`
          });
          dispatch({
            type: REQUEST_CALL_SUCCESS,
            payload: response
          });
        } else {
          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'error',
            message: "Malheureusement, une erreur s'est produite lors de votre demande de rappel."
          });
          dispatch({
            type: REQUEST_CALL_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        dispatch({
          type: ADD_MESSAGE_BANNER,
          level: 'error',
          message: "Malheureusement, une erreur s'est produite lors de votre demande de rappel."
        });
        dispatch({
          type: REQUEST_CALL_FAIL,
          payload: err
        });
      });
  };
}

export function emailSend(accountId, payload) {
  return dispatch => {
    dispatch({ type: REQUEST_EMAIL_LOADING });
    axios
      .post(`${CUSTOMER_PORTAL_API}/account/accounts/${accountId}/mail/mail-contact`, payload)
      .then(response => {
        if (response.status === 204) {
          sendUserEvent('event_ga', 'Contact', 'contact email requested');

          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'success',
            message: 'Merci de votre demande par courriel! Nous vous recontacterons sous 48h.'
          });
          dispatch({
            type: REQUEST_EMAIL_SUCCESS,
            payload: response
          });
        } else {
          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'error',
            message:
              "Malheureusement, une erreur s'est produite lors de votre demande pour un courriel"
          });
          dispatch({
            type: REQUEST_EMAIL_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        dispatch({
          type: ADD_MESSAGE_BANNER,
          level: 'error',
          message:
            "Malheureusement, une erreur s'est produite lors de votre demande pour un courriel"
        });
        dispatch({
          type: REQUEST_EMAIL_FAIL,
          payload: err
        });
      });
  };
}
