import { REMOVE_DRIVER_LOADING, REMOVE_DRIVER_SUCCESS, REMOVE_DRIVER_FAIL } from '../Actions/types';

const initialState = {
  success: false,
  response: null,
  error: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case REMOVE_DRIVER_LOADING:
      return {
        ...initialState,
        loading: true
      };
    case REMOVE_DRIVER_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false
      };
    case REMOVE_DRIVER_FAIL:
      return {
        ...state,
        error: true,
        loading: false
      };
    default:
      return state;
  }
}
