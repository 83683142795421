import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '../../../common/Loader';
import Button from '../../../common/Button';
import Dropdown from '../../../common/Dropdown';
import CalendarDropdown from '../../../common/CalendarDropdown';
import Card from '../../Card';
import { sendVirtualpageView } from '../../../utils/googleAnalyticsService';
import styles from './removeDriver.module.css';
import { removeDriver } from '../../../store/Actions/removeDriverAction';
import Input from '.././../../common/Input';
import Label from '.././../../common/Label';

class RemoveDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarBirthDate: new Date(),
      calendarEffectiveDate: new Date(),
      calendarDateEffectiveError: false,
      driverDataToRemove: {
        salutation: '',
        firstName: '',
        lastName: '',
        birthDate: '',
        effectiveDate: ''
      },
      salutations: [
        {
          id: 0,
          title: 'Mme',
          selected: false,
          value: 'Mme',
          key: 'title'
        },
        {
          id: 1,
          title: 'Mr',
          selected: false,
          value: 'Mr',
          key: 'title'
        }
      ]
    };
  }

  componentDidMount() {
    sendVirtualpageView('VirtualPageView', this.props.accountId, '/removeDriver', 'Remove Driver');
  }

  handleChange = ({ currentTarget: input }) => {
    const { driverDataToRemove } = { ...this.state };
    driverDataToRemove[input.name] = input.value;
    this.setState({ driverDataToRemove });
  };

  handleSubmit = evnt => {
    evnt.preventDefault();
    const { driverDataToRemove } = this.state;
    const policyNumber = sessionStorage.getItem('SelectedPolicyNumber');
    this.props.removeDriver(
      driverDataToRemove,
      this.props.accountId,
      policyNumber,
      this.props.history
    );
  };

  handleDropdownChange = id => {
    const { driverDataToRemove, salutations } = { ...this.state };

    salutations.forEach(item => {
      item.selected = false;
    });
    salutations[id].selected = true;

    this.setState({
      driverDataToRemove: { ...driverDataToRemove, salutation: salutations[id].value }
    });
  };

  formatDateForBackend = date => {
    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;
    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    return `${date.getFullYear()}-${month}-${day}`;
  };

  onCalendarChange = (date, fieldName, calendarName) => {
    const parsedDate = this.formatDateForBackend(date);
    this.setState(prevState => ({
      [calendarName]: date,
      driverDataToRemove: { ...prevState.driverDataToRemove, [fieldName]: parsedDate }
    }));
  };

  substractDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  };

  validateEffectiveDate = date => {
    if (date instanceof Date) {
      let past = false;

      if (date < new Date()) {
        past = true;
      }

      this.setState(() => ({
        calendarDateEffectiveError: past
      }));
    }
  };

  render() {
    const { driverDataToRemove } = this.state;
    return (
      <React.Fragment>
        <Loader show={this.props.loading} />

        <div className={styles.header}>RETIRER UN CONDUCTEUR</div>
        <Card>
          <div className={styles.cardContent}>
            <div className={styles.text}>
              <p>
                Veuillez remplir le formulaire ci-dessous si vous souhaitez retirer un conducteur de
                votre contrat.
              </p>
            </div>

            <hr className={styles.divider} />

            <div className={styles.form}>
              <div className={styles.dropdownWrapper}>
                <Dropdown
                  className={styles.dropdown}
                  navDropdown
                  dropdownBGColor="#e4e4e4"
                  placeholder="Titre"
                  title={this.state.driverDataToRemove.salutation}
                  list={this.state.salutations}
                  resetThenSet={id => this.handleDropdownChange(id)}
                  required
                />
              </div>
              <Input
                additionalStyles={styles.input}
                value={driverDataToRemove.firstName}
                name="firstName"
                onChange={this.handleChange}
                placeholder="Prenom"
                required
              />
              <Input
                additionalStyles={styles.input}
                value={driverDataToRemove.lastName}
                name="lastName"
                onChange={this.handleChange}
                placeholder="Nom de Famille"
                required
              />
              <Label className={styles.label} required>
                Date de naissance
              </Label>
              <CalendarDropdown
                className={styles.datePicker}
                date={this.state.calendarBirthDate}
                onChange={date => this.onCalendarChange(date, 'birthDate', 'calendarBirthDate')}
                placeholder="jj.mm.aaaa"
              />
              <Label className={styles.label} required>
                Quand voudriez-vous que cela prenne effet?
              </Label>
              <CalendarDropdown
                className={styles.datePicker}
                date={this.state.calendarEffectiveDate}
                minDate={new Date()}
                onChange={date => {
                  this.onCalendarChange(date, 'effectiveDate', 'calendarEffectiveDate');
                  this.validateEffectiveDate(date);
                }}
                placeholder="jj.mm.aaaa"
              />
              {this.state.calendarDateEffectiveError ? (
                <span className={styles.error}>La date ne doit pas se situer dans le passé</span>
              ) : null}

              <hr className={styles.divider} />

              <Button additionalStyles={styles.button} handleClick={this.handleSubmit}>
                Retirer un conducteur
              </Button>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

RemoveDriver.propTypes = {
  accountId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  removeDriver: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
};
const mapStateToProps = state => ({
  accountId: state.accountIdUser.accountId,
  loading: state.removeDriver.loading
});

export default connect(mapStateToProps, { removeDriver })(RemoveDriver);
