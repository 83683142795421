import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function UploadDocument({ files, setFiles, setFileError, dropRef, accept, exactFileNumber, maxFileNumber }) {

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const validateFile = (fileSize, fileType) => {
    if (fileSize > 10000000) {
      setFileError([
        "Fichier trop volumineux. Veuillez uploader un fichier d'une taille inférieure à 10 MB."
      ]);
      return false;
    }

    if (
      !(
        fileType === 'application/pdf' ||
        fileType === 'image/png' ||
        fileType === 'image/jpeg' ||
        fileType === 'image/jpg'
      )
    ) {
      setFileError([
        `Format de fichier non valide. Veuillez uploader un fichier de type ${accept} .`]
      );

      return false;
    }

    if (files.filter(file => (file.size === fileSize && file.type === fileType)).length > 0){
      setFileError(errs => [...errs,
        `Le fichier est déjà joint.`]
      );
      return false
    }

    return true;
  };

  const selectFiles = async filesObj => {
    setFileError([])
    const validatedFiles = [];
    Object.values(filesObj).forEach(file => {
      if (validateFile(file.size, file.type)) {
        validatedFiles.push(file);
      }
    });
    setFiles(files => [...files, ...validatedFiles]);
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    const totalFiles = e?.dataTransfer?.files?.length + files.length
    if ((maxFileNumber && totalFiles <= maxFileNumber) || (exactFileNumber && totalFiles <= exactFileNumber)) {
      selectFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
    } else {
      const count = maxFileNumber || exactFileNumber
      const pluralS = count > 1 ? 's': ''
      setFileError([`Maximum ${count} fichier${pluralS} accepté${pluralS}!`]);
      e.preventDefault();
    }
  };

  const handleFileEvent = e => {
    e.preventDefault();
    e.stopPropagation();
    const totalFiles = e?.target?.files?.length + files?.length
    if ((maxFileNumber && totalFiles <= maxFileNumber) || (exactFileNumber && totalFiles <= exactFileNumber)) {
      selectFiles(e?.target?.files);
    } else {
      const count = maxFileNumber || exactFileNumber
      const pluralS = count > 1 ? 's': ''
      setFileError([`Maximum ${count} fichier${pluralS} accepté${pluralS}!`]);
      e.preventDefault();
    }
    e.target.value = '';
  };

  useEffect(() => {
    const div = dropRef.current;
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);
    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  });

  return <input id="fileInput" type="file" accept={accept} multiple onChange={e => handleFileEvent(e)} />;
}

UploadDocument.propTypes = {
  select: PropTypes.func,
  unselect: PropTypes.func
};

export default UploadDocument;
