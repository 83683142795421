import {
    CHANGE_PAYMENT_DETAILS_LOADING,
    CHANGE_PAYMENT_DETAILS_SUCCESS,
    CHANGE_PAYMENT_DETAILS_FAIL
  } from "../Actions/types";
  
  const initialState = {
    response: null,
    loading: false,
    error: false
  
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case CHANGE_PAYMENT_DETAILS_LOADING:
        return {
          ...initialState,
          loading: true
        };
      case CHANGE_PAYMENT_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          response: {...action.payload}
        }
      case CHANGE_PAYMENT_DETAILS_FAIL:
        return {
          ...state,
          loading: false,
          error: true
        }
      default:
        return state;
    }
  }
  