import React, { Component } from 'react';
import Calendar from 'react-calendar';
import { throttle } from 'lodash';
import styles from './CalendarDropdown.module.css';

class CalendarDropdown extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      userDate: '',
      showCal: false,
      dropUp: false
    };
  }

  componentDidMount() {
    this.determineDropUp();
    window.addEventListener('resize', this.throttleDertermineDropUp);
    window.addEventListener('scroll', this.throttleDertermineDropUp);
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (this.state.showCal) {
        window.addEventListener('mousedown', this.handleClickOutside);
      } else {
        window.removeEventListener('mousedown', this.handleClickOutside);
      }
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttleDertermineDropUp);
    window.removeEventListener('scroll', this.throttleDertermineDropUp);
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    const container = this.wrapperRef.current;
    const target = event.target;
    if (container && !container.contains(target)) {
      this.toggleDropdown();
    }
  };

  determineDropUp = () => {
    const windowHeight = window.innerHeight;
    const calHeight = 300;
    const instOffsetWithCal = this.wrapperRef.current.getBoundingClientRect().bottom + calHeight;
    this.setState({
      dropUp: instOffsetWithCal >= windowHeight
    });
  };

  throttleDertermineDropUp = throttle(this.determineDropUp, 1000);

  onChange = date => {
    this.props.onChange(date);
    this.setState({ userDate: this.formatDateForUI(date) });
    this.toggleDropdown();
  };

  formatDateForUI = date => {
    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;
    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    return `${day}.${month}.${date.getFullYear()}`;
  };

  onInputChange = event => {
    const userInput = event.target.value;
    const userInputArr = userInput.split(/\.|\/|-+/g);
    const [userDay, userMonth, userYear] = userInputArr;
    const parsedDate = new Date(`${userYear}/${userMonth}/${userDay}`);
    this.setState({ userDate: userInput });
    if (!isNaN(parsedDate)) {
      this.props.onChange(parsedDate);
    }
  };

  toggleDropdown = () => {
    if (!this.props.disabled) {
      this.setState(prevState => ({ showCal: !prevState.showCal }));
    }
  };

  render() {
    let cal = null;
    if (this.state.showCal) {
      cal = (
        <div
          className={
            this.state.dropUp ? [styles.calwrapper, styles.caldropup].join(' ') : styles.calwrapper
          }
        >
          <Calendar
            onChange={this.onChange}
            value={this.props.date}
            minDate={this.props.minDate ? this.props.minDate : null}
            maxDate={this.props.maxDate ? this.props.maxDate : null}
            locale="FR"
          />
        </div>
      );
    }

    return (
      <>
        <div
          className={`${styles.container} ${this.props.className ? this.props.className : null}`}
          style={{ position: 'relative' }}
          ref={this.wrapperRef}
        >
          <div
            className={styles.ddwrapper}
            onClick={() => {
              if (!this.props.disabled) {
                this.setState({ showCal: true });
              }
            }}
          >
            <div className={styles.ddheader}>
              <input
                className={styles.userInput}
                type="text"
                onChange={this.onInputChange}
                value={this.state.userDate}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
              />
              <i className={`icon-calendar ${this.props.disabled ? styles.disabledIcon : ''}`} />
            </div>
          </div>
          {cal}
        </div>
      </>
    );
  }
}

export default CalendarDropdown;
