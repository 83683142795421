const addMonthsToDate = (date, months) => {
  const d = date.getDate();

  date.setMonth(date.getMonth() + months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }

  return date;
};

export default addMonthsToDate;

export const formatDate = (date) => {
  try {
    const today = new Date(date);
    if (isNaN(today.getTime())) {
      return ''; // Invalid date, return empty string
    }
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy;
  } catch (e) {
    return ''; // Error occurred, return empty string
  }
}

