import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NavBarAccount from '../../common/NavBarAccountPage';
import AccountAddresse from './AccountSubViews/Addresse';
import ChangePassword from './AccountSubViews/ChangePassword';
import AccountDelete from './AccountSubViews/Delete';
import Communication from './AccountSubViews/Communication';
import styles from './account.module.css';

export default function Account({ i18n }) {
  const navBarLinks = [
    { location: '/account/address', text: 'Adresse' },
    { location: '/account/password', text: 'Mot de Passe' },
    { location: '/account/communication', text: 'Communication' },
    { location: '/account/accountDelete', text: 'Supprimer le Compte' }
  ];
  return (
    <React.Fragment>
      <h1 className={styles.header}> {i18n.t('headerTitleAccount').toUpperCase()}</h1>

      <NavBarAccount links={navBarLinks} dropdownBGColor="#e4e4e4" />

      <Switch>
        <Route exact path="/(account|account/address)" component={AccountAddresse} />
        <Route exact path="/account/password" component={ChangePassword} />
        <Route exact path="/account/accountDelete" component={AccountDelete} />
        <Route exact path="/account/communication" component={Communication} />
      </Switch>
    </React.Fragment>
  );
}
