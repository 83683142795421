/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PolicyAddressList from '../../PolicyAddressList';
import AddressCard from '../../AddressCard';
import Loader from '../../../common/Loader';
import elementGroupByAddress from '../../../utils/elementGroupByAddress';
import isPostalAddressLinked from '../../../utils/isPostalAddressLinked';
import ErrorPage from '../../../common/ErrorBoundary/ErrorPage/ErrorPage';
import { sendVirtualpageView, sendUserEvent } from '../../../utils/googleAnalyticsService';
import styles from './addresse.module.css';

class AccountAddresse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      postalAddress: false,
      policyNumber: null,
      addressId: null,
      customerId: null
    };
  }

  componentDidMount() {
    sendVirtualpageView('VirtualPageView', this.props.accountId, '/account', 'Account');
  }

  changePostalAddressClickHandler = (addressId, customerId) => {
    // policyNumber is sent to CCU by mail for identification of the customer
    const policyNumber = this.props?.policies[0]?.policyNumber;
    if (isPostalAddressLinked(this.props.account.address.id, this.props.policies)) {
      this.setState({
        showModal: true,
        postalAddress: true,
        policyNumber,
        addressId,
        customerId
      });
    } else {
      const addressData = JSON.stringify({ postalAddress: true, policyNumber, addressId });
      sessionStorage.setItem('addressData', addressData);
      this.props.history.push({
        pathname: '/changeAddress',
        state: { postalAddress: true }
      });
    }
  };

  changePolicyAddressClickHandler = (addressId, customerId, policyNumber) => {
    this.setState({ showModal: true, policyNumber, postalAddress: false, addressId, customerId });
  };

  noClickHandler = () => {
    this.setState({ showModal: false });

    if (this.state.postalAddress) {
      sendUserEvent('event_ga', 'Account', 'account personal address questioned', 'No');
    } else {
      sendUserEvent('event_ga', 'Account', 'account garage address questioned', 'No');
    }
  };

  yesClickHandler = () => {
    if (this.state.postalAddress) {
      sendUserEvent('event_ga', 'Account', 'account personal address questioned', 'Yes');
    } else {
      sendUserEvent('event_ga', 'Account', 'account garage address questioned', 'Yes');
    }

    const { postalAddress, policyNumber, addressId } = this.state;
    const addressData = JSON.stringify({ postalAddress, policyNumber, addressId });
    sessionStorage.setItem('addressData', addressData);
    this.props.history.push({
      pathname: '/changeAddress',
      state: { postalAddress }
    });
  };

  render() {
    let addresses = null;

    if (this.props.policiesError || this.props.accountError) {
      return <ErrorPage history={this.props.history} />;
    }

    const modal = this.state.showModal ? (
      <div className={styles.modalBG}>
        <div className={styles.modal}>
          <div className={styles.textContainer}>
            <h1>Changement d&apos;adresse</h1>
            <strong>Veuillez noter:</strong>
            <p>
              Cette adresse est actuellement liée à au moins une police ou un contrat. Les
              changements apportés à cette adresse entraîneront des changements aux polices liées.
            </p>
            <p>Voulez vous continuer?</p>
            <div>
              <button
                onClick={() => {
                  this.noClickHandler();
                }}
                className={styles.noButton}
                type="button"
              >
                Non
              </button>
              <button
                onClick={() => {
                  this.yesClickHandler();
                }}
                className={styles.yesButton}
                type="button"
              >
                Oui
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : null;

    if (this.props.account && this.props.policies) {
      let { address } = this.props.account;
      address = {
        street: address.line1,
        zip: address.postalCode,
        ...address
      };

      let { policies } = this.props;

      const numberOfPolicies = policies.length;
      const attachedPoliciesText = numberOfPolicies === 1 ? 'Police attachée' : 'Polices attachées';
      const policiesText = numberOfPolicies === 1 ? 'Police' : 'Polices';

      policies = elementGroupByAddress(policies);
      addresses = (
        <React.Fragment>
          <h2 className={styles.subHeader}>Adresse personnelle</h2>
          {modal}
          <AddressCard
            address={address}
            addressId={address.id}
            customerId={address.customerId}
            changeAddressText="Faire un changement"
            changeAddressClickHandler={this.changePostalAddressClickHandler}
          />
          <h2 className={styles.subHeader}>Adresse du lieu de garage</h2>
          <PolicyAddressList
            attachedPoliciesText={attachedPoliciesText}
            changeAddressText="Faire un changement"
            policiesText={policiesText}
            policies={policies}
            changeAddressClickHandler={this.changePolicyAddressClickHandler}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Loader show={this.props.policiesLoading || this.props.accountLoading} />
        {addresses}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  account: state.account.account,
  accountId: state.keycloak.accountId,
  policies: state.policies.policies,
  policiesLoading: state.policies.loading,
  accountLoading: state.account.loading,
  policiesError: state.policies.error,
  accountError: state.account.error
});

export default connect(mapStateToProps)(AccountAddresse);
