import React from 'react';
import PropTypes from 'prop-types';
import styles from './card.module.css';

function Card(props) {
  return <div className={`${styles.card} ${props.className}`}>{props.children}</div>;
}

Card.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string
};

Card.defaultProps = {
  className: null
};

export default Card;
