import React from 'react';
import PropTypes from 'prop-types';
import styles from './input.module.css';

const Input = ({
  value,
  name,
  onChange,
  placeholder,
  additionalStyles,
  additionalInputStyles,
  required,
  type,
  errorMessage,
  readonly
}) => {
  return (
    <div className={`${styles.masterContainer} ${additionalStyles}`}>
      <div className={`${styles.container} ${errorMessage ? styles.error : ''}`}>
        <input
          className={`${styles.myInput} ${additionalInputStyles}`}
          value={value}
          name={name}
          onChange={onChange}
          id={name}
          type={type}
          required={required}
          readOnly={readonly}
        />
        {!value && (
          <label className={required ? styles.requiredLabel : styles.label} htmlFor={name}>
            {placeholder}
          </label>
        )}
      </div>
      {errorMessage && <span className={styles.inputErrorMessage}>{errorMessage}</span>}
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  additionalStyles: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  readonly: PropTypes.bool
};

Input.defaultProps = {
  additionalStyles: '',
  required: false,
  placeholder: null,
  type: 'text',
  errorMessage: null,
  readonly: false
};

export default Input;
