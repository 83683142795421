import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './contractDocumentsCard.module.css';

import { ReactComponent as Downchevron } from '../../../assets/icons/DownChevron.svg';
import { ReactComponent as Upchevron } from '../../../assets/icons/UpChevron.svg';
import { ReactComponent as AddDarkBackground } from '../../../assets/icons/AddDarkBackground.svg';

import UploadableDocument from '../DocumentInformationTab/uploadableDocument';
import DownloadableDocument from '../DocumentInformationTab/downloadableDocument';
import Modal from '../../../common/DocumentUploadModal';
import { sendUserEvent } from '../../../utils/googleAnalyticsService';

function ContractDocumentsCard({
  documents,
  policyNumber,
  uploadable = false,
  width,
  title,
  onDownload
}) {
  const [showMore, setShowMore] = useState(width > 767);
  const [showModal, setShowModal] = useState(false);
  const [uploadConfigs, setUploadConfigs] = useState(null);
  const [otherDocumentType, setOtherDocumentType] = useState(null);

  const clickShowMore = () => setShowMore(prev => !prev);

  const onOpenUploader = ({ fileType, maxFileNumber, exactFileNumber }) => {
    sendUserEvent(
      'event_ga',
      'Document_upload',
      'Document Upload Modal Opened',
      'Document Type: ' + fileType
    );

    setUploadConfigs({ fileType, maxFileNumber, exactFileNumber });
    setShowModal(prev => !prev);
  };

  const handleAddExtraDocuments = () => {
    sendUserEvent(
      'event_ga',
      'Document_upload',
      'Document Upload Modal Opened',
      'Document Type: Others'
    );

    setUploadConfigs({ fileType: 'other', maxFileNumber: 4 });
    setOtherDocumentType(true);
    setShowModal(true);
  };

  const modalRejectionHandler = () => {
    setShowModal(false);
    setOtherDocumentType(false);
  };

  //This component serves purpose for both download and upload documents.

  return (
    <>
      {/* Modal which serves the purpose for upload of documents and also for accepting extra document if user wants to attach.*/}
      {showModal && (
        <Modal
          width={width}
          configs={uploadConfigs}
          policyNumber={policyNumber}
          otherDocumentType={otherDocumentType}
          modalRejectionHandler={modalRejectionHandler}
        />
      )}

      {/* Component to show document info bar for both upload and download. */}
      {uploadable ? (
        <UploadableDocument
          title={title}
          documents={showMore ? documents : documents?.slice(0, 4)}
          width={width}
          onShowUploader={onOpenUploader}
        />
      ) : (
        <DownloadableDocument
          title={title}
          documents={showMore ? documents : documents?.slice(0, 4)}
          showStatus={uploadable}
          width={width}
          policyNumber={policyNumber}
          onDownload={onDownload}
        />
      )}
      {/* This needs to be shown only for Upload documents tab hence category-1 */}
      {uploadable && (
        <div className={styles.addExtraDocuments} onClick={handleAddExtraDocuments}>
          <AddDarkBackground width={22} height={22} />
          <p>Ajouter un autre document</p>
        </div>
      )}
      {/* Show more/less Only needs to be shown in mobile screens for policies which have greater than 3 documents */}
      {documents?.length > 3 && width < 767 && (
        <div className={!showMore ? styles.showMore : styles.showLess}>
          <div className={styles.showMoreText} onClick={clickShowMore}>
            {!showMore ? 'Voir plus' : 'Afficher moins'}
            <span className={styles.showMoreIcons}>
              {showMore ? (
                <Upchevron width={16} height={16} />
              ) : (
                <Downchevron width={26} height={26} />
              )}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

ContractDocumentsCard.propTypes = {
  documents: PropTypes.any,
  policyNumber: PropTypes.string
};

export default ContractDocumentsCard;
