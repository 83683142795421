import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '../../../common/Loader';
import styles from './emailForm.module.css';
import { updateObject, checkValidity } from '../../../utils/formUtil';
import { emailSend } from '../../../store/Actions/requestCallAndEmailAction';
import Button from '../../../common/Button';

class EmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailDataForm: {
        text: {
          value: '',
          isValid: false,
          touched: false,
          validation: {
            required: false
          }
        },
        formIsValid: true
      }
    };
  }

  handleChange = event => {
    const { target } = event;
    const controlName = target.name;
    const { value } = target;

    const { emailDataForm } = this.state;

    const updatedEmailData = updateObject(emailDataForm[controlName], {
      value,
      isValid: checkValidity(value, emailDataForm[controlName].validation),
      touched: true
    });

    const updatedEmailDataForm = updateObject(emailDataForm, {
      [controlName]: updatedEmailData
    });

    let formIsValid = true;
    formIsValid = updatedEmailDataForm[controlName].isValid && formIsValid;

    // We need to implement the formValidation
    this.setState({
      emailDataForm: updatedEmailDataForm,
      formIsValid
    });
  };

  handleSubmit = () => {
    const { accountId, email } = this.props;
    const { emailDataForm } = this.state;
    const payload = {
      text: emailDataForm.text.value,
      email
    };
    this.props.emailSend(accountId, payload);
  };

  render() {
    const { emailDataForm } = this.state;
    const { loading } = this.props;

    return (
      <React.Fragment>
        <Loader show={loading} />
        <div className={styles.container}>
          <span className={styles.header}>Email</span>
          <div className={styles.textContainer}>
            Merci de ne pas fournir d’informations sensibles. Nous nous efforçons de vous contacter
            dans un delai de 48h.
          </div>
          <textarea
            name="text"
            className={styles.emailTextArea}
            onChange={this.handleChange}
            value={emailDataForm.text.value}
            placeholder="Votre message"
          />
          <Button type="submit" handleClick={this.handleSubmit}>
            Envoyer un email
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateProps = state => ({
  accountId: state.keycloak.accountId,
  email: state.keycloak.data.tokenParsed.email,
  loading: state.requestCallAndEmail.loadingSendEmail
});

EmailForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  emailSend: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};

export default connect(mapStateProps, { emailSend })(EmailForm);
