import React, { Component } from 'react';
import { connect } from 'react-redux';
import PolicyCardList from '../PolicyCardList';
import Loader from '../../common/Loader';
import { setAccountId } from '../../store/Actions/setAccountIdAction';
import { fetchAccount } from '../../store/Actions/accountAction';
import { fetchPolicies } from '../../store/Actions/policiesAction';
import ErrorPage from '../../common/ErrorBoundary/ErrorPage/ErrorPage';
import styles from './policies.module.css';

import { ReactComponent as Pending } from '../../assets/icons/error.svg';
import Button from '../../common/Button';
import { NavLink } from 'react-router-dom';
import { sendUserEvent } from '../../utils/googleAnalyticsService';

class Policies extends Component {
  cardInfo = {
    accordianLabel: 'Les détails de paiement',
    coverageButtonText: "Couverture d'assurance",
    dropdownTitle: 'Faire un changement',
    links: [
      { location: '/changeVehicle', text: 'Changer de véhicule' },
      { location: '/addDriver', text: 'Ajouter un conducteur' },
      { location: '/removeDriver', text: 'Retirer un conducteur' },
      { location: '/changePaymentDetails', text: 'Changer les détails de paiement' }
    ]
  };

  componentDidMount() {
    const { keycloak } = this.props;
    const accountId = keycloak.idTokenParsed.sub;
    this.props.setAccountId(accountId);
    this.props.fetchAccount(accountId);
    this.props.fetchPolicies(accountId);
  }

  printAccountHolderName = policy => {
    if (policy !== null && typeof policy.paymentDetails !== 'undefined') {
      return policy.paymentDetails.bankAccount.ownerName;
    }

    return ' - ';
  };

  getAnnualPremium = policy => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    }).format(policy.premiumA);
  };

  getMainDriver = policy => {
    return policy.drivers.find(driver => driver.principal === true);
  };

  render() {
    const { i18n, error, history } = this.props;

    if (error) {
      return <ErrorPage history={history} />;
    }

    const policies = this.props.policies
      ? this.props.policies.map(policy => {
          return {
            policyData: [
              { title: "Plaque d'immatriculation", value: policy.risks[0].licensePlate },
              { title: 'Numéro de contrat', value: policy.policyNumber },
              { title: 'Couverture du contrat', value: policy.risks[0].guaranteeFormula },
              {
                title: 'Prime annuelle',
                value: this.getAnnualPremium(policy)
              },
              { title: 'Date de début', value: policy.startDate },
              { title: 'Bonus/Malus', value: `${this.getMainDriver(policy).crm * 100}%` }
            ],
            paymentDetails: [
              {
                title: 'Titulaire du compte',
                value: this.printAccountHolderName(policy)
              },
              {
                title: 'BIC',
                value:
                  typeof policy.paymentDetails !== 'undefined'
                    ? policy.paymentDetails.bankAccount.bic
                    : ' - '
              },
              {
                title: 'IBAN',
                value:
                  typeof policy.paymentDetails !== 'undefined'
                    ? policy.paymentDetails.bankAccount.iban
                    : ' - '
              }
            ],
            policyNumber: policy.policyNumber,
            carMake: policy.risks[0].manufacturer,
            carModel: policy.risks[0].model,
            guarantees: policy.risks[0].guarantees,
            hasPaymentDetails: policy.paymentDetails != null
          };
        })
      : null;

    return (
      <React.Fragment>
        <h1 className={styles.header}>{i18n.t('headerTitlePolicies').toUpperCase()}</h1>
        {this.props.unfulfilledDocs && (
          <div className={styles.documentWarningDiv}>
            <div className={styles.documentWarningInnerDiv}>
              <div>
                <Pending />
              </div>
              <div className={styles.documentWarningTextDiv}>
                <div>Documents à transmettre</div>
                <div>
                  Afin de compléter votre dossier, nous vous prions de nous transmettre en ligne les
                  documents demandés dans les {this.props.remainedDays} jours.
                </div>
              </div>
            </div>
            <div>
              <NavLink
                to="/documents/1"
                onClick={() => {
                  sendUserEvent(
                    'event_ga',
                    'Document_upload',
                    'Document Notification Banner Clicked',
                    'Document Upload Modal Opened'
                  );
                }}
              >
                <Button>Transmettre les documents</Button>
              </NavLink>
            </div>
          </div>
        )}
        <Loader show={this.props.loading} />
        {policies ? <PolicyCardList cardInfo={this.cardInfo} policies={policies} /> : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  policies: state.policies.policies,
  remainedDays: state.policies.remainedDays,
  unfulfilledDocs: state.policies.unfulfilledDocs,
  error: state.policies.error,
  keycloak: state.keycloak.data,
  loading: state.policies.loading,
  accountId: state.keycloak.accountId
});

export default connect(mapStateToProps, { fetchAccount, fetchPolicies, setAccountId })(Policies);
