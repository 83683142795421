import {
  CHANGE_ADDRESS_SUCCESS,
  CHANGE_ADDRESS_LOADING,
  CHANGE_ADDRESS_FAIL
} from '../Actions/types';

const initialState = {
  loading: false,
  error: false,
  response: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ADDRESS_LOADING: {
      return {
        ...initialState,
        loading: true
      };
    }
    case CHANGE_ADDRESS_SUCCESS: {
      return {
        ...state,
        response: { ...action.payload },
        loading: false
      };
    }
    case CHANGE_ADDRESS_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
