import React from 'react';
import styles from './modal.module.css';
import {XIcon} from "@heroicons/react/solid";
import Button from "../Button";
export default function Modal({acceptable = false, acceptTitle = 'Accepter', cancelTitle = 'Annuler', onAccept, onAbort, children}) {

  return <div className={styles.modalBG}>
    <div className={styles.modal}>
      <div style={{width: "100%", display: "flex", justifyContent: "end"}}>
        <XIcon style={{width: "2rem", cursor: "pointer"}} onClick={onAbort}/>
      </div>
      <div style={{marginTop: "1rem", width: '100%'}}>
        {children}
      </div>
      <div className={styles.footer}>
        <Button handleClick={onAbort} additionalStyles={acceptable? styles.noButton : ''}>{cancelTitle}</Button>
        {acceptable && <Button handleClick={onAccept}>{acceptTitle}</Button>}
      </div>
    </div>
  </div>

}