import axios from 'axios';
import { FETCH_ACCOUNT_LOADING, FETCH_ACCOUNT_SUCCESS, FETCH_ACCOUNT_FAIL } from './types';
import { CUSTOMER_PORTAL_API } from '../../config/properties';

export function fetchAccount(accountId) {
  return function(dispatch) {
    dispatch({ type: FETCH_ACCOUNT_LOADING });
    axios
      .get(`${CUSTOMER_PORTAL_API}/account/accounts/${accountId}`)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: FETCH_ACCOUNT_SUCCESS,
            payload: response.data
          });
        } else {
          dispatch({
            type: FETCH_ACCOUNT_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        console.error(err);
        dispatch({ type: FETCH_ACCOUNT_FAIL, payload: err });
      });
  };
}
