import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ErrorPage from './ErrorPage/ErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
  }

  render() {
    const { history } = this.props;

    history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false
        });
      }
    });

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage history={history} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.element.isRequired
};

export default withRouter(ErrorBoundary);
