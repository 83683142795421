import React from 'react';
import PropTypes from 'prop-types';
import styles from './loader.module.css';

function Loader(props) {
  const parentClasses = props.show
    ? [styles.loaderParent, styles.showLoader]
    : [styles.loaderParent, styles.hideLoader];
  const bgClasses = props.show
    ? [styles.backdrop, styles.showLoader]
    : [styles.backdrop, styles.hideLoader];

  return (
    <div className={parentClasses.join(' ')}>
      <div className={bgClasses.join(' ')} />
      <div className={styles.container}>
        <div className={styles.line1}>
          <div className={styles.accentsline1} />
        </div>
        <div className={styles.break1} />
        <div className={styles.line2}>
          <div className={styles.accentsline2} />
        </div>
        <div className={styles.break2} />
        <div className={styles.line3}>
          <div className={styles.accentsline3} />
        </div>
      </div>
    </div>
  );
}

Loader.propTypes = {
  show: PropTypes.bool.isRequired
};
export default Loader;
