import {
  REQUEST_CALL_SUCCESS,
  REQUEST_CALL_FAIL,
  REQUEST_CALL_LOADING,
  REQUEST_EMAIL_LOADING,
  REQUEST_EMAIL_SUCCESS,
  REQUEST_EMAIL_FAIL
} from "./../Actions/types";

const initialState = {
  requestCallSuccess: false,
  sendEmailSuccess: false,
  errorRequestCall: null,
  errorSendEmail: null,
  loadingRequestCall: false,
  loadingSendEmail: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CALL_LOADING:
      return {
        ...state,
        loadingRequestCall: true
      };
    case REQUEST_EMAIL_LOADING:
      return {
        ...state,
        loadingSendEmail: true
      };
    case REQUEST_CALL_SUCCESS:
      return {
        ...state,
        requestCallSuccess: true,
        loadingRequestCall: false
      };
    case REQUEST_CALL_FAIL:
      return {
        ...state,
        loadingRequestCall: false,
        errorRequestCall: true
      };
    case REQUEST_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailSuccess: true,
        loadingSendEmail: false
      };
    case REQUEST_EMAIL_FAIL:
      return {
        ...state,
        loadingSendEmail: false,
        errorSendEmail: true
      };
    default:
      return state;
  }
}
