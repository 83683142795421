import React from 'react';
import PropTypes from 'prop-types';
import styles from './errorMessage.module.css';

function ErrorMessage({
  className,
  message,
  warn,
  error,
  success,
  show,
  errorDunningLevel,
  handleClose
}) {
  let backgroundColorClass = '';
  let iconClass = '';
  if (error || errorDunningLevel) {
    iconClass = styles.iconError;
    backgroundColorClass = styles.error;
  } else if (warn) {
    iconClass = styles.iconWarn;
    backgroundColorClass = styles.warn;
  } else if (success) {
    iconClass = styles.iconSuccess;
    backgroundColorClass = styles.success;
  }

  const icon = !warn ? <div className={`${styles.iconContainer} ${iconClass}`} /> : null;

  return (
    <div
      className={`${className} ${styles.container} ${
        show ? styles.containerShow : styles.containerHide
      }`}
    >
      <div className={`${styles.contentContainer} ${backgroundColorClass}`}>
        {icon}
        <span className={styles.message}>{message}</span>
        {errorDunningLevel ? null : (
          <button className={styles.closeButton} type="button" onClick={handleClose}>
            <i className={styles.closeIcon} />
          </button>
        )}
      </div>
    </div>
  );
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  warn: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func
};

ErrorMessage.defaultProps = {
  className: null,
  error: false,
  warn: false,
  success: false,
  show: false,
  message: '',
  handleClose: () => null
};

export default ErrorMessage;
