import React, { Component } from 'react';
import styles from './mobileDropdown.module.css';
import NavItems from '../NavItems/NavItems';

export default class MobileDropdown extends Component {
  state = {
    showDropdown: false
  };

  wrapperRef = React.createRef();

  componentDidMount() {
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    let container = this.wrapperRef.current;
    let target = event.target;
    if (container && !container.contains(target)) {
      this.setState({ showDropdown: false });
    }
  };

  // handleClickOutside = event => {
  //   if (event.target.id !== "menuButton"){
  //     setTimeout(() => {
  //       this.setState({ showDropdown: false });
  //     }, 100);
  //   }

  // };

  toggleDropdown = () => {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
  };

  render() {
    const dropdownStyles = this.state.showDropdown
      ? [styles.dropdown, styles.showDropdown]
      : [styles.dropdown, styles.hideDropdown];

    const dropDown = (
      <div className={dropdownStyles.join(' ')}>
        <NavItems
          i18n={this.props.i18n}
          unfulfilledDocs={this.props.unfulfilledDocs}
          clickHandler={() => this.setState({ showDropdown: false })}
        />
        <div className={styles.buttonContainer}>
          <button
            onClick={() => {
              sessionStorage.clear();
              this.props.logout({ redirectUri: window.location.origin + process.env.PUBLIC_URL });
            }}
            className={styles.button}
          >
            Se déconnecter
          </button>
        </div>
      </div>
    );

    const icon = this.state.showDropdown ? (
      <i id="menuButton" className={styles.iconHideMenu} />
    ) : (
      <i id="menuButton" className={styles.iconShowMenu} />
    );

    return (
      <div ref={this.wrapperRef}>
        <div className={[styles.iconContainer, this.props.className].join(' ')}>
          <span id="menuButton" onClick={this.toggleDropdown} className={styles.menuButton}>
            {icon}
          </span>
        </div>
        {dropDown}
      </div>
    );
  }
}
