import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavBar from './common/NavBar';
import Footer from './common/Footer';
import i18n from './common/i18next';
import { keycloakAxiosInit } from './config/keycloakAxiosInit';

import ErrorBoundary from './common/ErrorBoundary/ErrorBoundary';
import MessageBanner from './common/MessageBanner/MessageBanner';
import Routes from './Routes/Routes';
import styles from './app.module.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLocale: null
    };
  }

  componentDidMount() {
    keycloakAxiosInit();
    i18n.changeLanguage(this.state.userLocale, (err, t) => {
      if (err) console.log('something went wrong loading', err);
    });
  }


  render() {
    const footerLinks = [
      { text: 'Règlement du site', address: '/rules' },
      { text: 'Vos informations personnelles', address: '/personalInformation' },
      { text: 'Gestion des Cookies', address: '/cookies' }
    ];

    const copyright = 'Copyright © Toyota 2020';

    if (this.props.keycloak && this.props.keycloak.authenticated) {
      return (
        <React.Fragment>
          <MessageBanner />
          <NavBar i18n={i18n} unfulfilledDocs={this.props.unfulfilledDocs} />
          <main className={styles.container}>
            <div className={styles.content}>
              <ErrorBoundary>
                <Routes i18n={i18n} />
              </ErrorBoundary>
            </div>
            <Footer links={footerLinks} copyrightText={copyright} />
          </main>
        </React.Fragment>
      );
    }

    return <div>Loading...</div>;
  }
}

const mapStateToProps = state => {
  return {
    keycloak: state.keycloak.data,
    policies: state.policies.policies,
    unfulfilledDocs: state.policies.unfulfilledDocs
  };
};

export default connect(mapStateToProps)(App);
