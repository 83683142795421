/* eslint-disable import/prefer-default-export */
import Keycloak from 'keycloak-js';
import { setRequestHeadersInterceptor } from '../axios/interceptors';
import { keycloakSuccess } from '../store/Actions/keycloak';
import { store } from '../index';

export const keycloakAxiosInit = () => {
  const keycloak = Keycloak(
    process.env.NODE_ENV === 'development' ? './keycloak_dev.json' : './keycloak.json'
  );

  function detectIE() {
    const userAgent = window.navigator.userAgent;
    const isIE = /MSIE|Trident/.test(userAgent);
    return isIE;
  }

  // const token = localStorage.getItem("kc_token");
  // const refreshToken = localStorage.getItem("kc_refreshToken");

  keycloak
    .init({
      onLoad: 'login-required',
      promiseType: 'native',
      // passing in a token and refreshtoken causes undesirable reloads
      // token,
      // refreshToken,
      // Causes looping in IE11
      checkLoginIframe: !detectIE()
    })
    .then(authenticated => {
      if (authenticated) {
        // localStorage.setItem("kc_token", keycloak.token);
        // localStorage.setItem("kc_refreshToken", keycloak.refreshToken);

        // TODO: uncomment this line after creating the keycloak realms for the different brands.
        // localStorage.setItem('brand', keycloak.realm.toLowerCase());

        localStorage.setItem('brand', 'toyota');

        store.dispatch(keycloakSuccess(keycloak));
      } else {
        sessionStorage.clear();
        keycloak.logout();
      }
    })
    .catch(err => {
      console.error('KC ERROR', err);
      sessionStorage.clear();
      keycloak.logout();
    });

  setRequestHeadersInterceptor(keycloak);
};
