import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dropdown from '../../common/Dropdown';
import Accordian from '../../common/Accordian';
import { sendUserEvent } from '../../utils/googleAnalyticsService';
import styles from './policyCard.module.css';
import Button from '../../common/Button';

function PolicyCard({
  dropdownTitle,
  carMake,
  carModel,
  accordianLabel,
  policyNumber,
  hasPaymentDetails,
  history,
  policyData,
  coverageButtonText,
  guarantees,
  paymentDetails
}) {
  const dropdownLinks = () => {
    const links = [
      { location: '/changeVehicle', text: 'Changer de véhicule' },
      { location: '/addDriver', text: 'Ajouter un conducteur' },
      { location: '/removeDriver', text: 'Retirer un conducteur' }
    ];

    if (hasPaymentDetails) {
      links.push({ location: '/changePaymentDetails', text: 'Changer les détails de paiement' });
    }
    links.push({ location: `/contractTermination/${policyNumber}`, text: 'Résilier ce contrat' });

    return links;
  };

  const dropdownNavItems = dropdownLinks().map((link, index) => {
    return {
      id: index,
      title: link.text,
      selected: false,
      value: link.location,
      key: 'navItems'
    };
  });

  const handleDropdownChange = id => {
    sessionStorage.setItem('SelectedPolicyNumber', policyNumber);
    // eslint-disable-next-line react/prop-types
    history.push({
      pathname: dropdownNavItems[id].value,
      state: { policyNumber }
    });
  };

  const coverageClick = () => {
    sessionStorage.setItem('SelectedPolicyNumber', policyNumber);
    sendUserEvent('event_ga', 'Contract', 'coverages viewed');

    history.push({
      pathname: '/coverages',
      state: { guarantees: guarantees }
    });
  };

  const policyDataCard = policyData.map(item => {
    return (
      <React.Fragment key={item.title + item.value}>
        <span key={item.title} className={styles.titles}>
          {item.title}
        </span>
        <span key={item.value} className={styles.info}>
          {item.value}
        </span>
      </React.Fragment>
    );
  });

  let paymentDetailsDiv;
  if (hasPaymentDetails) {
    const paymentDetailsData = paymentDetails.map(item => {
      return (
        <React.Fragment key={item.title + item.value}>
          <span key={item.title} className={styles.paymentDetailTitle}>
            {item.title}
          </span>
          <span key={item.value} className={styles.paymentDetailInfo}>
            {item.value}
          </span>
        </React.Fragment>
      );
    });

    paymentDetailsDiv = <div className={styles.paymentDetails}>{paymentDetailsData}</div>;
  }

  return (
    <div className={styles.card}>
      <div className={styles.dropdown}>
        <Dropdown
          navDropdown
          title={dropdownTitle}
          list={dropdownNavItems}
          resetThenSet={handleDropdownChange}
          dropdownBGColor="#F8F8F8"
        />
      </div>

      <div className={styles.header}>
        <span className={styles.carMake}>{carMake}</span>
        <span className={styles.carModel}>{carModel}</span>
      </div>

      <div className={styles.policyInfo}>{policyDataCard}</div>

      <hr className={styles.divider} />

      {hasPaymentDetails && (
        <div className={styles.paymentDetailsAccordian}>
          <Accordian content={paymentDetailsDiv} label={accordianLabel} />
        </div>
      )}

      <hr className={styles.divider} />

      <div className={styles.coverageDocuments}>
        <Button additionalStyles={styles.firstButton} handleClick={coverageClick}>
          {coverageButtonText}
        </Button>
      </div>
    </div>
  );
}

PolicyCard.propTypes = {
  policyData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  paymentDetails: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  dropdownTitle: PropTypes.string.isRequired,
  carMake: PropTypes.string.isRequired,
  carModel: PropTypes.string.isRequired,
  accordianLabel: PropTypes.string.isRequired,
  coverageButtonText: PropTypes.string.isRequired,
  policyNumber: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  hasPaymentDetails: PropTypes.bool.isRequired
};

PolicyCard.defaultProps = {
  documents: []
};

export default withRouter(PolicyCard);
