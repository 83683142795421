import React, { useState } from 'react';
import styles from './attachedPolicies.module.css';

const AttachedPolicies = props => {
  const [showAttachedPolicies, toggleAttachedPolicies] = useState(false);
  const { policies } = props;

  const policyRows = (
    <div className={styles.policiesContainer}>
      {props.policies.map((policy, index) => (
        <div
          key={index}
          className={[styles.policyRow, !(index % 2 === 0) ? styles.alternateRowColor : null].join(
            ' '
          )}
        >
          <span>{policy.policyNumber}</span>
          <span>{policy.makeAndModel}</span>
          <span>{policy.licensePlate}</span>
          <span>{policy.startDate}</span>
        </div>
      ))}
    </div>
  );

  return (
    <React.Fragment>
      <hr className={styles.divider} />
      <div
        className={[
          styles.toggleShowPolicies,
          showAttachedPolicies ? styles.showPoliciesOpen : null
        ].join(' ')}
        onClick={() => toggleAttachedPolicies(!showAttachedPolicies)}
      >
        <div className={styles.policiesNumberText}>
          <span className={styles.policiesNumberLabel}>{props.attachedPoliciesText} </span>
          <span className={styles.policiesNumber}>
            {policies ? policies.length : null} {props.policiesText}
          </span>
        </div>
        <div className={styles.icon}>
          {showAttachedPolicies ? (
            <i className={styles.upchevron} />
          ) : (
            <i className={styles.downchevron} />
          )}
        </div>
      </div>
      {showAttachedPolicies ? policyRows : null}
    </React.Fragment>
  );
};

export default AttachedPolicies;
