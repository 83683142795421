import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './accordian.module.css';

function Accordian(props) {
  const [showContent, toggleShowContent] = useState(props.defaultOpen);

  return (
    <>
      <div className={styles.toggleShow} onClick={() => toggleShowContent(!showContent)}>
        <div className={styles.label}>
          <span className={styles.labelText}>{props.label} </span>
        </div>
        <div className={styles.icon}>
          {showContent ? <i className={styles.upchevron} /> : <i className={styles.downchevron} />}
        </div>
      </div>
      <div className={showContent ? styles.contentContainerShow : styles.contentContainerHidden}>
        {props.content}
      </div>
    </>
  );
}

Accordian.propTypes = {
  content: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  defaultOpen: PropTypes.bool
};

Accordian.defaultProps = {
  defaultOpen: false
};
export default Accordian;
