import {
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL
} from "../Actions/types";

const initialState = {
  response: null,
  loading: false,
  error: false

};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD_LOADING:
      return {
        ...initialState,
        loading: true
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {...action.payload}
      }
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return state;
  }
}
