/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  ADD_MESSAGE_BANNER
} from './types';
import { CUSTOMER_PORTAL_API } from '../../config/properties';
import { sendUserEvent } from '../../utils/googleAnalyticsService';

export function changePassword(password, accountId) {
  return dispatch => {
    dispatch({ type: CHANGE_PASSWORD_LOADING });
    axios
      .put(`${CUSTOMER_PORTAL_API}/authentication/accounts/${accountId}/password`, password)
      .then(response => {
        if (response.status === 204) {
          sendUserEvent('event_ga', 'Account', 'account password changed');

          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'success',
            message: 'Votre mot de passe a été mis à jour !'
          });
          dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: response
          });
        } else {
          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'error',
            message: "Malheureusement, nous n'avons pas pu mettre votre mot de passe à jour."
          });
          dispatch({
            type: CHANGE_PASSWORD_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        dispatch({
          type: ADD_MESSAGE_BANNER,
          level: 'error',
          message: "Malheureusement, nous n'avons pas pu mettre votre mot de passe à jour."
        });
        dispatch({
          type: CHANGE_PASSWORD_FAIL,
          payload: err
        });
      });
  };
}
