import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import policiesReducer from './policiesReducer';
import documentsReducer from './documentsReducer';
import accountReducer from './accountReducer';
import changePassword from './changePasswordReducer';
import deleteAccount from './deleteAccountReducer';
import changeAddress from './changeAddressReducer';
import setAccountId from './accountIdReducer';
import addNewDriver from './addNewDriverReducer';
import removeDriver from './removeDriverReducer';
import changeVehicle from './changeVehicleReducer';
import changePaymentDetails from './changePaymentDetailsReducer';
import requestCallAndEmail from './requestCallAndEmailReducer';
import keycloak from './keycloak';
import downloadFile from './downloadFile';
import messageBanner from './messageBanner';

export default history =>
  combineReducers({
    router: connectRouter(history),
    keycloak,
    downloadFile,
    policies: policiesReducer,
    documents: documentsReducer,
    account: accountReducer,
    changePassword,
    deleteAccount,
    changeAddress,
    accountIdUser: setAccountId,
    addNewDriver,
    removeDriver,
    changeVehicle,
    changePaymentDetails,
    requestCallAndEmail,
    messageBanner
  });
