import { SET_ACCOUNT_ID, SET_POLICYNUMBER } from "../Actions/types";
const initialState = {
  accountId: "",
  policyNumber: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ACCOUNT_ID:
      return {
        ...state,
        accountId: action.payload
      };
    case SET_POLICYNUMBER:
      return {
        ...state,
        policyNumber: action.payload
      };
    default:
      return state;
  }
}
