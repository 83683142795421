import axios from 'axios';
import { push } from 'connected-react-router';
import {
  CHANGE_ADDRESS_SUCCESS,
  CHANGE_ADDRESS_LOADING,
  CHANGE_ADDRESS_FAIL,
  ADD_MESSAGE_BANNER
} from './types';
import { CUSTOMER_PORTAL_API } from '../../config/properties';
import { sendUserEvent } from '../../utils/googleAnalyticsService';

export function changePostalAddress(payload, accountId) {
  return dispatch => {
    dispatch({ type: CHANGE_ADDRESS_LOADING });
    axios
      .put(`${CUSTOMER_PORTAL_API}/account/accounts/${accountId}/address`, payload)
      .then(response => {
        if (response.status === 200) {
          dispatch(push('/'));

          sendUserEvent('event_ga', 'Account', 'account personal address changed');

          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'success',
            message: 'Adresse modifiée avec succès'
          });
          dispatch({
            type: CHANGE_ADDRESS_SUCCESS,
            payload: response
          });
        } else {
          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'error',
            message: "Une erreur s'est produite"
          });
          dispatch({
            type: CHANGE_ADDRESS_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        dispatch({
          type: ADD_MESSAGE_BANNER,
          level: 'error',
          message: "Une erreur s'est produite"
        });
        dispatch({
          type: CHANGE_ADDRESS_FAIL,
          payload: err
        });
      });
  };
}

export function changePolicyAddress(payload, accountId, policyNumber) {
  return dispatch => {
    dispatch({ type: CHANGE_ADDRESS_LOADING });
    axios
      .put(`${CUSTOMER_PORTAL_API}/policy/accounts/${accountId}/address/${policyNumber}`, payload)
      .then(response => {
        if (response.status === 204) {
          dispatch(push('/'));

          sendUserEvent('event_ga', 'Account', 'account garage address changed');

          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'success',
            message:
              'Votre demande de changement a bien été prise en compte. Nos services vous recontacteront au plus vite'
          });
          dispatch({
            type: CHANGE_ADDRESS_SUCCESS,
            payload: response
          });
        } else {
          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'error',
            message: "Une erreur s'est produite"
          });
          dispatch({
            type: CHANGE_ADDRESS_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        dispatch({
          type: ADD_MESSAGE_BANNER,
          level: 'error',
          message: "Une erreur s'est produite"
        });
        dispatch({
          type: CHANGE_ADDRESS_FAIL,
          payload: err
        });
      });
  };
}
