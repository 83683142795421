import {
  FETCH_POLICIES_LOADING,
  FETCH_POLICIES_SUCCESS,
  FETCH_POLICIES_FAIL
} from '../Actions/types';

const initialState = {
  policies: null,
  loading: false,
  error: null,
  unfulfilledDocs: false,
  remainedDays: 0,
};


function checkDocs(policies) {
  const pendingStatuses = new Set(['pending', 'rejected', 'not validated']);

  const unfulfilledPolicies = policies
    .filter(policy => policy?.state !== 'Résilié')
    ?.map(policy => ({
      startDate: new Date(policy?.startDate),
      documentsCount: (policy?.documents || [])
        .filter(doc => pendingStatuses.has(doc.status))
        .length || 0
    }))
    .filter(policy => policy?.documentsCount > 0);

  if (unfulfilledPolicies.length > 0) {
    const nearestStartDate = Math.min(...unfulfilledPolicies.map(policy => policy.startDate));
    const differenceMs = Math.abs(nearestStartDate - new Date());
    const remainedDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24)) - 1;

    return {
      remainedDays: (remainedDays < 30) ? 30 - remainedDays : 0,
      unfulfilledDocs: true
    }
  } else {
    return {
      unfulfilledDocs: false
    };
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_POLICIES_LOADING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_POLICIES_SUCCESS:
      return {
        ...state,
        policies: [...action.payload],
        loading: false,
        ...(checkDocs([...action.payload]))
      };
    case FETCH_POLICIES_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
}
