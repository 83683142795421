/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  ADD_NEW_DRIVER_LOADING,
  ADD_NEW_DRIVER_SUCCESS,
  ADD_NEW_DRIVER_FAIL,
  ADD_MESSAGE_BANNER
} from './types';
import { CUSTOMER_PORTAL_API } from '../../config/properties';
import { sendUserEvent } from '../../utils/googleAnalyticsService';

export function addNewDriver(changeData, accountId, policyNumber, history) {
  return dispatch => {
    dispatch({ type: ADD_NEW_DRIVER_LOADING });
    axios
      .post(
        `${CUSTOMER_PORTAL_API}/policy/accounts/${accountId}/${policyNumber}/driver`,
        changeData
      )
      .then(response => {
        if (response.status === 204) {
          history.push('/');

          sendUserEvent('event_ga', 'Contract', 'contract driver added');

          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'success',
            message:
              'Vous avez ajouté un conducteur avec succès. Un avenant à votre contrat vous sera transmis prochainement.'
          });
          dispatch({
            type: ADD_NEW_DRIVER_SUCCESS,
            payload: response
          });
        } else {
          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'error',
            message: "Malheureusement, une erreur s'est produite lors de l'ajout du conducteur"
          });
          dispatch({
            type: ADD_NEW_DRIVER_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        dispatch({
          type: ADD_MESSAGE_BANNER,
          level: 'error',
          message: "Malheureusement, une erreur s'est produite lors de l'ajout du conducteur"
        });
        dispatch({
          type: ADD_NEW_DRIVER_FAIL,
          payload: err
        });
      });
  };
}
