import { KEYCLOAK_SUCCESS, KEYCLOAK_FAIL } from '../Actions/types';

const initialState = {
  data: null,
  accountId: null,
  error: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case KEYCLOAK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        accountId: action.payload.tokenParsed.sub
      };
    case KEYCLOAK_FAIL:
      return {
        ...state,
        error: true
      };
    default:
      return state;
  }
}
