import {
  DELETE_ACCOUNT_LOADING,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL
} from '../Actions/types';

const initialState = {
  response: null,
  loading: false,
  error: false,
  success: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DELETE_ACCOUNT_LOADING:
      return {
        ...initialState,
        loading: true
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: { ...action.payload },
        success: true
      };
    case DELETE_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false
      };
    default:
      return state;
  }
}
