import React from 'react';
import PropTypes from 'prop-types';
import AddressCard from '../AddressCard';

function PolicyAddressList(props) {
  const AddressList = props.policies.map(data => {
    return (
      <AddressCard
        // need to pretty print address
        attachedPoliciesText={props.attachedPoliciesText}
        changeAddressText={props.changeAddressText}
        policiesText={props.policiesText}
        key={data.policyNumber + data.address.id}
        address={data.address}
        addressId={data.address.id}
        customerId={data.customerId}
        attachedPolicies={data.attachedPolicies}
        changeAddressPolicyNumber={data.attachedPolicies[0].policyNumber}
        changeAddressClickHandler={props.changeAddressClickHandler}
      />
    );
  });

  return <>{AddressList}</>;
}

PolicyAddressList.propTypes = {
  policies: PropTypes.arrayOf(PropTypes.object).isRequired,
  attachedPoliciesText: PropTypes.string,
  policiesText: PropTypes.string,
  changeAddressClickHandler: PropTypes.func,
  changeAddressText: PropTypes.string
};

PolicyAddressList.defaultProps = {
  attachedPoliciesText: 'Attached Policy/Policies',
  policiesText: 'Policy/Policies',
  changeAddressClickHandler: null,
  changeAddressText: null
};

export default PolicyAddressList;
