export const FETCH_POLICIES_LOADING = 'FETCH_POLICIES_LOADING';
export const FETCH_POLICIES_SUCCESS = 'FETCH_POLICIES_SUCCESS';
export const FETCH_POLICIES_FAIL = 'FETCH_POLICIES_FAIL';

export const FETCH_ACCOUNT_LOADING = 'FETCH_ACCOUNT_LOADING';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_FAIL = 'FETCH_ACCOUNT_FAIL';

export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const CHANGE_PAYMENT_DETAILS_LOADING = 'CHANGE_PASSWORD_LOADING';
export const CHANGE_PAYMENT_DETAILS_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PAYMENT_DETAILS_FAIL = 'CHANGE_PASSWORD_FAIL';

export const DELETE_ACCOUNT_LOADING = 'DELETE_ACCOUNT_LOADING';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAIL = 'DELETE_ACCOUNT_FAIL';

export const CHANGE_ADDRESS_LOADING = 'CHANGE_ADDRESS_LOADING';
export const CHANGE_ADDRESS_SUCCESS = 'CHANGE_ADDRESS_SUCCESS';
export const CHANGE_ADDRESS_FAIL = 'CHANGE_ADDRESS_FAIL';

export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';

export const SET_POLICYNUMBER = 'SET_POLICY_NUMBER';

export const DOWNLOAD_FILE_LOADING = 'DOWNLOAD_FILE_LOADING';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAIL = 'DOWNLOAD_FILE_FAIL';

export const ADD_NEW_DRIVER_LOADING = 'ADD_NEW_DRIVER_LOADING';
export const ADD_NEW_DRIVER_SUCCESS = 'ADD_NEW_DRIVER_SUCCESS';
export const ADD_NEW_DRIVER_FAIL = 'ADD_NEW_DRIVER_FAIL';

export const REMOVE_DRIVER_LOADING = 'REMOVE_DRIVER_LOADING';
export const REMOVE_DRIVER_SUCCESS = 'REMOVE_DRIVER_SUCCESS';
export const REMOVE_DRIVER_FAIL = 'REMOVE_DRIVER_FAIL';

export const OTHER_CHANGES_LOADING = 'OTHER_CHANGES_LOADING';
export const OTHER_CHANGES_SUCCESS = 'OTHER_CHANGES_SUCCESS';
export const OTHER_CHANGES_FAIL = 'OTHER_CHANGES_FAIL';

export const CHANGE_VEHICLE_LOADING = 'CHANGE_VEHICLE_LOADING';
export const CHANGE_VEHICLE_SUCCESS = 'CHANGE_VEHICLE_SUCCESS';
export const CHANGE_VEHICLE_FAIL = 'CHANGE_VEHICLE_FAIL';

export const KEYCLOAK_LOADING = 'KEYCLOAK_LOADING';
export const KEYCLOAK_SUCCESS = 'KEYCLOAK_SUCCESS';
export const KEYCLOAK_FAIL = 'KEYCLOAK_FAIL';

export const REQUEST_CALL_SUCCESS = 'REQUEST_CALL_SUCCESS';
export const REQUEST_CALL_FAIL = 'REQUEST_CALL_FAIL';
export const REQUEST_CALL_LOADING = 'REQUEST_CALL_LOADING';

export const REQUEST_EMAIL_SUCCESS = 'REQUEST_EMAIL_SUCCESS';
export const REQUEST_EMAIL_FAIL = 'REQUEST_EMAIL_FAIL';
export const REQUEST_EMAIL_LOADING = 'REQUEST_EMAIL_LOADING';

export const CLEAR_MESSAGE_BANNER = 'CLEAR_MESSAGE_BANNER';
export const ADD_MESSAGE_BANNER = 'ADD_MESSAGE_BANNER';

export const FETCH_DOCUMENTS_LOADING = 'FETCH_DOCUMENTS_LOADING';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAIL = 'FETCH_DOCUMENTS_FAIL';

export const UPLOAD_DOCUMENT_LOADING = 'UPLOAD_DOCUMENT_LOADING';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAIL = 'UPLOAD_DOCUMENT_FAIL';
