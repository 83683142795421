import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import Loader from '../../../common/Loader';
import Button from '../../../common/Button';
import Card from '../../Card';
import { changePassword } from '../../../store/Actions/changePasswordAction';
import { sendVirtualpageView } from '../../../utils/googleAnalyticsService';
import Input from '../../../common/Input';
import styles from './changePassword.module.css';

const ChangePassword = ({ accountId, loading }) => {
  const dispatch = useDispatch();

  const [currentPassword, setCurrentPassword] = useState({
    value: '',
    isValid: false,
    showError: false,
    message: 'Mot de passe est nécessaire'
  });
  const [newPassword, setNewPassword] = useState({
    value: '',
    isValid: false,
    showError: false,
    message: 'Mot de passe est nécessaire'
  });
  const [confirmNewPassword, setConfirmNewPassword] = useState({
    value: '',
    isValid: false,
    showError: false,
    message: 'Mot de passe est nécessaire'
  });

  useEffect(() => {
    sendVirtualpageView('VirtualPageView', accountId, '/password', 'Account - Password');
    // eslint-disable-next-line
  }, []);

  function isNotEmpty(password) {
    return !!password.length;
  }

  function inputIsValid() {
    let isValid = true;
    if (newPassword.value !== confirmNewPassword.value) {
      setNewPassword({
        ...newPassword,
        isValid: false,
        showError: true,
        message: 'Les mots de passe ne sont pas identiques'
      });
      setConfirmNewPassword({
        ...confirmNewPassword,
        isValid: false,
        showError: true,
        message: 'Les mots de passe ne sont pas identiques'
      });
      isValid = false;
    }
    return isValid;
  }

  function handleSubmit() {
    const isValid = inputIsValid();

    if (isValid) {
      dispatch(
        changePassword(
          { oldPassword: currentPassword.value, newPassword: newPassword.value },
          accountId
        )
      );
    }
  }

  return (
    <>
      <Loader show={loading} />
      <Card>
        <div className={styles.container}>
          <div className={styles.inputForm}>
            <Input
              value={currentPassword.value}
              onChange={e =>
                setCurrentPassword({
                  ...currentPassword,
                  value: e.target.value,
                  isValid: isNotEmpty(e.target.value)
                })
              }
              name="currentPassword"
              type="password"
              placeholder="Mot de passe actuel"
              errorMessage={currentPassword.showError ? currentPassword.message : ''}
            />
            <Input
              value={newPassword.value}
              onChange={e =>
                setNewPassword({
                  ...newPassword,
                  value: e.target.value,
                  isValid: isNotEmpty(e.target.value)
                })
              }
              name="newPassword"
              type="password"
              placeholder="Nouveau mot de passe"
              errorMessage={newPassword.showError ? newPassword.message : ''}
            />
            <Input
              value={confirmNewPassword.value}
              onChange={e =>
                setConfirmNewPassword({
                  ...confirmNewPassword,
                  value: e.target.value,
                  isValid: isNotEmpty(e.target.value)
                })
              }
              name="confirmPassword"
              type="password"
              placeholder="Confirmez le nouveau mot de passe"
              errorMessage={confirmNewPassword.showError ? confirmNewPassword.message : ''}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button additionalStyles={styles.button} type="button" handleClick={handleSubmit}>
              Changer le mot de passe
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

ChangePassword.propTypes = {
  loading: PropTypes.bool.isRequired,
  accountId: PropTypes.string.isRequired,
  changePassword: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    accountId: state.keycloak.accountId,
    loading: state.changePassword.loading
  };
}

export default connect(mapStateToProps, {
  changePassword
})(ChangePassword);
