/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { DELETE_ACCOUNT_LOADING, DELETE_ACCOUNT_FAIL, ADD_MESSAGE_BANNER } from './types';
import { CUSTOMER_PORTAL_API } from '../../config/properties';
import { sendUserEvent } from '../../utils/googleAnalyticsService';

export function deleteUserAccount(password, accountId, keycloakLogout) {
  return dispatch => {
    dispatch({ type: DELETE_ACCOUNT_LOADING });

    axios
      .delete(`${CUSTOMER_PORTAL_API}/account/accounts/${accountId}`, { data: { password } })
      .then(response => {
        if (response.status === 204) {
          sendUserEvent('event_ga', 'Account', 'account deleted');

          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'success',
            message: 'Votre compte a été supprimé !'
          });
          sessionStorage.clear();
          setTimeout(keycloakLogout, 3000);
        } else {
          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'error',
            message:
              "Malheureusement, une erreur s'est produite lors de la suppression de votre compte."
          });
          dispatch({
            type: DELETE_ACCOUNT_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        dispatch({
          type: ADD_MESSAGE_BANNER,
          level: 'error',
          message:
            "Malheureusement, une erreur s'est produite lors de la suppression de votre compte."
        });
        dispatch({
          type: DELETE_ACCOUNT_FAIL,
          payload: err
        });
      });
  };
}
