/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  REMOVE_DRIVER_SUCCESS,
  REMOVE_DRIVER_FAIL,
  REMOVE_DRIVER_LOADING,
  ADD_MESSAGE_BANNER
} from './types';
import { CUSTOMER_PORTAL_API } from '../../config/properties';
import { sendUserEvent } from '../../utils/googleAnalyticsService';

export function removeDriver(changeData, accountId, policyNumber, history) {
  return dispatch => {
    dispatch({ type: REMOVE_DRIVER_LOADING });
    axios
      .delete(`${CUSTOMER_PORTAL_API}/policy/accounts/${accountId}/${policyNumber}/driver`, {
        headers: {
          'Content-Type': 'application/json'
        },
        data: changeData
      })
      .then(response => {
        if (response.status === 204) {
          history.push('/');

          sendUserEvent('event_ga', 'Contract', 'contract driver removed');

          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'success',
            message: 'Vous avez retiré un conducteur avec succès.'
          });
          dispatch({
            type: REMOVE_DRIVER_SUCCESS,
            payload: response
          });
        } else {
          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'error',
            message:
              "Malheureusement, une erreur s'est produite lors de la suppresion un conducteur"
          });
          dispatch({
            type: REMOVE_DRIVER_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        dispatch({
          type: ADD_MESSAGE_BANNER,
          level: 'error',
          message: "Malheureusement, une erreur s'est produite lors de la suppresion du conducteur"
        });
        dispatch({
          type: REMOVE_DRIVER_FAIL,
          payload: err
        });
      });
  };
}
