import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from './navItems.module.css';

export default function NavItems(props) {
  return (
    <>
      {props.links.map(link => {
        const isActive =
          link.location === '/account/address'
            ? (match, location) =>
                location.pathname === '/account' || location.pathname === '/account/address'
            : null;
        return (
          <NavLink
            key={link.location + link.text}
            to={link.location}
            exact
            className={styles.navItem}
            activeClassName={styles.navItemActive}
            isActive={isActive}
          >
            {link.text}
          </NavLink>
        );
      })}
    </>
  );
}

NavItems.propTypes = {
  links: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired
};
