import React from 'react';
import PropTypes from 'prop-types';
import styles from './documentInformationTab.module.css';
import { ReactComponent as Download } from '../../../assets/icons/DownloadTranparentBackground.svg';
import { formatDate } from '../../../utils/addMonthsToDate';

function DownloadableDocument({ title, documents, width, policyNumber, onDownload }) {
  return (
    <>
      {width > 767 ? <div style={{ padding: '28px 0' }}>{title}</div> : null}
      {documents?.map((document, index) =>
        width > 767 ? (
          <div
            onClick={_ => onDownload(document['fileName'], policyNumber, document['id'])}
            key={document['fileName'] + index}
            className={styles.mainDiv}
          >
            <div className={styles.documentInfoDiv} style={{ margin: '16px 16px 16px 40px' }}>
              <div className={styles.titleDiv}>
                <div>{document['fileName']}</div>
              </div>
              <div style={{ fontSize: '0.8rem', paddingTop: '0.3rem' }}>
                {formatDate(document['created'])}
              </div>
            </div>
            <div className={styles.statusIcon} style={{ width: '5%' }}>
              <Download />
            </div>
          </div>
        ) : (
          <div key={document['fileName']} className={styles.mainMobileDiv}>
            <div
              onClick={_ => onDownload(document['fileName'], policyNumber, document['id'])}
              className={styles.textDiv}
            >
              <div className={styles.mobileDocumentInfoDiv}>
                <div className={styles.titleDiv}>
                  <div className={styles.fileName}>{document['fileName']}</div>
                </div>
                <div style={{ fontSize: '0.8rem', paddingTop: '0.3rem' }}>
                  {formatDate(document['created'])}
                </div>
              </div>
              <div className={styles.statusIcon} style={{ width: '5%' }}>
                <Download />
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}

DownloadableDocument.propTypes = {
  title: PropTypes.string,
  document: PropTypes.any
};

export default DownloadableDocument;
