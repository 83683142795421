import React from 'react';
import PropTypes from 'prop-types';
import styles from './labeledinput.module.css';

const LabeledInput = ({
                 value,
                 name,
                 onChange,
                 label,
                 placeholder,
                 additionalStyles,
                 additionalInputStyles,
                 required,
                 disabled,
                 type,
                 errorMessage,
                 readonly
               }) => {
  return (
    <div className={`${styles.masterContainer} ${additionalStyles}`}>
      <div className={styles.label}>
        {label} {required && <span className={styles.astrix}>*</span>}
      </div>
      <div className={`${styles.container} ${errorMessage ? styles.error : ''}`}>
        <input
          className={`${styles.myInput} ${additionalInputStyles}`}
          value={value}
          name={name}
          onChange={onChange}
          id={name}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          readOnly={readonly}
        />
      </div>
      {errorMessage && <span className={styles.inputErrorMessage}>{errorMessage}</span>}
    </div>
  );
};

LabeledInput.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  additionalStyles: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  readonly: PropTypes.bool
};

LabeledInput.defaultProps = {
  label: '',
  additionalStyles: '',
  required: false,
  placeholder: null,
  type: 'text',
  errorMessage: null,
  readonly: false,
  disabled: false,
};

export default LabeledInput;
