import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Tabs from '../../common/Tabs/Tabs';

import styles from './document.module.css';

import { fetchPoliciesDocuments } from '../../store/Actions/documentsAction';

import ContractDocumentsCard from './ContractDocumentsCard';
import { connect, useSelector } from 'react-redux';
import { downloadFile } from '../../store/Actions/downloadFile';
import { useParams } from 'react-router-dom';
import Loader from '../../common/Loader';
function Letters({ documents, width, onDownload, getTitle }) {
  return (
    <>
      {Object.keys(documents).map((policyNumber, index) => {
        return (
          <React.Fragment key={index + policyNumber}>
            {width < 767 ? (
              <div key={index + policyNumber} className={styles.ContractWrapper}>
                <div key={policyNumber} className={styles.contractDocumentsCard}>
                  <div className={styles.contractNameDiv}>
                    <div className={styles.contractNameInnerDiv}>{getTitle(policyNumber)}</div>
                  </div>
                  <ContractDocumentsCard
                    title={getTitle(policyNumber)}
                    documents={documents?.[policyNumber]}
                    policyNumber={policyNumber}
                    uploadable={false}
                    width={width}
                    onDownload={onDownload}
                  />
                </div>
              </div>
            ) : (
              <ContractDocumentsCard
                title={getTitle(policyNumber)}
                documents={documents?.[policyNumber]}
                policyNumber={policyNumber}
                uploadable={false}
                width={width}
                onDownload={onDownload}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}

function UploadableDocuments({ documents, width }) {
  return (
    <>
      {Object.keys(documents).map((policyNumber, index) => {
        return (
          <React.Fragment key={index + policyNumber}>
            {width < 767 ? (
              <div key={index + policyNumber} className={styles.ContractWrapper}>
                <div key={policyNumber} className={styles.contractDocumentsCard}>
                  <div className={styles.contractNameDiv}>
                    <div
                      className={styles.contractNameInnerDiv}
                    >{`${documents?.[policyNumber]?.manufacturer} ${documents?.[policyNumber]?.model} (${documents?.[policyNumber]?.licensePlate})`}</div>
                  </div>
                  <ContractDocumentsCard
                    title={`${documents?.[policyNumber]?.manufacturer} ${documents?.[policyNumber]?.model} (${documents?.[policyNumber]?.licensePlate})`}
                    documents={documents?.[policyNumber]?.documents}
                    policyNumber={policyNumber}
                    uploadable={true}
                    width={width}
                  />
                </div>
              </div>
            ) : (
              <ContractDocumentsCard
                title={`${documents?.[policyNumber]?.manufacturer} ${documents?.[policyNumber]?.model} (${documents?.[policyNumber]?.licensePlate})`}
                documents={documents?.[policyNumber]?.documents}
                policyNumber={policyNumber}
                uploadable={true}
                width={width}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}

function Documents({
  policies,
  accountId,
  documents,
  unfulfilledDocs,
  fetchPoliciesDocuments,
  downloadFile
}) {
  const [width, setWidth] = useState(window.innerWidth);
  const documentLoading = useSelector(state => state.documents.loading);
  const { id } = useParams();

  const uploadableDocuments = useMemo(
    () =>
      policies
        .filter(policy => policy?.state !== 'Résilié')
        ?.map(policy => {
          return {
            [policy?.policyNumber]: {
              model: policy?.risks?.[0]?.model,
              manufacturer: policy?.risks?.[0]?.manufacturer,
              licensePlate: policy?.risks?.[0]?.licensePlate,
              documents: policy?.documents
            }
          };
        })
        .reduce((a, v) => ({ ...a, [Object.keys(v)[0]]: Object.values(v)[0] }), {}),
    [policies]
  );

  useEffect(() => {
    fetchPoliciesDocuments(Object.keys(uploadableDocuments), accountId);
  }, [fetchPoliciesDocuments, uploadableDocuments, accountId]);

  const getTitle = useCallback(
    policyId => {
      const entity = uploadableDocuments?.[policyId];
      return `${entity?.manufacturer} ${entity?.model} (${entity?.licensePlate})`;
    },
    [uploadableDocuments]
  );

  const onDownload = (fileName, policyNumber, documentId) => {
    downloadFile(fileName, accountId, policyNumber, documentId);
  };

  const handleResize = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Loader show={documentLoading} />
      <div className={styles.header}>Mes documents</div>
      <div className={styles.documentsContainer}>
        <Tabs
          tabsList={[
            {
              title: 'Téléchargement',
              content: (
                <Letters
                  documents={documents || {}}
                  width={width}
                  onDownload={onDownload}
                  getTitle={getTitle}
                />
              )
            },
            {
              title: 'Documents à fournir',
              content: <UploadableDocuments documents={uploadableDocuments} width={width} />,
              alert: unfulfilledDocs
            }
          ]}
          defaultSelectedTabIndex={parseInt(id) || 0}
        />
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  policies: state.policies.policies,
  unfulfilledDocs: state.policies.unfulfilledDocs,
  accountId: state.keycloak.accountId,
  documents: state.documents.documents
});

export default connect(mapStateToProps, { fetchPoliciesDocuments, downloadFile })(Documents);
