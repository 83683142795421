import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '../../../common/Loader';
import Button from '../../../common/Button';
import CalendarDropdown from '../../../common/CalendarDropdown';
import Card from '../../Card';
import addMonthsToDate from '../../../utils/addMonthsToDate';
import { sendVirtualpageView } from '../../../utils/googleAnalyticsService';
import { changeVehicleAction } from '../../../store/Actions/changeVehicleAction';
import styles from './changeVehicle.module.css';
import Input from '../../../common/Input';
import Label from '../../../common/Label';

class ChangeVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarDateReg: new Date(),
      calendarDateEffective: new Date(),
      calendarDateEffectiveError: false,
      changeVehicleData: {
        marque: '',
        manufacturer: '',
        model: '',
        engine: '',
        equipment: '',
        licensePlate: '',
        regDate: '',
        effectiveDate: ''
      }
    };
  }

  componentDidMount() {
    sendVirtualpageView(
      'VirtualPageView',
      this.props.accountId,
      '/changeVehicle',
      'Change Vehicle'
    );
  }

  handleChange = ({ currentTarget: input }) => {
    const { changeVehicleData } = { ...this.state };
    changeVehicleData[input.name] = input.value;
    this.setState({ changeVehicleData });
  };

  handleSubmit = evnt => {
    evnt.preventDefault();
    const { changeVehicleData } = this.state;
    const policyNumber = sessionStorage.getItem('SelectedPolicyNumber');
    this.props.changeVehicleAction(
      changeVehicleData,
      this.props.accountId,
      policyNumber,
      this.props.history
    );
  };

  formatDateForBackend = date => {
    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;
    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    return `${date.getFullYear()}-${month}-${day}`;
  };

  onCalendarChange = (date, fieldName, calendarName) => {
    const parsedDate = this.formatDateForBackend(date);
    this.setState(prevState => ({
      [calendarName]: date,
      changeVehicleData: {
        ...prevState.changeVehicleData,
        [fieldName]: parsedDate
      }
    }));
  };

  substractDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  };

  validateEffectiveDate = date => {
    if (date instanceof Date) {
      let past = false;

      if (date < new Date()) {
        past = true;
      }

      this.setState(() => ({
        calendarDateEffectiveError: past
      }));
    }
  };

  render() {
    const { changeVehicleData } = { ...this.state };

    return (
      <React.Fragment>
        <Loader show={this.props.loading} />

        <div className={styles.header}>CHANGER DE VEHICULE</div>
        <Card>
          <div className={styles.cardContent}>
            <div className={styles.text}>
              <p>
                Vous changez de voiture? Faites-nous savoir les détails de votre nouvelle voiture et
                nous vous contacterons.
              </p>
              <p>
                Veuillez noter qu&apos;il faut jusqu&apos;à 48h pour que les changements prennent
                effet. Si le changement est urgent, veuillez appeler le 01.41.12.20.73.
              </p>
            </div>

            <hr className={styles.divider} />

            <div className={styles.form}>
              <Input
                additionalStyles={styles.input}
                value={changeVehicleData.manufacturer}
                onChange={this.handleChange}
                name="manufacturer"
                placeholder="Manufacturer"
                required
              />
              <Input
                additionalStyles={styles.input}
                value={changeVehicleData.model}
                onChange={this.handleChange}
                name="model"
                placeholder="Modele"
                required
              />
              <Input
                additionalStyles={styles.input}
                value={changeVehicleData.engine}
                onChange={this.handleChange}
                name="engine"
                placeholder="Energie"
                required
              />
              <Input
                additionalStyles={styles.input}
                value={changeVehicleData.equipment}
                onChange={this.handleChange}
                name="equipment"
                placeholder="Finition"
                required
              />
              <Input
                additionalStyles={styles.input}
                value={changeVehicleData.licensePlate}
                onChange={this.handleChange}
                name="licensePlate"
                placeholder="Plaque d'immatriculation"
                required
              />
              <Label required>Date de mise en circulation</Label>
              <CalendarDropdown
                className={styles.datePicker}
                date={this.state.calendarDateReg}
                onChange={date => this.onCalendarChange(date, 'regDate', 'calendarDateReg')}
                placeholder="jj.mm.aaaa"
              />
              <Label required>Quand voudriez-vous que cela prenne effet?</Label>
              <CalendarDropdown
                className={styles.datePicker}
                date={this.state.calendarDateEffective}
                minDate={new Date()}
                maxDate={addMonthsToDate(new Date(), 6)}
                onChange={date => {
                  this.onCalendarChange(date, 'effectiveDate', 'calendarDateEffective');
                  this.validateEffectiveDate(date);
                }}
                placeholder="jj.mm.aaaa"
              />
              {this.state.calendarDateEffectiveError ? (
                <span className={styles.error}>La date ne doit pas se situer dans le passé</span>
              ) : null}

              <hr className={styles.divider} />

              <Button additionalStyles={styles.button} handleClick={this.handleSubmit}>
                Changer de vehicule
              </Button>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

ChangeVehicle.propTypes = {
  accountId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  changeVehicleAction: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  accountId: state.accountIdUser.accountId,
  policyNumber: state.accountIdUser.policyNumber,
  loading: state.changeVehicle.loading
});

export default connect(mapStateToProps, { changeVehicleAction })(ChangeVehicle);
