/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import fileSaver from 'file-saver';
import { CUSTOMER_PORTAL_API } from '../../config/properties';
import {
  DOWNLOAD_FILE_LOADING,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAIL,
  ADD_MESSAGE_BANNER
} from './types';
import { sendUserEvent } from '../../utils/googleAnalyticsService';

export function downloadFile(fileName, accountId, policyNumber, documentId) {
  return dispatch => {
    dispatch({ type: DOWNLOAD_FILE_LOADING });
    axios
      .get(
        `${CUSTOMER_PORTAL_API}/document/accounts/${accountId}/policy/${policyNumber}/document/${documentId}`,
        {
          responseType: 'blob',
          timeout: 30000
        }
      )
      .then(response => {
        fileSaver.saveAs(response.data, `${fileName}`);

        dispatch({ type: DOWNLOAD_FILE_SUCCESS });

        sendUserEvent('event_ga', 'Contract', 'contract document downloaded', fileName);
      })
      .catch(err => {
        dispatch({
          type: ADD_MESSAGE_BANNER,
          level: 'error',
          message: "Une erreur s'est produite lors de la récupération de vos documents."
        });
        dispatch({
          type: DOWNLOAD_FILE_FAIL,
          payload: err
        });
      });
  };
}
