/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './checkbox.module.css';

function Checkbox({ label, onChange, checked, disabled }) {
  return (
    <>
      <div className={styles.checkboxContainer}>
        <div
          className={`${styles.clickable} ${disabled && styles.disabledBox}`}
          onClick={!disabled ? onChange : () => null}
        >
          <div className={checked ? styles.selectedBox : styles.unselectedBox}>
            <span className={styles.checkmark}>
              {checked && (
                <>
                  <div className={styles.checkmark_stem} />
                  <div className={styles.checkmark_kick} />
                </>
              )}
            </span>
          </div>
          <p className={styles.helperText}>{label}</p>
        </div>
      </div>
    </>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  tooltip: PropTypes.string
};

Checkbox.defaultProps = {
  onChange: () => null,
  disabled: false,
  checked: null
};

export default Checkbox;
