import ToyotaLogo from '../assets/brands/toyota/logo-toyota.png';
import LexusLogo from '../assets/brands/lexus/logo-lexus.png';

export const TOYOTA = 'toyota';
export const LEXUS = 'lexus';
export const getBrand = () => localStorage.getItem('brand');

export const getLogoImage = () => {
  switch (getBrand()) {
    case TOYOTA:
      return ToyotaLogo;
    case LEXUS:
      return LexusLogo;
    default:
      console.log('Unsupported brand in local storage detected');
      return null;
  }
};
