import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './navBar.module.css';
import { getLogoImage } from '../../utils/brandService';

import UserDropdown from './UserDropdown/UserDropdown';
import NavItems from './NavItems/NavItems';
import MobileDropDown from './MobileDropdown/MobileDropdown';

function NavBar({ i18n, unfulfilledDocs }) {
  const keycloak = useSelector(state => state.keycloak.data);

  return (
    <>
      <div className={styles.desktopOnly}>
        <Link className={styles.logoContainer} to="/">
          <img src={getLogoImage()} className={styles.navLogo} alt="logo" />
        </Link>
        <div className={styles.navItemContainer}>
          <NavItems i18n={i18n} unfulfilledDocs={unfulfilledDocs} />
        </div>
        <UserDropdown name={keycloak.tokenParsed.name} logout={keycloak.logout} />
      </div>

      <div className={styles.mobileOnly}>
        <Link className={styles.logoContainer} to="/">
          <img src={getLogoImage()} className={styles.navLogo} alt="logo" />
        </Link>
        <MobileDropDown logout={keycloak.logout} i18n={i18n} unfulfilledDocs={unfulfilledDocs} />
      </div>
    </>
  );
}

export default NavBar;
