import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import Card from '../../Card';
import { sendVirtualpageView } from '../../../utils/googleAnalyticsService';
import styles from './communication.module.css';

const Communication = () => {
  const accountId = useSelector(state => state.keycloak.accountId);
  const path = useSelector(state => state.router.location.pathname);

  useEffect(() => {
    switch (path) {
      case '/personalInformation':
        sendVirtualpageView(
          'VirtualPageView',
          accountId,
          '/personalInformation',
          'Personal Information'
        );
        break;
      case '/account/communication':
        sendVirtualpageView(
          'VirtualPageView',
          accountId,
          '/account/communication',
          'Account - Communication'
        );
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Card>
      <div className={styles.cardContent}>
        <p className={styles.subHeader}>Informations sur la collecte de données personnelles</p>
        <p>
          Dans ce qui suit, nous fournissons des informations sur la collecte de données
          personnelles lors de l'utilisation de notre portail client. Pour le traitement général de
          vos données personnelles par nos soins, veuillez également noter les informations sur la
          protection des données que nous avons mises à votre disposition dans le cadre de votre
          contrat. Les données personnelles sont toutes les données qui peuvent vous être liées
          personnellement, par exemple : Nom, adresse, adresses e-mail, comportement des
          utilisateurs. Le responsable de traitement, conformément à l'article 4, paragraphe 7, du
          règlement général sur la protection des données de l'UE (RGPD), est Aioi Nissay Dowa
          Insurance Company of Europe SE, succursale française.
        </p>
        <p>
          Vous pouvez contacter notre délégué à la protection des données à RGPD@aioinissaydowa.eu
          ou à notre adresse postale en ajoutant : «délégué à la protection des données» : 157,
          Bureaux de la Colline, 1 rue Royale, 92213 Saint Cloud Cedex.
        </p>

        <p className={styles.subHeader}>
          Collecte de données générales sur le site Web et le portail client
        </p>
        <p>
          <em>a) Fichiers journaux</em>
        </p>
        <p>
          Lorsque vous utilisez le site Web à des fins d'information uniquement, c'est-à-dire si
          vous ne vous inscrivez pas ou ne nous fournissez pas d'informations, nous collectons
          uniquement les données personnelles que votre navigateur transmet à notre serveur. Si vous
          souhaitez consulter notre site Web, nous collectons les données suivantes, qui nous sont
          techniquement nécessaires pour vous afficher notre site Web et pour garantir la stabilité
          et la sécurité (la base juridique est l'article 6, paragraphe 1, point f) du RGPD):
        </p>
        <ul>
          <li>Adresse IP</li>
          <li>Date et heure de la demande</li>
          <li>Différence de fuseau horaire par rapport à l'heure moyenne de Greenwich (GMT)</li>
          <li>Contenu de la demande (page spécifique)</li>
          <li>État d’accès / code d’état HTTP</li>
          <li>Quantité de données transférées dans chaque cas</li>
          <li>Site Web d'où provient la demande</li>
          <li>Navigateur</li>
          <li>Système d'exploitation et sa surface</li>
          <li>Langue et version du logiciel de navigation.</li>
        </ul>
        <p>
          En plus de l'utilisation purement informative de notre site Web, nous collectons des
          données personnelles si vous nous les fournissez de votre propre chef, par exemple pour
          réaliser un contrat, une enquête, une demande par e-mail ou lors de l'inscription sur le
          portail client. Dans le portail client, vous pouvez afficher et gérer vos contrats et
          demander des modifications. Nous collectons les données nécessaires à l'inscription,
          telles que le nom et l'adresse e-mail, en fonction de votre demande et après votre
          consentement à utiliser le portail client. Après l'activation de votre compte client, vos
          données et documents de contrat sont affichés dans le portail, qui contient, par exemple,
          des données de base, des données d'offre et de contrat, des données de communication ainsi
          que des données sur les dommages et les performances. À votre demande, nous effectuerons
          ou traiterons également des modifications. En outre, le portail client et les données
          d'utilisation sont stockés. Le but de ce traitement est de vous fournir les fonctions du
          portail client et d'activer la gestion générale des contrats, par exemple si vous
          commandez des modifications ou de nouvelles applications. La base juridique de ce
          traitement des données personnelles à des fins précontractuelles et contractuelles est
          l'article 6, paragraphe 1, point b) du RGPD. S'il existe des catégories spéciales de
          données personnelles, par exemple que vos données de santé sont nécessaires, nous
          obtiendrons votre consentement conformément à l'article 9, paragraphe 2 a) et à l’article
          7 du RGPD. Si nous créons des statistiques avec ces catégories de données, cela se fait
          sur la base de l'article 9, paragraphe 2 j) du RGPD. Nous traitons également vos données
          afin de sauvegarder nos intérêts légitimes ou ceux de tiers (art. 6, paragraphe 1 f) du
          RGPD). Cela peut être, par exemple, requis:
        </p>
        <ul>
          <li>pour assurer la sécurité informatique et les opérations informatiques,</li>
          <li>
            pour prévenir et enquêter sur les infractions pénales, nous utilisons en particulier des
            analyses de données pour identifier des indices pouvant indiquer une fraude à
            l'assurance.
          </li>
        </ul>
        <p>
          <em>c) Contact par e-mail ou formulaire de contact</em>
        </p>
        <p>
          Lorsque vous nous contactez par e-mail ou via un formulaire de contact, les données que
          vous fournissez (votre adresse e-mail, éventuellement votre nom et votre numéro de
          téléphone) seront enregistrées par nos soins afin de répondre à vos questions. Nous
          supprimons les données résultant de ce contexte une fois que le stockage n'est plus
          nécessaire à cette fin, sauf s'il existe des exigences légales de conservation. La base
          juridique à cet égard est soit le lancement ou la mise en œuvre d'une relation
          contractuelle, article 6, paragraphe 1, point b) du RGPD, ou un consentement séparé donné
          par vous, article 6, paragraphe 1, point a) du RGPD. Si nécessaire, vous recevrez de notre
          part des informations supplémentaires sur la protection des données au moment de la
          collecte de vos données.
        </p>

        <p className={styles.subHeader}>Cookies / outils d'analyse:</p>
        <p>
          <em>a) Utilisation de cookies / outils d'analyse:</em>
        </p>
        <p>
          En plus des données susmentionnées, des cookies sont stockés sur votre ordinateur ou des
          analyses sont effectuées lorsque vous utilisez notre site Web. Vous pouvez{' '}
          <a href="./cookies.html">ici</a> utiliser des outils de gestion / analyse des cookies. Les
          cookies sont de petits fichiers texte qui sont stockés sur votre disque dur et attribués
          au navigateur que vous utilisez et à travers lesquels certaines informations circulent
          vers l'endroit qui place le cookie (dans ce cas par nous). Les cookies ne peuvent pas
          exécuter de programmes ou transférer de virus sur votre ordinateur. Ils servent à rendre
          l'offre Internet plus conviviale et plus efficace dans l'ensemble. Ce site Web utilise les
          types de cookies suivants, dont la portée et la fonctionnalité sont expliquées ci-dessous:
        </p>
        <ul>
          <li>Cookies transitoires</li>
          <li>Les cookies persistants</li>
        </ul>
        <p>
          Les cookies transitoires sont automatiquement supprimés lorsque vous fermez le navigateur.
          Cela inclut notamment les cookies de session. Ceux-ci enregistrent un « ID de session »,
          avec lequel diverses requêtes de votre navigateur peuvent être attribuées à la session
          commune. Cela permet à votre ordinateur d'être reconnu lorsque vous revenez sur notre site
          Web. Les cookies de session sont supprimés lorsque vous vous déconnectez ou fermez le
          navigateur. Les cookies persistants sont automatiquement supprimés après une période
          spécifiée, qui peut différer en fonction du cookie. Vous pouvez supprimer les cookies à
          tout moment dans les paramètres de sécurité de votre navigateur. Vous pouvez configurer
          les paramètres de votre navigateur selon vos souhaits et par exemple, refuser
          l'acceptation des cookies tiers ou tous les cookies. Nous attirons votre attention sur le
          fait que vous ne pourrez peut-être pas utiliser toutes les fonctions de ce site Web.
        </p>
        <p>
          <em>b) Cookies / outils nécessaires:</em>
        </p>
        <p>
          Nous utilisons ces cookies pour assurer la fonctionnalité et la sécurité du site Web. Les
          cookies peuvent être bloqués dans le navigateur, mais la bonne utilisation du site peut
          alors être restreinte. Les cookies sont supprimés soit à l'expiration de la session, soit
          après 12 mois au plus tard. Nous utilisons également des cookies pour stocker la décision
          pour ou contre les analyses sur le site Web. Cela inclut la date et l'heure, les
          informations du navigateur, une adresse IP raccourcie des derniers chiffres et des
          informations indiquant si quelqu'un a consenti. Ce faisant, nous nous conformons également
          à notre obligation légale de fournir des preuves de consentement ou de révocation. Ces
          cookies sont supprimés au plus tard après 12 mois. La base juridique est notre intérêt
          légitime Art.6 Para.1 point f) du RGPD aux fins mentionnées ci-dessus.
        </p>
        <p>
          <em>c) Google Analytics:</em>
        </p>
        <p>
          Si vous avez donné votre consentement, Google Analytics, un service d'analyse Web fourni
          par Google LLC, est utilisé sur ce site Web. Le fournisseur de services responsable dans
          l'UE est Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irlande
          («Google»).
        </p>
        <p>
          <em>
            <strong>Portée du traitement</strong>
          </em>
        </p>
        <p>
          Google Analytics utilise des cookies ou des informations que nous transmettons (demandes
          ou événements), qui permettent une analyse de votre utilisation de notre site Web. Les
          informations collectées sur votre utilisation de ce site Web sont généralement transférées
          vers un serveur de Google aux États-Unis et y sont stockées. Nous utilisons la fonction ID
          utilisateur. À l'aide de l'ID utilisateur, nous pouvons attribuer un ID unique et
          permanent à une ou plusieurs sessions (et les activités au sein de ces sessions) et
          analyser le comportement des utilisateurs sur tous les appareils. Nous utilisons la
          fonction `` anonymizeIP '' (ce que l'on appelle le ID-masking): en raison de l'activation
          de l'anonymisation IP sur ce site Web, votre adresse IP sera raccourcie par Google dans
          les États membres de l'Union européenne ou dans d'autres États contractants de l'Accord
          sur l'Espace économique européen. L'adresse IP complète n'est transmise à un serveur de
          Google aux États-Unis et y est raccourcie que dans des cas exceptionnels. L'adresse IP
          transmise par votre navigateur dans le cadre de Google Analytics ne sera pas fusionnée
          avec d'autres données Google.
        </p>
        <ul>
          <li>les pages que vous avez visitées, votre "chemin de clic"</li>
          <li>
            Atteinte des "objectifs du site Web" (conversions, par exemple, inscriptions à la
            newsletter, téléchargements, achats)
          </li>
          <li>
            Votre comportement d'utilisateur (par exemple, clics, durée du séjour, taux de rebond)
          </li>
          <li>Votre emplacement approximatif (région)</li>
          <li>Votre adresse IP (sous forme abrégée)</li>
          <li>
            informations techniques sur votre navigateur et les appareils que vous utilisez (par ex.
            réglage de la langue, résolution de l'écran)
          </li>
          <li>Votre fournisseur Internet</li>
          <li>
            l'URL de référence (via quel site Web / via quel matériel publicitaire vous êtes venu
            sur ce site Web)
          </li>
        </ul>
        <span className={styles.subHeader}>Finalités du traitement</span>
        <p>
          Au nom de l'exploitant de ce site Web, Google utilisera ces informations pour évaluer
          votre utilisation du site Web et pour compiler des rapports sur l'activité du site Web.
          Les rapports fournis par Google Analytics sont utilisés pour analyser les performances de
          notre site Web.
        </p>
        <span className={styles.subHeader}>Bénéficiaire</span>
        <p>
          Le destinataire des données est Google Ireland Limited, Gordon House, Barrow Street,
          Dublin 4, Irlande. Google LLC, basée en Californie, aux États-Unis et éventuellement aux
          autorités américaines, peut accéder aux données stockées par Google.
        </p>
        <span className={styles.subHeader}>Transfert vers des pays tiers</span>
        <p>
          Un transfert de données vers les États-Unis ne peut être exclu. En particulier, il existe
          un risque que vos données soient traitées par les autorités américaines à des fins de
          contrôle et de surveillance, peut-être même sans possibilité de recours judiciaire. Si
          vous n'acceptez que les outils nécessaires, la transmission décrite ci-dessus n'aura pas
          lieu.
        </p>
        <span className={styles.subHeader}>Période de stockage</span>
        <p>
          Les données que nous envoyons et / ou liées aux cookies sont automatiquement supprimées
          après 12 mois. La suppression des données dont la période de conservation a expiré a lieu
          automatiquement une fois par mois. Vous pouvez également empêcher que les données générées
          par le cookie ou les analyses relatives à votre utilisation du site (y compris votre
          adresse IP) soient envoyées et traitées par Google par a. Ne donnez pas votre consentement
          au paramétrage du cookie ou b. Téléchargez et installez le module complémentaire du
          navigateur pour désactiver Google Analytics{' '}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            ICI
          </a>
          . Vous pouvez également empêcher le stockage de cookies en paramétrant votre logiciel de
          navigation en conséquence. Si vous configurez votre navigateur de manière à ce que tous
          les cookies ou outils d'analyse soient rejetés, les fonctionnalités de ce site Web et
          d'autres peuvent être restreintes.
        </p>
        <span className={styles.subHeader}>Base juridique et possibilité de rétractation</span>
        <p>
          Votre consentement est requis pour ce traitement de données, article 6 paragraphe 1, point
          a) du GDPR. Vous pouvez révoquer votre consentement à tout moment avec effet pour l'avenir
          en appelant ici les paramètres des cookies et en y modifiant votre sélection. Vous pouvez
          trouver plus d'informations sur les conditions d'utilisation de Google Analytics et la
          protection des données sur Google sur
          <a
            href="https://www.google.com/analytics/terms/fr.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            https://www.google.com/analytics/terms/fr.html{' '}
          </a>
          et sur
          <a href="https://policies.google.com/?hl=fr" target="_blank" rel="noopener noreferrer">
            {' '}
            https://policies.google.com/?hl=fr
          </a>
          .
        </p>
        <p>
          <em>d) Hotjar</em>
        </p>
        <p>
          Notre site Web utilise le service d'analyse Web Hotjar de Hotjar Ltd. Hotjar Ltd. est une
          société européenne basée à Malte (Hotjar Ltd, Level 2, St Julians Business Centre, 3, Elia
          Zammit Street, St Julians STJ 1000, Malta, Europe, Tel: +1 (855) 464-6788). Cet outil peut
          être utilisé pour suivre les mouvements sur les sites Web sur lesquels Hotjar est utilisé
          (ce que l'on appelle les cartes thermiques). Par exemple, on peut voir jusqu'où les
          utilisateurs font défiler et sur quels boutons les utilisateurs cliquent et à quelle
          fréquence. L'outil peut également être utilisé pour obtenir des commentaires directement
          des utilisateurs du site Web. Surtout, les services de Hotjar peuvent améliorer la
          fonctionnalité du site Web basé sur Hotjar en les rendant plus conviviaux, plus précieux
          et plus faciles à utiliser pour les utilisateurs finaux. Lors de l'utilisation de cet
          outil, nous accordons une attention particulière à la protection de vos données
          personnelles. Ainsi, nous ne pouvons comprendre que les boutons sur lesquels on clique, le
          parcours de la souris, la distance de défilement, la taille de l'écran de l'appareil, le
          type d'appareil et les informations du navigateur, l'emplacement géographique (uniquement
          le pays) et la langue préférée pour afficher notre site Web. Les zones des sites Web dans
          lesquelles des données personnelles vous concernant ou concernant des tiers sont affichées
          sont automatiquement masquées par Hotjar et ne sont donc jamais traçables. Afin d'exclure
          les références personnelles directes, les adresses IP ne sont stockées que de manière
          anonyme et traitées ultérieurement. Cependant, Hotjar utilise divers services tiers tels
          que Google Analytics et Optimizely. Il se peut donc que ces services collectent des
          données qui sont transmises par votre navigateur dans le cadre des demandes de pages Web.
          Ce serait, par exemple, des cookies ou votre adresse IP. Dans ces cas exceptionnels, ce
          traitement a lieu conformément à l'article 6, paragraphe 1 du RGPD basé sur le
          consentement que vous avez donné à des fins d'analyse statistique du comportement des
          utilisateurs à des fins d'optimisation et de marketing. Les données que nous envoyons et /
          ou liées aux cookies sont automatiquement supprimées après 14 mois. La suppression des
          données dont la période de conservation a expiré a lieu automatiquement une fois par mois.
          Hotjar offre à chaque utilisateur la possibilité d'empêcher l'utilisation de l'outil
          Hotjar à l'aide d'un «En-tête Ne pas suivre» afin qu'aucune donnée ne soit enregistrée sur
          la visite du site Web respectif. Il s'agit d'un paramètre pris en charge par tous les
          navigateurs courants dans les versions actuelles. Pour ce faire, votre navigateur envoie
          une demande à Hotjar pour désactiver le suivi de l'utilisateur respectif. Si vous utilisez
          nos sites Web avec différents navigateurs / ordinateurs, vous devez configurer séparément
          l'en-tête «Ne pas suivre» pour chacun de ces navigateurs / ordinateurs. Lorsque vous
          visitez un site Web basé sur Hotjar, vous pouvez empêcher Hotjar de collecter vos données
          à tout moment en vous rendant sur notre page de désactivation
          <a
            href="https://www.hotjar.com/legal/compliance/opt-out/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            https://www.hotjar.com/legal/compliance/opt-out/{' '}
          </a>
          et en cliquant sur Désactiver Hotjar . Plus d'informations sur Hotjar Ltd. et via l'outil
          Hotjar peuvent être trouvés sur:
          <a href="https://www.hotjar.com" target="_blank" rel="noopener noreferrer">
            {' '}
            https://www.hotjar.com{' '}
          </a>
          La déclaration de protection des données de Hotjar Ltd. peut être trouvé à:
          <a href="https://www.hotjar.com/privacy/" target="_blank" rel="noopener noreferrer">
            {' '}
            https://www.hotjar.com/privacy/{' '}
          </a>
        </p>
        <span className={styles.subHeader}>Google Maps</span>
        <p>
          Lors de la saisie d'adresses, nous utilisons le service API Google Maps pour terminer les
          saisies d'adresses. Les noms de rue qui correspondent à vos entrées sont suggérés. Une
          telle vérification d'adresse est importante pour nous dans le portail afin de pouvoir
          vérifier l'orthographe correcte de la rue et du code postal lors des changements d'adresse
          ou de la facture d'offres (ces informations peuvent affecter la prime calculée, entre
          autres). La base juridique à cet égard est l'article 6, paragraphe 1, point f) du RGPD.
          Grâce à la connexion établie avec Google, Google peut déterminer à partir de quel site Web
          votre demande a été envoyée et à quelle adresse IP le résultat a été transmis. Les
          informations sont généralement transmises à un serveur de Google aux États-Unis et y sont
          stockées. Si vous n'acceptez pas ce traitement, vous avez la possibilité d'empêcher
          l'installation de cookies en effectuant les réglages appropriés dans votre navigateur
          Internet. Vous pouvez trouver des détails à ce sujet sous la rubrique "Cookies" ci-dessus.
          Google propose également
        </p>
        <ul>
          <li>
            <a
              href="https://adssettings.google.com/authenticated"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://adssettings.google.com/authenticated
            </a>
          </li>
          <li>
            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
              https://policies.google.com/privacy
            </a>
          </li>
        </ul>
        <p>pour plus d'informations.</p>

        <span className={styles.subHeader}>Compilation de statistiques</span>
        <p>
          Nous créons des statistiques spécifiques pour le portail client, qui servent à optimiser
          et à développer davantage le portail. En particulier, il s'agit, par exemple, d'une
          analyse du nombre de documents que vous avez récupérés. La base juridique est notre
          intérêt légitime à optimiser notre site Web et à corriger les erreurs.
        </p>
        <span className={styles.subHeader}>
          Opposition ou révocation contre le traitement de vos données
        </span>
        <p>
          Si vous avez donné votre consentement au traitement de vos données, vous pouvez le
          révoquer à tout moment. Une telle révocation affecte l'autorisation du traitement de vos
          données personnelles après que vous nous les ayez transmises. Si nous basons le traitement
          de vos données personnelles sur la pondération des intérêts, vous pouvez vous opposer au
          traitement. C'est le cas, en particulier, si le traitement n'est pas nécessaire pour
          exécuter un contrat avec vous, que nous décrivons dans la description des fonctions.
        </p>
        <p>
          Lors de l'exercice d'une telle objection, nous vous demandons d'expliquer les raisons pour
          lesquelles nous ne devons pas traiter vos données personnelles comme nous l'avons fait. En
          cas d'objection justifiée, nous examinerons la situation et arrêterons ou ajusterons le
          traitement des données ou vous indiquerons nos raisons impérieuses dignes de protection
          sur la base desquelles nous continuerons le traitement. Vous pouvez envoyer votre
          révocation et votre opposition aux coordonnées ci-dessus.
        </p>
        <span className={styles.subHeader}>
          Droits des personnes concernées et droit de recours
        </span>
        <p>
          Vous pouvez demander des informations sur les données stockées à votre sujet sous nos
          coordonnées. De plus, sous certaines conditions, vous pouvez demander la correction ou la
          suppression de vos données. Vous pouvez également avoir le droit de restreindre le
          traitement de vos données et le droit de restituer les données que vous avez fournies dans
          un format structuré, commun et lisible par machine. Vous avez la possibilité de contacter
          le délégué à la protection des données susmentionné ou une autorité de contrôle de la
          protection des données avec une plainte. L'autorité de contrôle de la protection des
          données dont nous sommes responsables est:
        </p>
        <p>
          Commission Nationale de l’Informatique et des Libertés (CNIL)
          <br />
          3, Place de Fontenoy
          <br />
          75007 PARIS
        </p>
        <span className={styles.subHeader}>Effacement</span>
        <p>
          Au-delà des durées de conservation mentionnées ci-dessus, vos données seront supprimées
          dès qu'elles ne seront plus nécessaires. Si cela est nécessaire en raison d'exigences
          légales de conservation (par exemple lors de la conclusion d'un contrat avec nous), les
          données seront conservées pendant une période maximale de dix ans.
        </p>
        <span className={styles.subHeader}>Bénéficiaire</span>
        <p>
          Dans certains cas, nous utilisons des prestataires de services externes en tant que
          sous-traitants pour traiter vos données. Ceux-ci ont été soigneusement sélectionnés et
          commandés par nos soins, sont liés par nos instructions et sont régulièrement contrôlés.
          Les informations sur les destinataires de vos données dans le cadre d'une relation
          contractuelle avec nous s'appliquent également au traitement des données qui a lieu dans
          le portail client.
        </p>
        <span className={styles.subHeader}>Transfert de données vers un pays tiers</span>
        <p>
          Si nous transférons des données personnelles à des prestataires de services en dehors de
          l'Espace économique européen (EEE), le transfert n'aura lieu que si la Commission
          européenne a confirmé un niveau adéquat de protection des données ou d'autres garanties
          appropriées de protection des données (par exemple, des réglementations internes
          contraignantes en matière de protection des données ou des clauses contractuelles standard
          de l'UE). Vous pouvez demander des informations détaillées à ce sujet et le niveau de
          protection des données à nos prestataires de services dans les pays tiers en utilisant les
          coordonnées mentionnées ci-dessus.
        </p>
      </div>
    </Card>
  );
};

export default Communication;
