import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Tabs.module.css';
import { sendUserEvent } from '../../utils/googleAnalyticsService';
function Tabs({ tabsList, defaultSelectedTabIndex = 0 }) {
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTabIndex);

  return (
    <>
      <div className={styles.navItemContainer}>
        {tabsList &&
          tabsList.map((item, index) => (
            <span
              key={item.title}
              onClick={() => {
                index === 1 &&
                  sendUserEvent(
                    'event_ga',
                    'Document_upload',
                    'Click on document à fournir tab',
                    'Document Upload Tab Viewed'
                  );
                setSelectedTab(index);
              }}
              className={`${styles.navigationItem} ${
                index === selectedTab ? styles.highlightedNavigationItem : ''
              }`}
            >
              {item?.alert && <span className={styles.alertDot} />}
              {item.title}
            </span>
          ))}
      </div>
      {tabsList?.[selectedTab]?.content}
    </>
  );
}

Tabs.propTypes = {
  tabsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  defaultSelectedTabIndex: PropTypes.number
};

export default Tabs;
