import React from 'react';
import PropTypes from 'prop-types';
import TextLine from '../TextLine';
import styles from './heading.module.css';

function Heading({ children }) {
  return (
    <div className={styles.container}>
      <TextLine />
      <h2>{children}</h2>
    </div>
  );
}

Heading.propTypes = {
  children: PropTypes.string.isRequired
};

export default Heading;
