import React, { Component } from 'react';
import Script from 'react-load-script';
import PropTypes from 'prop-types';
import Autocomplete from './autocomplete';
import Loader from '../Loader';
import styles from './addressAutocomplete.module.css';
import './dropdownOverrideStyles.css';

class AddressAutocomplete extends Component {
  googleApiKey = 'AIzaSyBIJGJe-BiIHD_A8yleF1zCL0gDOqIG6Rc';

  constructor(props) {
    super(props);
    this.state = { scriptLoaded: false, scriptError: false };
  }

  handleScriptCreate = () => {
    this.setState({ scriptLoaded: false });
  };

  handleScriptError = () => {
    this.setState({ scriptError: true });
  };

  handleScriptLoad = () => {
    this.setState({ scriptLoaded: true });
  };

  onPlaceSelectedParsed = place => {
    if (this.props.onPlaceSelectedParsed) {
      const addressFieldComponents = {
        street_number: 'street_number',
        route: 'route',
        locality: 'locality',
        country: 'country',
        postal_code: 'postal_code',
        administrative_area_level_1: 'administrative_area_level_1'
      };
      const result = {};
      for (let i = 0; i < place.address_components.length; i += 1) {
        const addressType = place.address_components[i].types[0];
        if (addressFieldComponents[addressType]) {
          result[addressType] = place.address_components[i].long_name;
        }
      }
      this.props.onPlaceSelectedParsed({
        city: result.locality,
        country: result.country,
        postalCode: result.postal_code ? result.postal_code : '',
        street: result.route ? result.route : '',
        streetNumber: result.street_number ? result.street_number : ''
      });
    }
  };

  onPlaceSelectedUnParsed = place => {
    if (this.props.onPlaceSelected) {
      // eslint-disable-next-line no-console
      console.warn('onPlaceSelected is depricated, please switch to onPLaceSelectedParsed');
      this.props.onPlaceSelected(place);
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&libraries=places&language=fr&region=FR`}
          onCreate={this.handleScriptCreate}
          onError={this.handleScriptError}
          onLoad={this.handleScriptLoad}
        />
        {this.state.scriptLoaded ? (
          <>
            <Autocomplete
              placeholder={this.props.placeholder}
              className={styles.autocompleteInput}
              types={['geocode']}
              onPlaceSelected={place => {
                this.onPlaceSelectedParsed(place);
                this.onPlaceSelectedUnParsed(place);
              }}
              componentRestrictions={{ country: this.props.country }}
              style={{ paddingLeft: '45px' }}
            />
            <div className={styles.searchIcon}>
              <i className="icon-search" aria-hidden="true" />
            </div>
          </>
        ) : (
          <Loader show />
        )}
      </div>
    );
  }
}

AddressAutocomplete.propTypes = {
  onPlaceSelected: PropTypes.func,
  onPlaceSelectedParsed: PropTypes.func,
  country: PropTypes.string.isRequired
};

AddressAutocomplete.defaultProps = {
  onPlaceSelected: null,
  onPlaceSelectedParsed: null
};

export default AddressAutocomplete;
