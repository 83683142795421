import {
  FETCH_DOCUMENTS_FAIL,
  FETCH_DOCUMENTS_LOADING,
  FETCH_DOCUMENTS_SUCCESS
} from "./types";
import axios from "axios";
import {CUSTOMER_PORTAL_API} from "../../config/properties";


export function fetchPoliciesDocuments(policies, accountId){
  return function (dispatch) {
      dispatch({ type: FETCH_DOCUMENTS_LOADING });
      Promise.all([
        policies?.forEach(policy => axios.get(`${CUSTOMER_PORTAL_API}/document/accounts/${accountId}/policy/${policy}`)
          .then(response => {
            if (response.status === 200) {
              dispatch({
                type: FETCH_DOCUMENTS_SUCCESS,
                payload: {[policy]: response.data}
              });
            } else {
              dispatch({
                type: FETCH_DOCUMENTS_FAIL,
                payload: {[policy]: response}
              });
            }
          })
          .catch(err => {
            dispatch({type: FETCH_DOCUMENTS_FAIL, payload: {[policy]: err}});
          })
        )]
      )
  }

}