import React from 'react';
import PropTypes from 'prop-types';
import styles from './labelwrapper.module.css';

const LabelWrapper = ({label,
                        additionalStyles,
                        required,
                        children}) => {
  return (
    <div className={`${styles.masterContainer} ${additionalStyles}`}>
      <div className={styles.label}>
        {label} {required && <span className={styles.astrix}>*</span>}
      </div>
      <div>
        {children}
      </div>
    </div>
  );
};

LabelWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  additionalStyles: PropTypes.string,
  required: PropTypes.bool,
};

LabelWrapper.defaultProps = {
  label: '',
  additionalStyles: '',
  required: false,
};

export default LabelWrapper;
