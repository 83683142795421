import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ErrorMessage from '../ErrorMessage';
import { clearMessageBanner } from '../../store/Actions/messageBanner';
import styles from './messageBanner.module.css';

function MessageBanner({ message, show, level, clearMessageBannerAction, locationKey }) {
  //   if (level === 'success') {
  //     setTimeout(clearMessageBanner, 5000);
  //   }
  const [initLocationKey, setLocationKey] = useState(locationKey);

  if (initLocationKey !== locationKey) {
    if (show) {
      clearMessageBannerAction();
    }
    setLocationKey(locationKey);
  }

  return (
    <ErrorMessage
      success={level === 'success'}
      warn={level === 'warn'}
      error={level === 'error'}
      show={show}
      className={styles.errorMessage}
      message={message}
      handleClose={clearMessageBannerAction}
    />
  );
}

MessageBanner.propTypes = {
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  level: PropTypes.string,
  clearMessageBannerAction: PropTypes.func.isRequired,
  locationKey: PropTypes.string
};

MessageBanner.defaultProps = {
  level: 'warn',
  locationKey: ''
};

const mapStateToProps = state => {
  return {
    message: state.messageBanner.message,
    show: state.messageBanner.showBanner,
    level: state.messageBanner.level,
    locationKey: state.router.location.key
  };
};

export default connect(mapStateToProps, { clearMessageBannerAction: clearMessageBanner })(
  MessageBanner
);
