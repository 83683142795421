import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { sendVirtualpageView } from '../../../utils/googleAnalyticsService';
import styles from './cookies.module.css';
import Button from '../../Button';
import Checkbox from '../../Checkbox/Checkbox';
import Heading from '../../Heading';

function Cookies() {
  const { t } = useTranslation();

  const accountId = useSelector(state => state.keycloak.accountId);

  const [performanceChecked, setPerformanceChecked] = useState(false);

  const getCookie = cookieName => {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');

    let consentCookie = '';

    cookies.forEach(function(c) {
      if (c.trim().startsWith(name)) consentCookie = c;
    });

    return consentCookie;
  };

  const getExpiryDate = days => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    return date.toUTCString();
  };

  const setConsentCookie = () => {
    if (performanceChecked) {
      document.cookie = `cpCookieConsentAll=true;path=/;expires=${getExpiryDate(360)}`;
    } else if (!performanceChecked) {
      document.cookie = `cpCookieConsentAll=;path=/;expires=${new Date(Date.UTC(0, 0))}`;
    }

    document.cookie = `cpCookieConsentRequired=true;path=/;expires=${getExpiryDate(360)}`;

    window.history.back();
  };

  useEffect(() => {
    sendVirtualpageView('VirtualPageView', accountId, '/cookies', 'Cookies - User Logged In');

    if (getCookie('cpCookieConsentAll').split('=')[1] === 'true') {
      setPerformanceChecked(true);
    }

    window.scrollTo({ top: 0, left: 0 });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.displayInfo}>
        <Heading>{t('dashboard:cookies')}</Heading>
        <p className={styles.explanation}>{t('cookieSettings:explanation')}</p>
        <div className={styles.settingsContainer}>
          <div className={styles.required}>
            <Checkbox
              label={t('cookieSettings:requiredCookiesLabel')}
              id={t('cookieSettings:requiredCheckboxId')}
              name={t('cookieSettings:requiredCheckboxName')}
              additionalStyles={styles.settingsCheckbox}
              checked
              disabled
            />
            <p className={styles.settingsText}>{t('cookieSettings:requiredCookiesText')}</p>
          </div>
          <div className={styles.performance}>
            {performanceChecked ? (
              <Checkbox
                label={t('cookieSettings:performanceCookiesLabel')}
                id={t('cookieSettings:performanceCheckboxId')}
                name={t('cookieSettings:performanceCheckboxName')}
                checked={performanceChecked}
                onChange={() => {
                  setPerformanceChecked(!performanceChecked);
                }}
              />
            ) : (
              <Checkbox
                label={t('cookieSettings:performanceCookiesLabel')}
                id={t('cookieSettings:performanceCheckboxId')}
                name={t('cookieSettings:performanceCheckboxName')}
                onChange={() => {
                  setPerformanceChecked(!performanceChecked);
                }}
              />
            )}
            <p className={styles.settingsText}>{t('cookieSettings:performanceCookiesText')}</p>
          </div>
        </div>
        <Button
          handleClick={() => {
            setConsentCookie();
          }}
          additionalStyles={styles.continueButton}
        >
          {t('cookieSettings:continue')}
        </Button>
        <div className={styles.text}>
          <p className={styles.categoryExplanation}>{t('cookieSettings:categoryExplanation')}</p>

          <br />

          <p className={styles.categoryRequiredLabel}>
            <b>{t('cookieSettings:categoryLabelRequiredCookies')}</b>
          </p>
          <div className={styles.categoryText}>
            <p>{t('cookieSettings:categoryTextRequiredCookiesOne')}</p>
            <p>{t('cookieSettings:categoryTextRequiredCookiesTwo')}</p>
            <p>{t('cookieSettings:categoryTextRequiredCookiesThree')}</p>
          </div>

          <p className={styles.categoryPerformanceLabel}>
            <b>{t('cookieSettings:categoryLabelPerformanceCookies')}</b>
          </p>
          <div className={styles.categoryText}>
            <p>{t('cookieSettings:categoryTextPerformanceCookiesOne')}</p>
            <p>{t('cookieSettings:categoryTextPerformanceCookiesTwo')}</p>
            <p>{t('cookieSettings:categoryTextPerformanceCookiesThree')}</p>
            <p>
              {t('cookieSettings:categoryTextPerformanceCookiesFour')}{' '}
              <Link className={styles.link} to="/rules">
                {t('cookieSettings:privacy')}
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cookies;
