import React, { Component } from 'react';
import { connect } from 'react-redux';
import Info from './Info/Info';
import RequestCall from './RequestCall/RequestCall';
import EmailForm from './EmailForm/EmailForm';
import { sendVirtualpageView } from '../../utils/googleAnalyticsService';
import styles from './contact.module.css';

class Contact extends Component {
  componentDidMount() {
    sendVirtualpageView('VirtualPageView', this.props.accountId, '/contact', 'Contact');
  }

  render() {
    const { i18n } = this.props;

    return (
      <React.Fragment>
        <div className={styles.header}>{i18n.t('headerTitleKontakt').toUpperCase()}</div>
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <Info />
          </div>
          <div className={styles.card}>
            <RequestCall />
          </div>
          <div className={styles.card}>
            <EmailForm />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  accountId: state.keycloak.accountId
});

export default connect(mapStateToProps)(Contact);
