import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import Card from '../../../containers/Card';
import { sendVirtualpageView } from '../../../utils/googleAnalyticsService';
import styles from './cardTerm.module.css';
import Heading from '../../Heading';

function Rules() {
  const accountId = useSelector(state => state.keycloak.accountId);

  useEffect(() => {
    sendVirtualpageView('VirtualPageView', accountId, '/websiterules', 'Website Rules');
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.displayInfo}>
        <Heading>Règlement du site</Heading>
      </div>
      <Card className={styles.cardContainer}>
        <div className={styles.cardContent}>
          <div className={styles.header}>MENTIONS LEGALES</div>

          <span className={styles.paragraph}>
            <strong>Données personnelles</strong>
            <br />
            Pour consulter l’information sur le traitement de vos données personnelles, dont vos
            droits et leurs modalités d’exercice, cliquez sur la page « Protection des données
            personnelles ».
          </span>

          <span className={styles.paragraph}>
            <strong>Protection intellectuelle</strong>
            <br />
            Le contenu de ce site est protégé par application des dispositions légales et
            réglementaires concernant le droit à la propriété intellectuelle.
            <br />
            En conséquence, tout téléchargeant, toute reproduction et toute utilisation du contenu
            de ce site, autres qu’individuelles, privées, et à des fins non commerciales, est
            interdite.
          </span>

          <span className={styles.paragraph}>
            <strong>Cookies</strong>
            <br />
            Pour consulter l’information sur la gestion des cookies, cliquez sur la page « Gestion
            des cookies ».
          </span>

          <span className={styles.paragraph}>
            <strong>Editeur du site</strong>
            <br />
            Ce site est édité par Toyota Insurance Management SE – Société européenne de Courtage
            d&apos;assurance et de réassurance au capital de 165 000 Euros Siège social : 4, rue Lou
            Hemmer, L-1748 Senningerberg,
            <br />
            Grand Duchy of Luxembourg, Succursale française 36 Bd de la République 92423 Vaucresson
            Cedex, RCS Nanterre 428 171 748.
          </span>

          <span className={styles.paragraph}>
            <strong>Autorité de contrôle</strong>
            <br />
            L’autorité chargée du contrôle de Toyota Assurances est le Commissariat aux Assurances
            (7 Boulevard Joseph II, 1840 Luxembourg – <a href="http://www.caa.lu/">www.caa.lu</a>).
          </span>

          <span className={styles.paragraph}>
            <strong>Réclamations :</strong>
            <br />
            Toyota Assurances est à votre entière disposition pour vous fournir toutes les
            précisions que vous pourriez souhaiter sur votre contrat.
            <br />
            Si la réponse ne vous satisfait pas, vous pouvez adresser votre Réclamation au Service
            Réclamation – gestionnaire réclamation – 157 bureaux de la Colline, 92213 Saint Cloud
            Cedex
            <br />
            ou par mail à l’adresse{' '}
            <a href="mailto:reclamations@toyota-assurances.fr">reclamations@toyota-assurances.fr</a>
            <br />
            <br />
            Nous nous engageons à accuser réception dans les 10 jours ouvrés suivant en y apportant
            une solution. Si votre réclamation demande un examen détaillé,
            <br />
            la réponse vous parviendra dans les 2 mois qui suivent la réception de votre
            réclamation.
            <br />
            <br />
            Si la réponse ne vous satisfait toujours pas vous pouvez porter votre réclamation à
            l’échelon supérieur à l’attention du responsable juridique, puis à l’échelon de la
            Direction.
            <br />
            <br />
            Si votre désaccord persiste après les réponses données par l’assureur, vous pouvez alors
            demander l’avis du Médiateur de l’Assurance à l’adresse suivante :
            <br />
            <br />
            <div className={styles.center}>
              La Médiateur de l’Assurance
              <br />
              TSA 50110
              <br />
              75441 Paris Cedex 09
              <br />
              <a href="http://www.mediation-assurance.org/">www.mediation-assurance.org</a>
            </div>
          </span>

          <span className={styles.paragraph}>
            <strong>Intermédiaire en assurance</strong>
            <br />
            Toyota Assurances est une marque de Toyota Insurance Management SE, société de courtage.
            Toyota Insurance Management SE est inscrit à l’ORIAS sous le numéro : 07 001 417{' '}
            <a href="http://www.orias.fr/">www.orias.fr</a>.
          </span>
        </div>
      </Card>
    </>
  );
}

export default Rules;
