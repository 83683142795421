import {
  FETCH_ACCOUNT_LOADING,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAIL
} from "../Actions/types";

const initialState = {
  account: null,
  loading: false,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_LOADING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: { ...action.payload },
        loading: false
      };
    case FETCH_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
