import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { arrayToMatrix } from '../../utils/arrayToMatrix';
import styles from './paginate.module.css';

function Paginate({ itemsPerPage, children, selectorContainerClass, previousText, nextText }) {
  const [currentPageNumber, setPageNumber] = useState(0);
  const arrOfPages = arrayToMatrix(children, itemsPerPage);

  function getPages(totalPages, currentPage) {
    let startPage;
    let endPage;

    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 5 total pages so calculate start and end pages
      // disabled because code is clearer this way.
      // eslint-disable-next-line no-lonely-if
      if (currentPage <= 2) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 3;
      }
    }
    // create an array of page numbers
    const pages = [...Array(endPage + 1 - startPage).keys()].map(i => startPage + i);

    // TODO: add logic to inject maybe a 1st and last page if out of range along with a spacer '...'
    return pages;
  }
  const arrOfPageNumbers = getPages(arrOfPages.length, currentPageNumber);

  const pageNumbers = arrOfPageNumbers.map(item => {
    return (
      <button
        onClick={() => setPageNumber(item - 1)}
        className={item - 1 === currentPageNumber ? styles.numberSelected : styles.number}
        type="button"
        key={currentPageNumber}
      >
        {item}
      </button>
    );
  });

  const increment = () => {
    if (currentPageNumber < arrOfPages.length - 1) {
      setPageNumber(currentPageNumber + 1);
    }
  };

  const decrement = () => {
    if (currentPageNumber > 0) {
      setPageNumber(currentPageNumber - 1);
    }
  };

  return (
    <div className={styles.container}>
      {arrOfPages[currentPageNumber]}
      <div className={`${selectorContainerClass} ${styles.selector}`}>
        <button className={styles.prev} onClick={decrement} type="button">
          {previousText}
        </button>
        {pageNumbers}
        <button className={styles.next} onClick={increment} type="button">
          {nextText}
        </button>
      </div>
    </div>
  );
}

Paginate.propTypes = {
  itemsPerPage: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  selectorContainerClass: PropTypes.string,
  previousText: PropTypes.string,
  nextText: PropTypes.string
};

Paginate.defaultProps = {
  itemsPerPage: 5,
  selectorContainerClass: styles.pageSelector,
  previousText: 'Previous',
  nextText: 'Next'
};

export default Paginate;
