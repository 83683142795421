import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './navItems.module.css';
import { sendUserEvent } from '../../../utils/googleAnalyticsService';

export default function NavItems(props) {
  const { i18n, unfulfilledDocs } = props;

  return (
    <>
      <NavLink
        to="/"
        exact
        className={styles.navItem}
        activeClassName={styles.navItemActive}
        onClick={props.clickHandler}
      >
        {i18n.t('navBarItem.item_one')}
      </NavLink>
      <NavLink
        to="/documents"
        className={styles.navItem}
        activeClassName={styles.navItemActive}
        onClick={() => {
          sendUserEvent(
            'event_ga',
            'Documents',
            'Document Header Click',
            'Document Section Viewed using Header'
          );
        }}
      >
        <div className={styles.navItemAlert}>
          {unfulfilledDocs && <span className={styles.navItemAlertDot} />}
          <span>{i18n.t('navBarItem.my_documents_section')}</span>
        </div>
      </NavLink>
      <NavLink
        to="/account"
        className={styles.navItem}
        activeClassName={styles.navItemActive}
        onClick={props.clickHandler}
      >
        {i18n.t('navBarItem.item_two')}
      </NavLink>
      <NavLink
        to="/contact"
        className={styles.navItem}
        activeClassName={styles.navItemActive}
        onClick={props.clickHandler}
      >
        {i18n.t('navBarItem.item_three')}
      </NavLink>
    </>
  );
}
