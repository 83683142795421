import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './navBarAccountMobile.module.css';

import Dropdown from '../Dropdown';

export class NavBarAccountMobile extends Component {
  constructor(props) {
    super(props);
    const initialState = this.props.links.map((link, index) => {
      return {
        id: index,
        title: link.text,
        selected: false,
        value: link.location,
        key: 'navItems'
      };
    });
    this.state = {
      navItems: initialState
    };
  }

  navElements = this.props.links.map((link, index) => {
    return {
      id: index,
      title: link.text,
      selected: false,
      value: link.location,
      key: 'navItems'
    };
  });

  handleDropdownChange = (id, key) => {
    this.props.history.push(this.navElements[id].value);
  };

  matchRoute = pathname => {
    let title = 'Navigation';
    this.state.navItems.forEach(item => {
      if (pathname === '/account') {
        title = 'Adresse';
        return;
      }
      if (item.value === pathname) {
        title = item.title;
        return;
      }
    });
    return title;
  };

  render() {
    let currentRoute = this.matchRoute(this.props.history.location.pathname);
    return (
      <>
        <div className={styles.container}>
          <Dropdown
            title={currentRoute}
            list={this.state.navItems}
            resetThenSet={this.handleDropdownChange}
            dropdownBGColor={this.props.dropdownBGColor}
            navDropdown
          />
        </div>
      </>
    );
  }
}

NavBarAccountMobile.propTypes = {
  links: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  dropdownBGColor: PropTypes.string
};
NavBarAccountMobile.defaultProps = {
  dropdownBGColor: null
};

export default NavBarAccountMobile;
