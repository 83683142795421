import React from 'react';
import PropTypes from 'prop-types';
import styles from './label.module.css';

const Label = ({ children, required }) => {
  return (
    <div className={styles.label}>
      {children} {required && <span className={styles.astrix}>*</span>}
    </div>
  );
};
Label.propTypes = {
  children: PropTypes.string.isRequired,
  required: PropTypes.bool
};

Label.defaultProps = {
  required: false
};

export default Label;
