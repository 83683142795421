import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '../../Card';
import Loader from '../../../common/Loader';
import Button from '../../../common/Button';
import Dropdown from '../../../common/Dropdown';
import CalendarDropdown from '../../../common/CalendarDropdown';
import calculateAge from '../../../utils/calculateAge';
import { sendVirtualpageView } from '../../../utils/googleAnalyticsService';
import styles from './addDriver.module.css';
import { addNewDriver } from '../../../store/Actions/addNewDriverAction';
import Input from '../../../common/Input';
import Label from '../../../common/Label';

class AddDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarBirthDate: new Date(),
      calendarPermDate: new Date(),
      calendarEffectiveDate: new Date(),
      calendarDateEffectiveError: false,
      newDriverData: {
        salutation: '',
        firstName: '',
        lastName: '',
        birthDate: '',
        bonus: '',
        relationToCustomer: '',
        driverLicenseNr: '',
        driverLicensePermDate: '',
        effectiveDate: ''
      },
      errorBirthdate: false,
      salutations: [
        {
          id: 0,
          title: 'Mme',
          selected: false,
          value: 'Mme',
          key: 'title'
        },
        {
          id: 1,
          title: 'Mr',
          selected: false,
          value: 'Mr',
          key: 'title'
        }
      ]
    };
  }

  componentDidMount() {
    sendVirtualpageView('VirtualPageView', this.props.accountId, '/addDriver', 'Add Driver');
  }

  handleChange = ({ currentTarget: input }) => {
    const { newDriverData } = { ...this.state };
    newDriverData[input.name] = input.value;
    this.setState({ newDriverData });
  };

  handleDropdownChange = id => {
    const { newDriverData, salutations } = { ...this.state };

    salutations.forEach(item => {
      item.selected = false;
    });
    salutations[id].selected = true;

    this.setState({
      newDriverData: { ...newDriverData, salutation: salutations[id].value }
    });
  };

  handleSubmit = evnt => {
    evnt.preventDefault();
    const { newDriverData } = this.state;
    newDriverData.driverLicenseNr = parseInt(newDriverData.driverLicenseNr, 10);
    const policyNumber = sessionStorage.getItem('SelectedPolicyNumber');
    this.props.addNewDriver(newDriverData, this.props.accountId, policyNumber, this.props.history);
  };

  formatDateForBackend = date => {
    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;
    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    return `${date.getFullYear()}-${month}-${day}`;
  };

  onCalendarChange = (date, fieldName, calendarName) => {
    const parsedDate = this.formatDateForBackend(date);
    this.setState(prevState => ({
      [calendarName]: date,
      newDriverData: { ...prevState.newDriverData, [fieldName]: parsedDate }
    }));
  };

  onBirthdateChange = (date, fieldName, calendarName) => {
    if (calculateAge(date) < 18) {
      this.setState(prevState => ({
        ...prevState.errorBirthdate,
        errorBirthdate: true
      }));
    } else {
      this.setState(prevState => ({
        ...prevState.errorBirthdate,
        errorBirthdate: false
      }));

      this.onCalendarChange(date, fieldName, calendarName);
    }
  };

  substractDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  };

  validateEffectiveDate = date => {
    if (date instanceof Date) {
      let past = false;

      if (date < new Date()) {
        past = true;
      }

      this.setState(() => ({
        calendarDateEffectiveError: past
      }));
    }
  };

  render() {
    const { newDriverData } = this.state;
    return (
      <React.Fragment>
        <Loader show={this.props.loading} />

        <div className={styles.header}>AJOUTER UN CONDUCTEUR</div>
        <Card>
          <div className={styles.cardContent}>
            <div className={styles.text}>
              <p>
                Veuillez remplir le formulaire ci-dessous si vous souhaitez ajouter un autre
                conducteur. En cas de conduite accompagnée, merci de contacter notre service clients
                au 01.41.12.20.73.
              </p>
            </div>

            <hr className={styles.divider} />

            <div className={styles.form}>
              <Dropdown
                className={styles.dropdown}
                navDropdown
                dropdownBGColor="#e4e4e4"
                placeholder="Titre"
                title={this.state.newDriverData.salutation}
                list={this.state.salutations}
                resetThenSet={id => this.handleDropdownChange(id)}
                required
              />
              <Input
                additionalStyles={styles.input}
                value={newDriverData.firstName}
                name="firstName"
                onChange={this.handleChange}
                placeholder="Prénom"
                required
              />
              <Input
                additionalStyles={styles.input}
                value={newDriverData.lastName}
                name="lastName"
                onChange={this.handleChange}
                placeholder="Nom de Famille"
                required
              />
              <Label required>Date naissance</Label>
              <CalendarDropdown
                className={styles.datePicker}
                date={this.state.calendarBirthDate}
                onChange={date => this.onBirthdateChange(date, 'birthDate', 'calendarBirthDate')}
                placeholder="jj.mm.aaaa"
              />
              {this.state.errorBirthdate && (
                <p className={styles.error}>
                  Le conducteur à assurer doit être âgé d&apos;au moins 18 ans
                </p>
              )}
              <Input
                additionalStyles={styles.input}
                value={newDriverData.bonus}
                name="bonus"
                onChange={this.handleChange}
                placeholder="Bonus"
                required
              />
              <Input
                additionalStyles={styles.input}
                value={newDriverData.relationToCustomer}
                name="relationToCustomer"
                onChange={this.handleChange}
                placeholder="Lien avec l'assuré"
                required
              />
              <Input
                additionalStyles={styles.input}
                value={newDriverData.driverLicenseNr}
                name="driverLicenseNr"
                onChange={this.handleChange}
                placeholder="Numéro de permis de conduire"
                required
              />
              <Label required>Date d&apos;émission du permis de conduire</Label>
              <CalendarDropdown
                className={styles.datePicker}
                date={this.state.calendarPermDate}
                onChange={date =>
                  this.onCalendarChange(date, 'driverLicensePermDate', 'calendarPermDate')
                }
                placeholder="jj.mm.aaaa"
              />
              <Label required>Quand voudriez-vous que cela prenne effet?</Label>
              <CalendarDropdown
                className={styles.datePicker}
                date={this.state.calendarEffectiveDate}
                minDate={new Date()}
                onChange={date => {
                  this.onCalendarChange(date, 'effectiveDate', 'calendarEffectiveDate');
                  this.validateEffectiveDate(date);
                }}
                placeholder="jj.mm.aaaa"
              />
              {this.state.calendarDateEffectiveError ? (
                <span className={styles.error}>La date ne doit pas se situer dans le passé</span>
              ) : null}
              <hr className={styles.divider} />
              <Button additionalStyles={styles.button} handleClick={this.handleSubmit}>
                Ajouter un conducteur
              </Button>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

AddDriver.propTypes = {
  accountId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  addNewDriver: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  accountId: state.accountIdUser.accountId,
  policyNumber: state.accountIdUser.policyNumber,
  loading: state.addNewDriver.loading
});

export default connect(mapStateToProps, { addNewDriver })(AddDriver);
