/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './dropdown.module.css';

const Dropdown = ({list, title,navDropdown, dropdownBGColor, className, disabled, resetThenSet, placeholder, required }) => {
  const [listOpen, setListOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(title);
  const [userHasSelected, setUserHasSelected] = useState(false);
  useEffect(() => {
    const selected = list.find(item => item?.selected)
    if (selected){
      setHeaderTitle(_ => selected?.title)
      setUserHasSelected(prev => !prev)
    }
  }, [list]);

  useEffect(() => {
    const handleOutsideClick = () => {
      if (listOpen) {
        setListOpen(false);
      }
    };

    if (listOpen) {
      window.addEventListener('click', handleOutsideClick);
    } else {
      window.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [listOpen]);

  const selectItem = (title, id, stateKey) => {
    setHeaderTitle(title);
    setListOpen(false);
    setUserHasSelected(true);
    resetThenSet(id, stateKey);
  };

  const toggleList = () => {
    if (!disabled) {
      setListOpen((prevListOpen) => !prevListOpen);
    }
  };

  return (
    <div className={`${className} ${styles.ddwrapper}`}>
      <div
        role="button"
        tabIndex="0"
        className={styles.ddheader}
        style={{ backgroundColor: dropdownBGColor }}
        onClick={toggleList}
        onKeyPress={toggleList}
      >
        <div
          className={
            required && !userHasSelected
              ? styles.ddHeaderRequiredTitle
              : styles.ddheadertitle
          }
        >
          {navDropdown || !userHasSelected
            ? title
              ? title
              : placeholder
            : headerTitle}
        </div>
        {listOpen ? (
          <i className={styles.upchevron} />
        ) : (
          <i
            className={`${disabled ? styles.disabledChevron : null} ${styles.downchevron}`}
          />
        )}
      </div>
      {listOpen && (
        <ul role="presentation" className={styles.ddlist} onClick={(e) => e.stopPropagation()}>
          {list.map((item) => (
            <li
              role="presentation"
              className={styles.ddlistitem}
              key={item.id}
              onClick={() => selectItem(item.title, item.id, item.key)}
            >
              {item.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  resetThenSet: PropTypes.func.isRequired,
  dropdownBGColor: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  list: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  navDropdown: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string
};

Dropdown.defaultProps = {
  title: '',
  disabled: false,
  placeholder: '',
  dropdownBGColor: null,
  navDropdown: false,
  required: false,
  className: ''
};

export default Dropdown;
