import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './documentInformationTab.module.css';
import { ReactComponent as Upload } from '../../../assets/icons/Upload.svg';
import { ReactComponent as Valid } from '../../../assets/icons/Check.svg';
import { ReactComponent as Rejected } from '../../../assets/icons/Cross.svg';
import { ReactComponent as Verification } from '../../../assets/icons/Pending.svg';
import { ReactComponent as Pending } from '../../../assets/icons/error.svg';

function UploadableDocument({ title, documents, width, onShowUploader }) {
  const statusData = {
    validated: { styles: 'validated', icon: <Valid width={30} height={30} />, text: 'Validé' },
    rejected: {
      styles: 'rejected',
      icon: <Rejected width={15} height={15} />,
      text: 'Non conforme'
    },
    received: { styles: 'received', icon: <Verification />, text: 'En cours de vérification' },
    pending: { styles: 'pending', icon: <Pending width={30} height={30} />, text: 'En attente' },
    'not validated': {
      styles: 'pending',
      icon: <Rejected width={15} height={15} />,
      text: 'Non conforme'
    },
    cancelled: { styles: 'rejected', icon: <Rejected width={15} height={15} />, text: 'Annulé' }
  };

  const uploadable = useCallback(({ status, canResend }) => {
    return (
      (canResend && !['received'].includes(status)) ||
      !['received', 'validated', 'cancelled'].includes(status)
    );
  }, []);

  return (
    <>
      {width > 767 ? <div style={{ padding: '28px 0' }}>{title}</div> : null}
      {documents?.map((document, index) =>
        width > 767 ? (
          <div
            key={document['name'] + index}
            className={styles.mainDiv}
            onClick={_ =>
              document.fileType !== 'autre' && uploadable(document) && onShowUploader(document)
            }
          >
            <div className={styles.statusIcon} style={{ width: '3%' }}>
              {statusData[document['status']]?.icon}
            </div>
            <div className={styles.documentInfoDiv}>
              <div className={styles.titleDiv}>
                <div>{document['name']}</div>
                {document.fileType !== 'autre' && (
                  <div
                    className={`${styles.statusBox} ${
                      styles[statusData[document['status']]?.styles]
                    }`}
                  >
                    {statusData[document['status']]?.text}
                  </div>
                )}
              </div>
              <div>
                <p className={styles.description}>{document['description']}</p>
              </div>
            </div>
            <div className={styles.statusIcon} style={{ width: '5%' }}>
              {document.fileType !== 'autre' && uploadable(document) && <Upload />}
            </div>
          </div>
        ) : (
          <div
            key={document['name']}
            className={styles.mainMobileDiv}
            onClick={_ =>
              document.fileType !== 'autre' && uploadable(document) && onShowUploader(document)
            }
          >
            {document.fileType !== 'autre' && (
              <div className={styles.statusDiv}>
                <div className={styles.statusIcon} style={{ width: '3%' }}>
                  {statusData[document['status']]?.icon}
                </div>
                <div
                  className={`${styles.statusBox} ${
                    styles[statusData[document['status']]?.styles]
                  }`}
                >
                  {statusData[document['status']]?.text}
                </div>
              </div>
            )}
            <div className={styles.textDiv}>
              <div className={styles.mobileDocumentInfoDiv}>
                <div className={styles.titleDiv}>
                  <div className={styles.fileName}>{document['name']}</div>
                </div>
                <div style={{ fontSize: '0.7rem' }}>{document['description']}</div>
              </div>
              <div className={styles.statusIcon} style={{ width: '5%' }}>
                {document.fileType !== 'autre' && uploadable(document) && <Upload />}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}

UploadableDocument.propTypes = {
  documentIcon: PropTypes.any,
  showStatus: PropTypes.bool,
  iconClickFucntion: PropTypes.func
};

export default UploadableDocument;
