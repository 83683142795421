import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import NotFound from '../containers/notFound';
import Policies from '../containers/Dashboard/policies';
import Contact from '../containers/Contact/Contact';
import Account from '../containers/account/Account';
import Coverages from '../containers/Coverages/Coverages';
import Documents from '../containers/Documents';
import AddDriver from '../containers/userChanges/AddDriver/addDriver';
import RemoveDriver from '../containers/userChanges/RemoveDriver/removeDriver';
import ChangeVehicle from '../containers/userChanges/ChangeVehicle/changeVehicle';
import ChangePaymentDetails from '../containers/userChanges/ChangePaymentDetails/ChangePaymentDetails';
import ChangeAddress from '../containers/userChanges/ChangeAddress/ChangeAddress';
import Rules from '../common/Footer/sections/rules';
import PersonalInformation from '../common/Footer/sections/personalInformation';
import Cookies from '../common/Footer/sections/cookies';
import ContractTermination from "../containers/userChanges/ContractTermination/contractTermination";

const Routes = ({ i18n }) => (
  <Switch>
    <Route path="/" exact render={props => <Policies i18n={i18n} {...props} />} />
    <Route path="/policies" exact render={props => <Policies i18n={i18n} {...props} />} />
    <Route path="/coverages" exact render={props => <Coverages i18n={i18n} {...props} />} />
    <Route path="/documents" exact render={props => <Documents i18n={i18n} {...props} />} />
    <Route path="/documents/:id" exact render={props => <Documents i18n={i18n} {...props} />} />
    <Route path="/account" render={props => <Account i18n={i18n} {...props} />} />
    <Route path="/changeAddress" exact render={props => <ChangeAddress i18n={i18n} {...props} />} />
    <Route path="/addDriver" exact render={props => <AddDriver i18n={i18n} {...props} />} />
    <Route path="/removeDriver" exact render={props => <RemoveDriver i18n={i18n} {...props} />} />
    <Route path="/changeVehicle" exact render={props => <ChangeVehicle i18n={i18n} {...props} />} />
    <Route path="/contact" exact render={props => <Contact i18n={i18n} {...props} />} />
    <Route path="/contractTermination/:policyNumber" exact render={props => <ContractTermination i18n={i18n} {...props} />} />
    <Route
      path="/changePaymentDetails"
      exact
      render={props => <ChangePaymentDetails i18n={i18n} {...props} />}
    />
    <Route path="/rules" exact render={props => <Rules i18n={i18n} {...props} />} />
    <Route
      path="/personalInformation"
      exact
      render={props => <PersonalInformation i18n={i18n} {...props} />}
    />
    <Route path="/cookies" exact render={props => <Cookies i18n={i18n} {...props} />} />
    <Route path="/not-found" exact component={NotFound} />
    <Redirect to="/not-found" exact />
  </Switch>
);

export default Routes;
