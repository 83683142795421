import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestCall } from '../../../store/Actions/requestCallAndEmailAction';
import { checkValidity, updateObject } from '../../../utils/formUtil';
import Loader from '../../../common/Loader';
import Input from '../../../common/Input';
import Button from '../../../common/Button';
import styles from './requestCall.module.css';

class RequestCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneData: {
        phone: {
          value: '',
          isValid: false,
          touched: false,
          validation: {
            required: false
          },
          formIsValid: true
        }
      }
    };
  }

  handleChange = event => {
    const controlName = event.target.name;
    const value = event.target.value;
    const { phoneData } = this.state;

    const updatedPhoneData = updateObject(phoneData[controlName], {
      value,
      isValid: checkValidity(value, phoneData[controlName].validation),
      touched: true
    });

    const updatedPhoneDataElement = updateObject(phoneData, {
      [controlName]: updatedPhoneData
    });

    let formIsValid = true;
    formIsValid = updatedPhoneDataElement[event.target.name].isValid && formIsValid;

    // We  neds to implements that later
    this.setState({
      phoneData: updatedPhoneDataElement,
      formIsValid
    });
  };

  handleSubmit = () => {
    const { phoneData } = this.state;
    const { accountId } = this.props;
    const paylaod = {
      phone: phoneData.phone.value
    };

    this.props.requestCall(accountId, paylaod);
  };

  render() {
    const { phoneData } = this.state;
    const { loading } = this.props;
    return (
      <React.Fragment>
        <Loader show={loading} />
        <span className={styles.header}>Demander un rappel</span>
        <div className={styles.textContainer}>
          Laissez nous vos coordonnées, nous vous recontacterons durant les horaires
          d&apos;ouverture du service clients
        </div>
        <Input
          additionalStyles={styles.numberInput}
          name="phone"
          onChange={this.handleChange}
          value={phoneData.phone.value}
          placeholder="Votre numéro de téléphone"
        />
        <Button type="submit" handleClick={this.handleSubmit}>
          Demander un rappel
        </Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  accountId: state.keycloak.accountId,
  loading: state.requestCallAndEmail.loadingRequestCall
});

RequestCall.propTypes = {
  loading: PropTypes.bool.isRequired,
  accountId: PropTypes.string.isRequired
};

export default connect(mapStateToProps, { requestCall })(RequestCall);
