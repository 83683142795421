import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  fr: {
    translation: {
      navBarItem: {
        item_one: "Mes Polices d'assurance",
        item_two: 'Mon compte',
        my_documents_section: 'Mes documents',
        item_three: 'Contactez-nous'
      },
      headerTitlePolicies: "Mes Polices d'assurance",
      headerTitleAccount: 'Mon Compte',
      headerTitleKontakt: 'Contactez-nous',
      insuranceId: 'Numéro de police',
      premium: 'Prime',
      comprehensive: 'Casco',
      coinsurance: 'auto-assurance',
      active: 'Active',
      expiration: "Date d'expiration",
      month: 'mois',
      dropdownCoverage: 'Niveau de couverture',
      coverage: 'Responsabilité civile',
      exampleLiability: '',
      liability: 'Lettre de protection',
      legalProtection: 'Protection juridique',
      accidentInsurance: 'Assurance accident',
      dropdownPayment: 'Détails de paiement',
      depositor: 'Titulaire du compte',
      iban: 'IBAN',
      dropdownDocs: 'Documents digitales',
      makeChange: 'Faire un changement',
      changeDriver: 'Changement de conducteur',
      changeVehicle: 'Changement de véhicule',
      changeAdress: "Changement d'adresse",
      viewDoc: 'Afficher',
      messageBanner: {
        genericError: "Désolé, une erreur inattendue s'est produite"
      }
    },
    cookieSettings: {
      explanation: 'Les cookies de ce site sont divisés en 2 catégories selon leur fonction.',
      requiredCookiesLabel: 'Cookies/Outils nécessaires',
      requiredCookiesText:
        'Ces paramètres ne peuvent pas être modifiés, mais vous pouvez toujours modifier les paramètres des cookies dans votre navigateur.',
      performanceCookiesLabel: 'Cookies/Outils pour analyse',
      performanceCookiesText:
        "Le comportement des utilisateurs est enregistré à des fins d'analyse et de développement ultérieur du site web.",
      continue: 'Continuer',
      categoryExplanation: 'Les cookies sont divisés en 2 catégories:',
      categoryLabelRequiredCookies: 'Cookies/outils nécessaires:',
      categoryTextRequiredCookiesOne:
        "Nous utilisons ces cookies pour garantir le fonctionnement et la sécurité du site web. Les cookies peuvent être bloqués dans le navigateur, mais il est alors possible que vous ne puissiez pas utiliser toutes les fonctions du site web. Les cookies sont supprimés à l'expiration de la session ou, au plus tard, après douze mois.",
      categoryTextRequiredCookiesTwo:
        "Nous utilisons également des cookies pour enregistrer votre décision pour ou contre les analyses du site web. Ils comprennent la date et l'heure, des informations sur le navigateur, une version tronquée de l'adresse IP et des informations indiquant si quelqu'un a consenti à des analyses. De cette manière, nous remplissons notre obligation légale de documenter le consentement ou la rétractation du consentement. Ces cookies sont supprimés au plus tard après douze mois.",
      categoryTextRequiredCookiesThree:
        "La base juridique est notre intérêt légitime, conformément à l'article 6, paragraphe 1, point f) du RGPD, aux fins susmentionnées.",
      categoryLabelPerformanceCookies: 'Cookies/Outils pour analyse:',
      categoryTextPerformanceCookiesOne:
        "Nous utilisons Google Analytics pour enregistrer statistiquement votre comportement d'utilisateur et pour améliorer continuellement notre site web et le rendre plus convivial. L'autre destinataire des données est Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irlande.",
      categoryTextPerformanceCookiesTwo:
        'Google LLC, dont le siège est en Californie, aux États-Unis et, le cas échéant, les autorités américaines, peuvent accéder aux données stockées par Google. ',
      categoryTextPerformanceCookiesThree:
        "Les données analytiques/cookies sont supprimées au plus tard après 12 mois. La base juridique est votre consentement conformément à l'article 6, paragraphe 1, point a) du RGPD.",
      categoryTextPerformanceCookiesFour:
        "Vous pouvez trouver plus d'informations sur les cookies dans la",
      privacy: 'Politique de confidentialité',
      performanceCheckboxId: 'performanceCheckboxId',
      performanceCheckboxName: 'performanceCheckboxName',
      requiredCheckboxId: 'requiredCheckboxId',
      requiredCheckboxName: 'requiredCheckboxName'
    },
    dashboard: {
      cookies: "COOKIES SUR LE PORTAIL CLIENT DE L'ASSURANCE AUTOMOBILE TOYOTA"
    }
  }
};

i18next
  .use(initReactI18next) // passes i18next down to react-i18next
  .init({
    resources,
    lng: 'fr',

    keySeparator: '.',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18next;
