import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { CUSTOMER_PORTAL_API } from '../../../config/properties';
import { addMessageBanner } from '../../../store/Actions/messageBanner';
import Loader from '../../../common/Loader';
import Button from '../../../common/Button';
import Dropdown from '../../../common/Dropdown';
import Checkbox from '../../../common/Checkbox/Checkbox';
import { sendVirtualpageView } from '../../../utils/googleAnalyticsService';
import styles from './changePaymentDetails.module.css';
import { changePaymentDetails as postChangePaymentDetails } from '../../../store/Actions/changePaymentDetailsAction';
import { getPolicy } from '../../../utils/getPolicy';

function ChangePaymentDetails({
  accountId,
  loading,
  policies,
  postChangeDetails,
  addMessageBanner
}) {
  const [showAccountholderForm, setShowAccountholderForm] = useState(false);
  const title = [
    {
      id: 0,
      title: 'Mme',
      value: 'Mme'
    },
    {
      id: 1,
      title: 'Mr',
      value: 'Mr'
    }
  ];

  useEffect(() => {
    sendVirtualpageView(
      'VirtualPageView',
      accountId,
      '/changePaymentDetails',
      'Change Payment Details'
    );
    // eslint-disable-next-line
  }, []);

  const handleDropdownChange = (id, setFormikFieldValue) => {
    setFormikFieldValue('title', title[id].value);
  };

  const handleCheckboxClick = () => {
    setShowAccountholderForm(!showAccountholderForm);
  };

  const getPaymentDetails = policyNumber => {
    return getPolicy(policies, policyNumber)?.paymentDetails;
  };

  const handleSubmit = values => {
    const { iban, title, firstName, lastName } = values;

    const cleanedIban = iban.replace(/\s/g, '');
    if (cleanedIban) {
      axios
        .post(
          `${CUSTOMER_PORTAL_API}/policy/accounts/${accountId}/iban/${encodeURIComponent(
            cleanedIban
          )}`
        )
        .then(response => {
          if (response.status === 204) {
            const policyNumber = sessionStorage.getItem('SelectedPolicyNumber');
            const paymentDetails = getPaymentDetails(policyNumber);
            const payload = {
              id: paymentDetails.id,
              paymentMethod: paymentDetails.paymentMethod
            };

            if (showAccountholderForm) {
              payload.bankAccount = {
                iban: cleanedIban,
                ownerName: `${title} ${firstName} ${lastName}`,
                changeHolder: true
              };
            } else {
              payload.bankAccount = {
                iban: cleanedIban,
                ownerName: paymentDetails.bankAccount.ownerName,
                changeHolder: false
              };
            }

            postChangeDetails(payload, policyNumber, accountId);
          }
        })
        .catch(err => {
          if (err.response.status === 400) {
            addMessageBanner(
              'error',
              "L'IBAN saisi est incorrect. Veuillez vérifier et corriger vos données."
            );
          } else if (err.response.status === 403) {
            addMessageBanner(
              'error',
              "L'IBAN saisi n'est pas valable. Veuillez vérifier vos entrées et les corriger."
            );
          }
        });
    }
  };

  const policyNumber = sessionStorage.getItem('SelectedPolicyNumber');

  const paymentDetails = getPaymentDetails(policyNumber);
  const accountHolderName = paymentDetails?.bankAccount.ownerName;

  const newAccountHolderSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    firstName: Yup.string().min(2).max(40).required('First name is required'),
    lastName: Yup.string().min(2).max(40).required('Last name is required'),
    iban: Yup.string().required('You must enter an IBAN')
  });
  const changeIbanOnlySchema = Yup.object().shape({
    iban: Yup.string().required('You must enter an IBAN')
  });

  const initialValues = {
    iban: '',
    title: '',
    firstName: '',
    lastName: ''
  };

  return (
    <React.Fragment>
      <Loader show={loading} />
      <div className={styles.header}>CHANGER LES DÉTAILS DE PAIMENT</div>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <div className={styles.text}>
            <p>
              Si vous souhaitez changer le mode de paiement de votre assurance, indiquez-nous vos
              nouvelles coordonnées bancaires.
            </p>
          </div>

          <hr className={styles.divider} />

          <div className={styles.accountHolderContainer}>
            <Checkbox
              label="Si le titulaire du compte bancaire est différent de celui de l'assuré"
              key="newHolder"
              checked={showAccountholderForm}
              onChange={handleCheckboxClick}
            />
          </div>

          <div className={styles.form}>
            <Formik
              initialValues={initialValues}
              validationSchema={
                showAccountholderForm ? newAccountHolderSchema : changeIbanOnlySchema
              }
              onSubmit={values => handleSubmit(values)}
            >
              {({ setFieldValue, errors, touched }) => (
                <>
                  <Form className={styles.form}>
                    {showAccountholderForm ? (
                      <>
                        <div
                          className={`${styles.dropdownWrapper} + ${
                            errors.title && touched.title ? styles.inputError : null
                          }`}
                        >
                          <Dropdown
                            title="Titre"
                            list={title}
                            resetThenSet={id => handleDropdownChange(id, setFieldValue)}
                          />
                        </div>
                        <Field name="firstName">
                          {({ field }) => (
                            <input
                              {...field}
                              className={
                                errors.firstName && touched.firstName ? styles.inputError : null
                              }
                              placeholder="Prénom"
                            />
                          )}
                        </Field>
                        <Field name="lastName">
                          {({ field }) => (
                            <input
                              {...field}
                              className={
                                errors.lastName && touched.lastName ? styles.inputError : null
                              }
                              placeholder="Nom de famille"
                            />
                          )}
                        </Field>
                      </>
                    ) : null}

                    {!showAccountholderForm ? <input value={accountHolderName} readOnly /> : null}

                    <Field name="iban">
                      {({ field }) => (
                        <input
                          {...field}
                          className={errors.iban && touched.iban ? styles.inputError : null}
                          placeholder="IBAN"
                        />
                      )}
                    </Field>

                    <hr className={styles.divider} />

                    <div className={styles.buttonHolder}>
                      <Button type="submit" disabled={loading}>
                        Changer les détails de paiment
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  loading: state.changePaymentDetails.loading,
  accountId: state.keycloak.accountId,
  policies: state.policies.policies
});

ChangePaymentDetails.propTypes = {
  postChangeDetails: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  accountId: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  policies: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default connect(mapStateToProps, {
  addMessageBanner,
  postChangeDetails: postChangePaymentDetails
})(ChangePaymentDetails);
