import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Card from '../Card';
import { sendVirtualpageView } from '../../utils/googleAnalyticsService';
import styles from './coverages.module.css';
import { getPolicy } from '../../utils/getPolicy';
function Coverages(props) {
  const policyNumber = sessionStorage.getItem('SelectedPolicyNumber');
  const policies = useSelector(state => state.policies.policies);
  const guarantees = getPolicy(policies, policyNumber).risks[0].guarantees;
  const accountId = useSelector(state => state.accountIdUser.accountId);

  useEffect(() => {
    sendVirtualpageView('VirtualPageView', accountId, '/coverages', 'Coverages');
    // eslint-disable-next-line
  }, []);

  function renderTableData() {
    return guarantees.map(guarantee => {
      const { type, description, franchise } = guarantee;

      return (
        <tr key={type}>
          <td>{description}</td>
          <td className={styles.center}>{franchise}</td>
        </tr>
      );
    });
  }

  return (
    <>
      <h1 className={styles.header}>Couverture d'assurance</h1>
      <Card>
        <div className={styles.container}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.tableHeader}>
                <th key="coverage">Couverture</th>
                <th key="franchise" className={styles.center}>
                  Franchise €
                </th>
              </tr>
            </thead>
            <tbody>{renderTableData()}</tbody>
          </table>
        </div>
      </Card>
    </>
  );
}

export default Coverages;
