import { CHANGE_VEHICLE_LOADING, CHANGE_VEHICLE_SUCCESS, CHANGE_VEHICLE_FAIL } from "../Actions/types";

const initialState = {
  success: false,
  response: null,
  error: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_VEHICLE_LOADING:
      return {
        ...initialState,
        loading: true
      }
    case CHANGE_VEHICLE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false
      };
    case CHANGE_VEHICLE_FAIL:
      return {
        ...state,
        error: true,
        loading: false
      };
    default:
      return state;
  }
}
