import React from 'react';
import PropTypes from 'prop-types';
import PolicyCard from '../PolicyCard';
import Paginate from '../../common/paginate';
import styles from './policyCardList.module.css';

function PolicyCardList(props) {
  const { policies, cardInfo } = props;

  const paginateProps = {
    itemsPerPage: 5,
    selectorContainerClass: styles.pageSelector,
    previousText: 'précédent',
    nextText: 'suivant'
  };

  const policyCards = policies.map(policy => {
    return (
      <div key={policy.policyNumber} style={{ marginBottom: '20px', width: '100%' }}>
        <PolicyCard {...policy} {...cardInfo} />
      </div>
    );
  });

  if (policies.length <= paginateProps.itemsPerPage) {
    return <>{policyCards}</>;
  }

  return <Paginate {...paginateProps}>{policyCards}</Paginate>;
}

PolicyCardList.propTypes = {
  policies: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  cardInfo: PropTypes.objectOf(PropTypes.any).isRequired
};

export default PolicyCardList;
