import { KEYCLOAK_SUCCESS, KEYCLOAK_FAIL } from './types';

export function keycloakSuccess(keycloak) {
  return {
    type: KEYCLOAK_SUCCESS,
    payload: keycloak
  };
}

export function keycloakFail() {
  return {
    type: KEYCLOAK_FAIL
  };
}
