import Card from '../../Card';
import React, { useEffect, useMemo, useState } from 'react';
import { ContractTerminationModel } from './models/ContractTermionation';
import styles from './contractTermination.module.css';
import Button from '../../../common/Button';
import Dropdown from '../../../common/Dropdown';
import { connect, useDispatch } from 'react-redux';
import Modal from '../../../common/Modal';
import { useParams } from 'react-router-dom';
import { UploadIcon } from '@heroicons/react/outline';
import { CUSTOMER_PORTAL_API } from '../../../config/properties';
import { sendUserEvent } from '../../../utils/googleAnalyticsService';
import axios from 'axios';
import FileInfo from './FileInfo/fileInfo';
import { Reasons } from './models/TerminationReasonsModel';
import { addMessageBanner } from '../../../store/Actions/messageBanner';
import { useHistory } from 'react-router-dom';
import { fileToBase64 } from '../../../utils/fileToBase64';
import LabeledInput from '../../../common/LabeledInput';
import Loader from '../../../common/Loader';
import LabelWrapper from '../../../common/LabelWrapper';
import Label from '../../../common/Label';

const ContractTermination = ({ policies, account, accountId }) => {
  const { policyNumber } = useParams();
  const [value, setValue] = useState({
    ...ContractTerminationModel,
    phone: account?.phoneNumber,
    firstName: account?.firstName,
    lastName: account?.lastName,
    email: account?.email
  });
  const [files, setFiles] = useState([]);
  const [validation, setValidation] = useState({ validated: false, errors: {} });
  const [showSendAccept, setShowSendAccept] = useState(false);
  const [terminationSuccess, setTerminationSuccess] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const contracts = useMemo(() => {
    return policies.map(policy => {
      const risk = policy?.risks[0];
      return {
        id: policy.policyNumber,
        title: `${risk.manufacturer} ${risk.model}   |   ${risk.licensePlate}   |   n°${policy.policyNumber}`,
        licensePlate: risk.licensePlate,
        vehicle: `${risk.manufacturer} ${risk.model}`,
        selected: policy.policyNumber === policyNumber,
        value: policy.policyNumber,
        key: 'navItems'
      };
    });
  }, [policies, policyNumber]);

  useEffect(() => {
    setSelectedVehicle(contracts?.find(p => p?.id === policyNumber) || null);
  }, [policyNumber, contracts]);

  const handleReasons = id => {
    setValue(prev => ({ ...prev, reason: id }));
    setFiles([]);
  };

  useEffect(() => {
    let errors = {};

    if (value?.phone === '') {
      errors['phone'] = "Phone number shouldn't be empty";
    }

    if (value?.email === '') {
      errors['email'] = "Email shouldn't be empty";
    }

    if (value?.reason === null) {
      errors['reason'] = 'reason is mandatory';
    }

    if (value?.reason === 3 && value?.comment === '') {
      errors['comment'] = 'comment is mandatory';
    }

    if ([2, 3].includes(value?.reason) && files.length === 0) {
      errors['file'] = 'Attachment is mandatory';
    }

    setValidation({
      validated: Object.keys(errors).length === 0,
      errors
    });
  }, [value, files]);

  const selectFiles = async e => {
    e.preventDefault();
    e.stopPropagation();
    Object.values(e?.target?.files).forEach(f => {
      setFiles(prev => [...prev, f]);
    });
  };

  const removeFile = ({ name }) => {
    setFiles(prev => [...prev.filter(f => f?.name !== name)]);
  };

  const sendRequest = async () => {
    let convertedFiles = [];
    if (files?.length > 0) {
      convertedFiles = await Promise.all(
        files.map(async f => ({
          fileName: f.name,
          fileType: f.type,
          fileContent: await fileToBase64(f)
        }))
      );
    }
    const payload = {
      ...value,
      reason: Reasons.find(reason => reason?.id === value.reason)?.value || '',
      licensePlate: selectedVehicle?.licensePlate,
      ...(files?.length > 0
        ? {
            hasFile: true,
            files: convertedFiles
          }
        : { files: [] })
    };

    try {
      const response = await callApi(
        `${CUSTOMER_PORTAL_API}/policy/accounts/${accountId}/${selectedVehicle?.id}/terminate`,
        payload
      );

      if (response?.status <= 299) {
        sendUserEvent('event_ga', 'Contract', 'client file uploaded');
        return { status: 'success', msg: "Téléchargement réussi avec l'ID de fichier enregistré" };
      } else {
        switch (response?.status) {
          case 404:
            if (response?.data === 'Sinister not found')
              return { status: 'error', msg: 'Le sinistre introuvable' };
            else if (response?.data === 'Policy not found')
              return { status: 'error', msg: 'Le contrat introuvable' };
            break;
          case 400:
            return { status: 'error', msg: response?.message || 'Le fichier est vide' };
          case 500:
            return { status: 'error', msg: response?.message || 'Erreur de fichier interne' };
          default:
            return {
              status: 'error',
              msg: "Malheureusement, une erreur s'est produite lors du téléchargement du fichier."
            };
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      return { status: 'error', msg: error?.message || '' };
    }
  };

  async function callApi(url, formData) {
    return new axios.post(url, formData, {
      headers: {
        'Content-Type': 'application/json'
      },
      validateStatus: function(status) {
        return status <= 599;
      }
    });
  }

  const terminate = async () => {
    if (selectedVehicle !== null && validation.validated) {
      setLoading(prev => !prev);
      sendRequest()
        .then(res => {
          if (res?.status === 'success') {
            setTerminationSuccess(true);
          } else {
            dispatch(addMessageBanner('error', res?.message || "quelque chose s'est mal passé"));
          }
          setLoading(prev => !prev);
        })
        .catch(_ => setLoading(prev => !prev));
    }
  };

  return (
    <React.Fragment>
      {showSendAccept && (
        <Modal
          acceptable
          acceptTitle="Oui, résilier le contrat"
          onAbort={_ => setShowSendAccept(prev => !prev)}
          onAccept={async () => {
            setShowSendAccept(prev => !prev);
            await terminate();
          }}
        >
          <p className={styles.header}>Résilier le contrat</p>
          <p className={styles.text}>
            Vous êtes sur le point de résilier le contrat <b> {selectedVehicle?.id} </b>
            pour votre{' '}
            <b>
              {' '}
              {selectedVehicle?.vehicle} immatriculée {selectedVehicle?.licensePlate}
            </b>
            . <br />
            Êtes-vous sûr de vouloir continuer?
          </p>
        </Modal>
      )}
      {terminationSuccess ? (
        <Card>
          <div className={styles.cardContent} style={{ marginTop: '3rem' }}>
            <p className={styles.success}>
              Votre demande de résiliation a été envoyée avec succès.
            </p>
            <p className={styles.text}>
              Nous vous confirmons que votre demande de résiliation a bien été réceptionnée et sera
              traitée dans les meilleurs délais.
            </p>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Button additionalStyles={styles.button} handleClick={_ => history.push('/')}>
                Revenir à la page d’accueil
              </Button>
            </div>
          </div>
        </Card>
      ) : (
        <>
          <Loader show={loading} />
          <div className={styles.headerContainer}>
            <p className={styles.header}>RESILIER LE CONTRAT</p>
            <p className={styles.text}>
              Afin de confirmer l’annulation du contrat, veuillez compléter le formulaire ci-dessous
            </p>
          </div>
          <Card>
            <div className={styles.cardContent}>
              <div className={styles.form}>
                <LabelWrapper label="Contrat à résilier pour">
                  <Dropdown
                    resetThenSet={id =>
                      setSelectedVehicle(contracts?.find(p => p?.id === id) || null)
                    }
                    list={contracts}
                  />
                </LabelWrapper>
                <p className={styles.text}>Vos informations personnelles</p>
                <LabeledInput
                  label="Votre nom"
                  additionalStyles={styles.input}
                  value={value.firstName}
                  onChange={e => setValue({ ...value, firstName: e.target.value })}
                  name="name"
                  placeholder="Votre nom"
                  errorMessage={validation?.errors?.firstName}
                  disabled
                />
                <LabeledInput
                  label="Votre prénom"
                  additionalStyles={styles.input}
                  value={value.lastName}
                  onChange={e => setValue({ ...value, lastName: e.target.value })}
                  name="family"
                  placeholder="Votre prénom"
                  errorMessage={validation?.errors?.lastName}
                  disabled
                />
                <p className={styles.text}>Vos données de contact</p>
                <LabeledInput
                  label="Votre courriel"
                  additionalStyles={styles.input}
                  value={value.email}
                  type="email"
                  onChange={e => setValue({ ...value, email: e.target.value })}
                  name="email"
                  placeholder="Votre courriel"
                  errorMessage={validation?.errors?.email}
                />
                <LabeledInput
                  label="Votre n° de téléphone"
                  additionalStyles={styles.input}
                  value={value.phone}
                  onChange={e => setValue({ ...value, phone: e.target.value })}
                  name="phone"
                  placeholder="Votre n° de téléphone"
                  disabled
                  errorMessage={validation?.errors?.phone}
                />
                <p className={styles.text}>Raison de votre résiliation</p>
                <LabelWrapper label="Raison de la résiliation">
                  <Dropdown title="-- Motif --" resetThenSet={handleReasons} list={Reasons} />
                </LabelWrapper>
                <LabelWrapper label="Commentaire" required={value?.reason === 3}>
                  <textarea
                    name="comment"
                    className={styles.textArea}
                    onChange={e => setValue({ ...value, comment: e.target.value })}
                    defaultValue={value.comment}
                    placeholder="Votre message"
                    maxLength={500}
                    required={value?.reason === 2}
                  />
                  <p className={styles.charInfo}>{value?.comment?.length}/500</p>
                </LabelWrapper>
                {/*{value?.reason === 2 && <>*/}
                {[2, 3].includes(value?.reason) && (
                  <Label required={[2, 3].includes(value?.reason)}>
                    <p className={styles.documentsHeader}>Documents</p>
                  </Label>
                )}
                {value?.reason === 2 && (
                  <p className={styles.documentsText}>
                    Pour résilier le motif « Je vends mon véhicule et suspend mes garanties »,{' '}
                    <br />
                    vous devez nous fournir soit le certificat de cession, la carte grise barrée ou
                    le certificat de reprise du véhicule.
                  </p>
                )}
                {value?.reason === 3 && (
                  <div className={styles.documentsText}>
                    Merci de nous fournir le document justifiant la demande de résiliation.
                    Exemples:
                    <ul>
                      <li>En cas de décès, veuillez nous adresser l’acte de décès.</li>
                      <li>
                        Suite à un refus d’avenant, veuillez nous adresser une copie de
                        l’attestation d’assurance de votre nouvel assureur.
                      </li>
                    </ul>
                  </div>
                )}
                <div className={styles.filesContainer}>
                  {files.map((f, index) => (
                    <FileInfo key={index} name={f?.name} onRemoveFile={_ => removeFile(f)} />
                  ))}
                </div>
                {(([2, 3].includes(value?.reason) && files?.length < 1) ||
                  (value?.reason === 2 && files?.length < 1) ||
                  (value?.reason === 3 && files.length < 2)) && (
                  <div>
                    <input
                      id="upload"
                      type="file"
                      accept={'.jpg, .jpeg, .png, .pdf'}
                      onChange={selectFiles}
                      hidden
                    />
                    <label htmlFor="upload" className={styles.uploadButton}>
                      <UploadIcon style={{ width: '2rem' }} /> Charger documents
                    </label>
                  </div>
                )}
                {/*</>}*/}
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                <Button
                  additionalStyles={styles.button}
                  disabled={!validation?.validated}
                  handleClick={_ => setShowSendAccept(true)}
                >
                  Résilier ce contrat
                </Button>
              </div>
            </div>
          </Card>
        </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  policies: state.policies.policies,
  account: state.account.account,
  accountId: state.keycloak.accountId
});
export default connect(mapStateToProps, {})(ContractTermination);
