import { ReactComponent as Trash } from '../../../../assets/icons/Trash.svg';
import styles from "./fileInfo.module.css";
import React from "react";

export default function FileInfo ({ name, onRemoveFile }) {
  return <div className={styles.wrapper}>
      <div style={{maxWidth:'85%', overflow: 'hidden'}}>
        <div className={styles.name}>{name}</div>
      </div>
      <div onClick={onRemoveFile}>
        <Trash />
      </div>
    </div>
}