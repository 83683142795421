import { ADD_MESSAGE_BANNER, CLEAR_MESSAGE_BANNER } from '../Actions/types';

const initialState = {
  level: null,
  message: '',
  showBanner: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_MESSAGE_BANNER:
      return {
        level: action.level,
        message: action.message,
        showBanner: true
      };
    case CLEAR_MESSAGE_BANNER:
      return {
        ...state,
        showBanner: false
      };
    default:
      return state;
  }
}
