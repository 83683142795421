import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styles from './navBarAccount.module.css';
import NavItems from './NavItems/NavItems';
import { NavBarAccountMobile } from './NavBarAccountMobile';

function NavBarAccount(props) {
  return (
    <>
      <div className={styles.desktopOnly}>
        <div className={styles.navItemContainer}>
          <NavItems links={props.links} />
        </div>
      </div>

      <div className={styles.mobileOnly}>
        <NavBarAccountMobile
          history={props.history}
          dropdownBGColor={props.dropdownBGColor}
          links={props.links}
        />
      </div>
    </>
  );
}

NavBarAccount.propTypes = {
  links: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  dropdownBGColor: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
NavBarAccount.defaultProps = {
  dropdownBGColor: null
};
export default withRouter(NavBarAccount);
