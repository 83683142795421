import axios from 'axios';
import { CUSTOMER_PORTAL_API } from '../config/properties';

export const setRequestHeadersInterceptor = keycloak => {
  axios.interceptors.request.use(config =>
    keycloak
      .updateToken(5)
      .then(() => {
        if (config.url.startsWith(CUSTOMER_PORTAL_API)) {
          config.headers.Authorization = `Bearer ${keycloak.token}`;
        }
        return Promise.resolve(config);
      })
      .catch(() => {
        keycloak.login();
      })
  );
};
