import { DOWNLOAD_FILE_LOADING, DOWNLOAD_FILE_SUCCESS, DOWNLOAD_FILE_FAIL } from '../Actions/types';

const initialState = {
  success: false,
  response: null,
  error: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_FILE_LOADING:
      return {
        ...initialState,
        loading: true
      };
    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false
      };
    case DOWNLOAD_FILE_FAIL:
      return {
        ...state,
        error: true,
        loading: false
      };
    default:
      return state;
  }
}
