import {
  ADD_NEW_DRIVER_LOADING,
  ADD_NEW_DRIVER_SUCCESS,
  ADD_NEW_DRIVER_FAIL
} from "../Actions/types";

const initialState = {
  success: false,
  response: null,
  error: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_DRIVER_LOADING:
      return {
        ...initialState,
        loading: true
      };
    case ADD_NEW_DRIVER_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false
      };
    case ADD_NEW_DRIVER_FAIL:
      return {
        ...state,
        error: true,
        loading: false
      };
    default:
      return state;
  }
}
