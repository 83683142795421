import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '../../Card';
import Loader from '../../../common/Loader';
import Button from '../../../common/Button';
import AddressAutocomplete from '../../../common/AddressAutocomplete';
import { sendVirtualpageView } from '../../../utils/googleAnalyticsService';
import {
  changePostalAddress,
  changePolicyAddress
} from '../../../store/Actions/changeAddressActions';
import Input from '../../../common/Input';
import Label from '../../../common/Label';
import styles from './changeAddress.module.css';
import './dropdownOverrideStyles.css';

class ChangeAdress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changedAddress: {
        streetNumber: '',
        street: '',
        city: '',
        country: '',
        postalCode: ''
      },
      addressComponent: {
        street_number: 'street_number',
        route: 'route',
        locality: 'locality',
        country: 'country',
        postal_code: 'postal_code',
        administrative_area_level_1: 'administrative_area_level_1'
      },
      ShowAddressDetails: false,
      formIsValid: false
    };
  }

  componentDidMount() {
    if (this.props.location?.state?.postalAddress) {
      sendVirtualpageView(
        'VirtualPageView',
        this.props.accountId,
        '/changeAddress/personal',
        'Change Address - Personal'
      );
    } else {
      sendVirtualpageView(
        'VirtualPageView',
        this.props.accountId,
        '/changeAddress/contract',
        'Change Address - Contract'
      );
    }
  }

  // this validate function can be cleaned up (more) its doing a lot of stuff we dont need..

  validate = changedAddress => {
    const errors = {};

    if (!changedAddress.streetNumber) {
      errors.streetNumber = 'StreetNumber is required';
    } else if (!changedAddress.street) {
      errors.street = 'StreetNumber is required';
    } else if (!changedAddress.postalCode) {
      errors.postalCode = 'StreetNumber is required';
    } else if (!changedAddress.city) {
      errors.postalCode = 'City is required';
    } else if (!changedAddress.country) {
      errors.country = 'City is required';
    }
    return !!(Object.keys(errors).length === 0);
  };

  handleFillAddress = place => {
    const { changedAddress } = this.state;
    const addressValue = {};

    if (!place.address_components) {
      this.setState({ ShowAddressDetails: false });
      return;
    }

    this.setState({ ShowAddressDetails: true });

    const address = { ...place };

    for (let i = 0; i < address.address_components.length; i += 1) {
      const addressType = address.address_components[i].types[0];
      if (this.state.addressComponent[addressType]) {
        addressValue[addressType] = address.address_components[i].long_name;
      }
    }

    changedAddress.street = addressValue.route ? addressValue.route : '';
    changedAddress.streetNumber = addressValue.street_number ? addressValue.street_number : '';
    changedAddress.city = `${addressValue.locality}`;
    changedAddress.country = addressValue.country;
    changedAddress.postalCode = addressValue.postal_code ? addressValue.postal_code : '';

    const formIsValid = this.validate(changedAddress);
    this.setState({ changedAddress, formIsValid });
  };

  handleChange = evnt => {
    const { changedAddress } = this.state;
    changedAddress[evnt.target.name] = evnt.target.value;
    const formIsValid = this.validate(changedAddress);
    this.setState({ changedAddress, formIsValid });
  };

  handleSubmit = evnt => {
    evnt.preventDefault();
    const { changedAddress } = this.state;
    const { policyNumber, postalAddress, addressId } = JSON.parse(
      sessionStorage.getItem('addressData')
    );
    const payload = {
      id: addressId,
      street: `${changedAddress.streetNumber} ${changedAddress.street}`,
      zip: changedAddress.postalCode,
      city: changedAddress.city,
      country: changedAddress.country
    };

    if (postalAddress) {
      payload.policyNumber = policyNumber;
      this.props.changePostalAddress(payload, this.props.accountId);
    } else if (policyNumber && !postalAddress) {
      this.props.changePolicyAddress(payload, this.props.accountId, policyNumber);
    }
  };

  render() {
    const { changedAddress } = this.state;
    const form = (
      <>
        <form className={styles.addressForm} onSubmit={this.handleSubmit}>
          <Label required>Adresse</Label>
          <div className={styles.streetAddress}>
            <Input
              additionalStyles={styles.streetName}
              name="street"
              value={changedAddress.street}
              onChange={this.handleChange}
            />
            <Input
              additionalStyles={styles.streetNumber}
              name="streetNumber"
              value={changedAddress.streetNumber}
              onChange={this.handleChange}
            />
          </div>
          <Label required>Code Postal</Label>
          <Input
            additionalStyles={styles.input}
            name="postalCode"
            value={changedAddress.postalCode}
            onChange={this.handleChange}
          />
          <Label required>Ville</Label>
          <Input
            additionalStyles={styles.input}
            name="city"
            value={changedAddress.city}
            onChange={this.handleChange}
          />
          <Label required>Pays</Label>
          <Input
            additionalStyles={styles.input}
            name="country"
            value={changedAddress.country}
            onChange={this.handleChange}
          />
          <div className={styles.button}>
            <Button disabled={!this.state.formIsValid} type="submit">
              enregistrer
            </Button>
          </div>
        </form>
      </>
    );
    return (
      <React.Fragment>
        <Loader show={this.props.loading} />
        {/* {!this.props.location.state && <Redirect to="/account" />} */}

        <div className={styles.headerTitle}>Changement d&apos;adresse</div>

        <Card className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.label}>Recherche par code postal ou nom de la rue</div>
            <div className={styles.autocompleteInput}>
              <AddressAutocomplete onPlaceSelected={this.handleFillAddress} country="fr" />
            </div>
            {this.state.ShowAddressDetails ? form : null}
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  policies: state.policies.policies,
  accountId: state.keycloak.accountId,
  loading: state.changeAddress.loading,
  error: state.changeAddress.error
});

ChangeAdress.propTypes = {
  changePostalAddress: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  changePolicyAddress: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(mapStateToProps, { changePostalAddress, changePolicyAddress })(ChangeAdress);
