import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './modal.module.css';
import { ReactComponent as CloseBlack } from '../../assets/icons/CloseBlack.svg';
import { ReactComponent as Upload } from '../../assets/icons/UploadLG.svg';
import { ReactComponent as Trash } from '../../assets/icons/Trash.svg';
import { ReactComponent as AddDarkBackground } from '../../assets/icons/AddDarkBackground.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/ImageIcon.svg';

import Button from '../Button';
import UploadDocument from '../UploadDocument/UploadDocument';

import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { CUSTOMER_PORTAL_API } from '../../config/properties';
import { sendUserEvent } from '../../utils/googleAnalyticsService';
import Loader from '../Loader';

import { addMessageBanner } from '../../store/Actions/messageBanner';
import { fetchPolicies } from '../../store/Actions/policiesAction';

const Modal = ({ width, otherDocumentType, modalRejectionHandler, configs, policyNumber }) => {
  const dropRef = createRef();

  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState({});
  const [fileValidation, setFileValidation] = useState([]);
  const [loading, setLoading] = useState(false);

  const accountId = useSelector(state => state.keycloak.accountId);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoading(true);

    const uploadPromises = files.map(async fileItem => {
      const formData = new FormData();
      formData.set('upfile', fileItem);
      formData.set('fileType', otherDocumentType ? 'autre' : configs?.fileType);
      try {
        const response = await callApi(
          `${CUSTOMER_PORTAL_API}/document/accounts/${accountId}/policy/${policyNumber}/uploadfile`,
          formData
        );

        if (response?.status <= 299) {
          return {
            status: 'success',
            msg: "Téléchargement réussi avec l'ID de fichier enregistré",
            size: fileItem.size
          };
        } else {
          switch (response?.status) {
            case 404:
              if (response?.data === 'Sinister not found')
                return { status: 'error', msg: 'Le sinistre introuvable', size: fileItem.size };
              else if (response?.data === 'Policy not found')
                return { status: 'error', msg: 'Le contrat introuvable', size: fileItem.size };
              break;
            case 400:
              return { status: 'error', msg: 'Erreur de chargement', size: fileItem.size };
            case 500:
              return { status: 'error', msg: 'Erreur de chargement', size: fileItem.size };
            default:
              return { status: 'error', msg: 'Erreur de chargement', size: fileItem.size };
          }
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        return {
          status: 'error',
          msg: error?.message || 'Erreur de chargement',
          size: fileItem.size
        };
      }
    });

    try {
      const responses = await Promise.all(uploadPromises);
      if (responses.every(res => res.status === 'success')) {
        dispatch(addMessageBanner('success', 'Votre document a été transmis avec succès'));
        modalRejectionHandler();
      } else {
        const failedFiles = responses
          .filter(res => res.status !== 'success')
          .reduce((acc, cur) => ({ ...acc, [cur.size]: cur }), {});
        setFiles(files.filter(file => !!failedFiles[file.size]));
        setFileError(failedFiles);
      }
    } catch (error) {
      console.log(error);
      dispatch(addMessageBanner('error', 'Erreur de fichier interne'));
      modalRejectionHandler();
    } finally {
      sendUserEvent(
        'event_ga',
        'Document_upload',
        'Files uploaded for document type: ' + configs?.fileType.toUpperCase(),
        files.length
      );
    }
    dispatch(fetchPolicies(accountId));
    setLoading(false);
  };
  async function callApi(url, formData) {
    return new axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      validateStatus: function(status) {
        return status <= 599;
      }
    });
  }

  const unSelectFile = (e, deleteFileIndex) => {
    e.preventDefault();
    setFiles(files => files.filter((file, index) => index !== deleteFileIndex));
    setFileError({});
  };

  const DocumentInfoTab = ({ documentName, index, status, msg }) => (
    <div className={styles.documentInfoTab}>
      <div style={{ maxWidth: '85%', overflow: 'hidden' }}>
        <div className={styles.documentName}>{documentName}</div>
        {status && <span className={`${styles.uploadStatus}  ${styles[status]}`}>{msg}</span>}
      </div>
      <div
        onClick={e => {
          unSelectFile(e, index);
        }}
        className={styles.cursorStyle}
      >
        <Trash />
      </div>
    </div>
  );

  const MobileDocumentInformationDiv = () => (
    <div className={styles.mobileDocumentInformationDiv}>
      {!files.length > 0 && (
        <>
          <div>
            <ImageIcon />
          </div>
          <div>Tapez sur le bouton plus pour créer un fichier</div>
        </>
      )}
      <label className={styles.mobileUploadButton} htmlFor="fileInput" ref={dropRef}>
        <AddDarkBackground />
        <UploadDocument
          dropRef={dropRef}
          files={files}
          setFiles={setFiles}
          fileSelected={true}
          accept={'.jpg, .jpeg, .png, .pdf'}
          setFileError={setFileValidation}
          exactFileNumber={configs?.exactFileNumber}
          maxFileNumber={otherDocumentType ? 4 : configs?.maxFileNumber}
        />
      </label>
    </div>
  );

  return (
    <>
      <Loader show={loading} />

      <div className={styles.modalBG}>
        <div className={styles.modal}>
          <div className={styles.textContainer}>
            <div className={styles.closeButton}>
              <CloseBlack onClick={modalRejectionHandler} />
            </div>
            <div style={{ textAlign: 'start' }}>
              <h1>Charger un/des documents</h1>
              <p className={styles.subText}>
                Maximum 10 MB par fichier. Format accepté: pdf, jpg jpeg, ou png.
              </p>
              <p className={styles.subText}>
                {configs?.exactFileNumber
                  ? `Exactement ${configs?.exactFileNumber} fichier${
                      configs?.exactFileNumber > 1 ? 's' : ''
                    }`
                  : configs?.maxFileNumber
                  ? `Maximum ${configs?.maxFileNumber} fichier${
                      configs?.maxFileNumber > 1 ? 's' : ''
                    }`
                  : 'Maximum 4 fichiers'}
              </p>
              {fileValidation && (
                <div className={styles.errorsContainer}>
                  {fileValidation?.map(error => (
                    <span className={styles.error}>{error}</span>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.documentInfoDiv}>
              {files.length > 0 && (
                <div className={styles.documentNameDiv}>
                  {files.map((file, index) => (
                    <DocumentInfoTab
                      key={file + index}
                      documentName={file?.name}
                      index={index}
                      {...fileError?.[file.size]}
                    />
                  ))}
                </div>
              )}
            </div>
            {width > 767 ? (
              <>
                <label className={styles.uploadBoxInput} htmlFor="fileInput" ref={dropRef}>
                  <div className={styles.uploadBox}>
                    <Upload />
                    <div>Glissez et déposez vos documents dans cette zone ou</div>
                    <span className={styles.yesButton}> Parcourir fichiers</span>
                    <UploadDocument
                      dropRef={dropRef}
                      files={files}
                      setFiles={setFiles}
                      fileSelected={true}
                      accept={'.jpg, .jpeg, .png, .pdf'}
                      setFileError={setFileValidation}
                      exactFileNumber={configs?.exactFileNumber}
                      maxFileNumber={otherDocumentType ? 4 : configs?.maxFileNumber}
                    />
                  </div>
                </label>
              </>
            ) : (
              <MobileDocumentInformationDiv />
            )}

            <div className={styles.buttonGroup}>
              <Button handleClick={_ => modalRejectionHandler()} additionalStyles={styles.button}>
                Annuler
              </Button>
              <Button
                disabled={
                  (configs?.exactFileNumber && files?.length !== configs?.exactFileNumber) ||
                  files.length <= 0
                }
                handleClick={_ => handleSubmit()}
                additionalStyles={styles.yesButton}
              >
                Confirmer
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  width: PropTypes.string.isRequired,
  otherDocumentType: PropTypes.func.isRequired,
  modalRejectionHandler: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  policyNumber: PropTypes.string.isRequired
};

export default Modal;
