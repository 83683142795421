/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { FETCH_POLICIES_LOADING, FETCH_POLICIES_SUCCESS, FETCH_POLICIES_FAIL } from './types';
import { CUSTOMER_PORTAL_API } from '../../config/properties';
import { acceptedGA } from '../../utils/googleAnalyticsService';
import buildCustomDimension from '../../utils/buildCustomDimension';

export function fetchPolicies(accountId) {
  return function(dispatch) {
    dispatch({ type: FETCH_POLICIES_LOADING });
    axios
      .get(`${CUSTOMER_PORTAL_API}/policy/accounts/${accountId}`)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: FETCH_POLICIES_SUCCESS,
            payload: response.data
          });

          if (acceptedGA()) {
            window.dataLayer.push(buildCustomDimension(response.data));
          }
        } else {
          dispatch({
            type: FETCH_POLICIES_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        dispatch({ type: FETCH_POLICIES_FAIL, payload: err });
      });
  };
}
