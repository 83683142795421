/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { push } from 'connected-react-router';
import {
  CHANGE_PAYMENT_DETAILS_LOADING,
  CHANGE_PAYMENT_DETAILS_SUCCESS,
  CHANGE_PAYMENT_DETAILS_FAIL,
  ADD_MESSAGE_BANNER
} from './types';
import { CUSTOMER_PORTAL_API } from '../../config/properties';
import { sendUserEvent } from '../../utils/googleAnalyticsService';

export function changePaymentDetails(newPaymentDetails, policyNumber, accountId) {
  return dispatch => {
    dispatch({ type: CHANGE_PAYMENT_DETAILS_LOADING });
    axios
      .put(
        `${CUSTOMER_PORTAL_API}/policy/accounts/${accountId}/${policyNumber}/payment-details`,
        newPaymentDetails
      )
      .then(response => {
        if (response.status === 200) {
          dispatch(push('/'));

          sendUserEvent('event_ga', 'Contract', 'contract payment details changed');

          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'success',
            message: 'Nous avons bien reçu vos nouvelles coordonnées bancaires.'
          });
          dispatch({
            type: CHANGE_PAYMENT_DETAILS_SUCCESS,
            payload: response
          });
        } else {
          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'error',
            message: "Malheureusement, vos coordonnées bancaires n'ont pas pu être modifiées."
          });
          dispatch({
            type: CHANGE_PAYMENT_DETAILS_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        dispatch({
          type: ADD_MESSAGE_BANNER,
          level: 'error',
          message: "Malheureusement, vos coordonnées bancaires n'ont pas pu être modifiées."
        });
        dispatch({
          type: CHANGE_PAYMENT_DETAILS_FAIL,
          payload: err
        });
      });
  };
}
