import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../../common/Button';
import styles from './addressCard.module.css';
import AttachedPolicies from './AttachedPolicies';

const AddressCard = props => {
  const {
    address,
    addressId,
    customerId,
    changeAddressPolicyNumber,
    attachedPolicies,
    changeAddressClickHandler
  } = props;

  const makeChanges = changeAddressClickHandler ? (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <Button
          additionalStyles={styles.button}
          type="button"
          handleClick={() =>
            changeAddressClickHandler(addressId, customerId, changeAddressPolicyNumber)
          }
        >
          {props.changeAddressText}
        </Button>
      </div>
    </div>
  ) : null;

  const policies = attachedPolicies ? (
    <AttachedPolicies
      attachedPoliciesText={props.attachedPoliciesText}
      policiesText={props.policiesText}
      policies={attachedPolicies}
    />
  ) : null;

  return (
    <React.Fragment>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <div className={styles.address}>
            <p>{address.street.toLowerCase()}</p>
            <p>{address.zip}</p>
            <p>{address.city.toLowerCase()}</p>
          </div>
          {makeChanges}
        </div>
        {policies}
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddressCard);
