import React, { Component } from 'react';
import styles from './userDropdown.module.css';
import storage from 'redux-persist/lib/storage';

export default class UserDropdown extends Component {
  state = {
    showDropdown: false
  };

  componentDidMount() {
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    const target = event.target.id;
    if (target !== 'signOutButton' && target !== 'userNameSpan') {
      this.setState({ showDropdown: false });
    }
  };

  toggleDropdown = () => {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
  };

  render() {
    const dropDown = (
      <div className={styles.dropdown}>
        <button
          id="signOutButton"
          onClick={() => {
            sessionStorage.clear();
            this.props.logout({ redirectUri: window.location.origin + process.env.PUBLIC_URL });
            storage.removeItem('persist:root');
          }}
          className={styles.button}
        >
          Se déconnecter
        </button>
      </div>
    );

    return (
      <div className={[styles.wrapper, this.props.className].join(' ')}>
        <span id="userNameSpan" onClick={this.toggleDropdown} className={styles.user}>
          {this.props.name}
        </span>
        {this.state.showDropdown ? dropDown : null}
      </div>
    );
  }
}
