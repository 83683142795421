const elementGroupByAddress = policies => {
  return Object.values(
    policies.reduce((groupedPolicies, policy) => {
      const key = policy.address.id + policy.customerId;
      const attachedPolicy = {
        policyNumber: policy.policyNumber,
        makeAndModel: `${policy.risks[0].manufacturer} ${policy.risks[0].model}`,
        licensePlate: policy.risks[0].licensePlate,
        startDate: policy.startDate
      };
      if (groupedPolicies[key]) {
        groupedPolicies[key].attachedPolicies.push(attachedPolicy);
      } else {
        const { city, country, street, zip } = policy.address;
        groupedPolicies[key] = {
          customerId: policy.customerId,
          address: {
            city,
            country,
            street,
            zip
          },
          attachedPolicies: [attachedPolicy]
        };
      }
      return groupedPolicies;
    }, {})
  );
};

export default elementGroupByAddress;
