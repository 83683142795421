import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './footer.module.css';

function Footer(props) {
  const footerLinks = props.links.map(link => {
    return (
      <Link key={link.address + link.text} to={link.address} className={styles.link}>
        {link.text}
      </Link>
    );
  });
  return (
    <div className={styles.container}>
      <div className={styles.footerContainer}>{footerLinks}</div>
      <div className={styles.copyright}>{props.copyrightText}</div>
    </div>
  );
}

Footer.propTypes = {
  links: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  copyrightText: PropTypes.string.isRequired
};

export default Footer;
