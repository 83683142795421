import React from 'react';
import PropTypes from 'prop-types';
import styles from './errorPage.module.css';

function ErrorPage(props) {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.mainText}>Désolé, cette page n&apos;est pas disponible</div>
        <div className={styles.subtext}>Nous rencontrons un problème</div>
      </div>

      <div className={styles.returnText}>
        Vous pouvez retourner vers le{' '}
        <button
          className={styles.button}
          type="button"
          onClick={() => props.history.push('/rules')}
        >
          <span className={styles.underline}>règlement du site</span>
        </button>{' '}
        ou vers la{' '}
        <button className={styles.button} type="button" onClick={props.history.goBack}>
          <span className={styles.underline}>dernière page visitée</span>
        </button>
      </div>
    </div>
  );
}

ErrorPage.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ErrorPage;
