const getCookie = cookieName => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');

  let consentCookie = '';

  cookies.forEach(function(c) {
    if (c.trim().startsWith(name)) consentCookie = c;
  });

  return consentCookie;
};

export const acceptedGA = () => {
  const analyticsCookie = getCookie('cpCookieConsentAll');
  return analyticsCookie.includes('=') ? analyticsCookie.split('=')[1] === 'true' : false;
};

export const sendVirtualpageView = (event, accountId, virtualPageURL, virtualPageTitle) => {
  if (acceptedGA()) {
    window.dataLayer.push({
      event,
      userId: accountId,
      virtualPageURL,
      virtualPageTitle
    });
  }
};

export const sendUserEvent = (event, category, action, label) => {
  if (acceptedGA()) {
    const userEvent = {
      event,
      category,
      action
    };

    if (label) {
      userEvent.label = label;
    }

    window.dataLayer.push(userEvent);
  }
};
