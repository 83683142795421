export const Reasons = [
  {
    id: 1,
    title: 'Résiliation à l’échéance',
    selected: false,
    value: 'Résiliation à l’échéance',
  },
  {
    id: 2,
    title: 'Je vends mon véhicule et suspends mes garanties',
    selected: false,
    value: 'Je vends mon véhicule et suspends mes garanties',
  },
  {
    id: 3,
    title: 'Résiliation Autre',
    selected: false,
    value: 'Résiliation Autre',
  }
]