import {
  FETCH_DOCUMENTS_LOADING, FETCH_DOCUMENTS_SUCCESS, FETCH_DOCUMENTS_FAIL
} from '../Actions/types';

const initialState = {
  documents: null,
  loading: false,
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCUMENTS_LOADING:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: {...state.documents, ...action.payload},
        loading: false
      };
    case FETCH_DOCUMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
}
