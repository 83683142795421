import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { deleteUserAccount } from '../../../store/Actions/deleteAccountAction';
import { sendVirtualpageView } from '../../../utils/googleAnalyticsService';
import Card from '../../Card';
import Loader from '../../../common/Loader';
import Button from '../../../common/Button';
import Input from '../../../common/Input';
import styles from './delete.module.css';

class AccountDelete extends Component {
  constructor(props) {
    super(props);
    this.state = { password: '', isValid: false, showError: false };
  }

  componentDidMount() {
    sendVirtualpageView(
      'VirtualPageView',
      this.props.accountId,
      '/account/accountDelete',
      'Account - Delete'
    );
  }

  validate = password => {
    return !!password.length;
  };

  handleChange = ({ target }) => {
    const password = target.value;
    this.setState({ password, showError: false, isValid: this.validate(password) });
  };

  handleSubmit = () => {
    this.setState(prevState => ({ showError: !prevState.isValid }));
    if (this.state.isValid) {
      sessionStorage.clear();
      this.props.deleteUserAccount(
        this.state.password,
        this.props.accountId,
        this.props.keycloakLogout
      );
    }
  };

  render() {
    const { showError } = this.state;
    return (
      <React.Fragment>
        <Loader show={this.props.loading} />
        <Card>
          <div className={styles.container}>
            <p>Si vous désirez de supprimer votre compte, veuillez entrer votre mot de passe.</p>
            <p>
              Votre assurances restent actives et si vous désirez de créer un nouveau compte, vous
              pouvez demander un nouveau code d&apos;activation.
            </p>
            <div className={styles.inputContainer}>
              <Input
                additionalStyles={styles.input}
                value={this.state.password}
                onChange={this.handleChange}
                name="password"
                type="password"
                placeholder="Mot de passe actuel"
                errorMessage={showError && 'Mot de passe est nécessaire'}
              />
            </div>

            <Button additionalStyles={styles.button} type="button" handleClick={this.handleSubmit}>
              Supprimer le compte
            </Button>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

AccountDelete.propTypes = {
  loading: PropTypes.bool.isRequired,
  accountId: PropTypes.string.isRequired,
  deleteUserAccount: PropTypes.func.isRequired,
  keycloakLogout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  accountId: state.keycloak.accountId,
  loading: state.deleteAccount.loading,
  keycloakLogout: state.keycloak.data.logout
});

export default connect(mapStateToProps, { deleteUserAccount })(AccountDelete);
