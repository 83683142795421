import React from 'react';
import styles from './info.module.css';

export default function Info() {
  return (
    <>
      <span className={styles.header}>Service clients</span>
      <div className={styles.container}>
        <p>Appelez-nous au 01.41.12.20.73</p>
        <p className={styles.bold}>Nos heures d&apos;ouverture</p>
        <p>Lundi-Vendredi 9h00 - 13h00, 14h00 - 18h00</p>
        <p>Les appels seront facturés au prix d&apos;un appel local</p>
      </div>
    </>
  );
}
