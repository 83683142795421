import { SET_ACCOUNT_ID, SET_POLICYNUMBER } from "../Actions/types";

export function setAccountId(accountId) {
  return function(dispatch) {
    dispatch({
      type: SET_ACCOUNT_ID,
      payload: accountId
    });
  };
}

export function setPolicyNumber(policyNumber) {
  return function(dispatch) {
    dispatch({
      type: SET_POLICYNUMBER,
      payload: policyNumber
    });
  };
}
