/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { CUSTOMER_PORTAL_API } from '../../config/properties';
import {
  CHANGE_VEHICLE_LOADING,
  CHANGE_VEHICLE_SUCCESS,
  CHANGE_VEHICLE_FAIL,
  ADD_MESSAGE_BANNER
} from './types';
import { sendUserEvent } from '../../utils/googleAnalyticsService';

export function changeVehicleAction(changeData, accountId, policyNumber, history) {
  return dispatch => {
    dispatch({ type: CHANGE_VEHICLE_LOADING });

    axios
      .put(
        `${CUSTOMER_PORTAL_API}/policy/accounts/${accountId}/${policyNumber}/vehicle`,
        changeData
      )
      .then(response => {
        if (response.status === 204) {
          history.push('/');

          sendUserEvent('event_ga', 'Contract', 'contract vehicle changed');

          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'success',
            message:
              'Votre demande de changement a bien été prise en compte. Nous vous recontactons au plus vite'
          });
          dispatch({
            type: CHANGE_VEHICLE_SUCCESS,
            payload: response
          });
        } else {
          dispatch({
            type: ADD_MESSAGE_BANNER,
            level: 'error',
            message: "Malheureusement, une erreur s'est produite lors du changement du vehicule"
          });
          dispatch({
            type: CHANGE_VEHICLE_FAIL,
            payload: response
          });
        }
      })
      .catch(err => {
        dispatch({
          type: ADD_MESSAGE_BANNER,
          level: 'error',
          message: "Malheureusement, une erreur s'est produite lors du changement du vehicule "
        });
        dispatch({
          type: CHANGE_VEHICLE_FAIL,
          payload: err
        });
      });
  };
}
