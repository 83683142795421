/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './button.module.css';

function Button({ additionalStyles, type, disabled, handleClick, children }) {
  return (
    <button
      className={`${styles.button} ${additionalStyles}`}
      type={type}
      disabled={disabled}
      onClick={handleClick}
    >
      <span className={styles.buttonText}>{children}</span>
    </button>
  );
}

Button.defaultProps = {
  additionalStyles: '',
  type: 'button',
  disabled: false,
  handleClick: null
};

Button.propTypes = {
  handleClick: PropTypes.func,
  children: PropTypes.string.isRequired,
  additionalStyles: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

export default Button;
