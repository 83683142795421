const buildCustomDimensionString = data => {
  let customDimension = '';
  data.forEach(d => {
    customDimension += `${d}|`;
  });

  return customDimension.length > 0 ? customDimension.substring(0, customDimension.length - 1) : '';
};

const buildCustomDimension = data => {
  const userId = data[0]?.accountId;
  const event = 'VirtualPageView';
  const virtualPageURL = '/';
  const virtualPageTitle = 'Homepage';
  const liabilityTypes = [];
  const carModels = [];
  const carBrands = [];
  const premiumPeriods = [];
  const premiumPrices = [];
  const startDates = [];

  data.forEach(policy => {
    liabilityTypes.push(policy?.risks[0]?.guaranteeFormula);
    carModels.push(policy?.risks[0]?.model);
    carBrands.push(policy?.risks[0]?.manufacturer);
    premiumPeriods.push(policy?.paymentDetails?.paymentMethod);
    premiumPrices.push(policy?.premiumA);
    startDates.push(policy?.startDate);
  });

  return {
    userId,
    event,
    virtualPageURL,
    virtualPageTitle,
    'car model': buildCustomDimensionString(carModels),
    'car brand': buildCustomDimensionString(carBrands),
    'premium period': buildCustomDimensionString(premiumPeriods),
    'premium price': buildCustomDimensionString(premiumPrices),
    'start date': buildCustomDimensionString(startDates),
    'liablity type': buildCustomDimensionString(liabilityTypes)
  };
};

export default buildCustomDimension;
